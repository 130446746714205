.shopping-cart-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center items horizontally */
    padding: 20px;
    background-color: #f4f4f4;
    font-family: var(--font-family) !important;
    font-weight: bold;
}
.shopping-cart-items-wrapper {
    display: inline-flex;
    flex-direction: column;
    flex: 1;
}

.shopping-cart-checkout-wrapper {
    display: flex;
    flex-direction: row;
}

.shopping-cart-checkout {
    border: 1px solid #e0e0e0; /* Border for the order block */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    background-color: #fff; /* White background for each order */
    margin-left: 20px;
    margin-bottom: 20px; /* Space between order blocks */
    padding: 20px; /* Space inside each order block */
    cursor: pointer; /* Indicate that the order is clickable */
}

.shopping-cart-item {
    display: flex;
    flex-direction: row;
    flex: 1;
    border: 1px solid #e0e0e0; /* Border for the order block */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    background-color: #fff; /* White background for each order */
    margin-bottom: 20px; /* Space between order blocks */
    padding: 20px; /* Space inside each order block */
    cursor: pointer; /* Indicate that the order is clickable */
}

.shopping-cart-item-thumbnail {
    width: 120px; /* Adjust as per your requireents */
    height: 120px;
    object-fit: cover;
    border-radius: 4px; /* Slight rounding on the item images */
}

.shopping-cart-item-info,
.shopping-cart-item-quantity,
.shopping-cart-item-total {
    padding: 0 30px;
}

.shopping-cart-checkout {
    justify-content: center;
    justify-items: center;
}

.shopping-cart-checkout-button {
    display: flex;
    font-size: 40px;
}
