.lab-manager-orders-container {
    margin: 24px auto;
    padding: 0 20px;
}

.lab-manager-orders-tabs {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1px;
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 8px;
    padding: 4px;
    margin-bottom: 15px;
}

.lab-manager-orders-tab {
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.lab-manager-orders-tab.active {
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.lab-manager-orders-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;
}

.lab-manager-orders-title {
    font-size: 24px;
    font-weight: 600;
    margin: 0;
}

.lab-manager-orders-description {
    color: #666;
    margin: 8px 0 0;
    font-size: 14px;
}

.lab-manager-orders-count {
    background-color: #f4f4f5;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 14px;
    font-weight: 500;
    white-space: nowrap;
}

.lab-manager-orders-filters-container {
    background-color: #f4f4f5;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 24px;
}

.lab-manager-orders-filters-group {
    display: flex;
    gap: 30px;
}

.lab-manager-orders-filter-section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.lab-manager-orders-filter-title {
    font-size: 12px;
    font-weight: 500;
    color: #666;
    margin: 0;
}

.lab-manager-orders-filter-options {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
}

.lab-manager-orders-filter-button {
    background: none;
    border: none;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 12px;
    cursor: pointer;
    transition: all 0.2s;
}

.lab-manager-orders-filter-button:hover {
    background-color: #e4e4e7;
}

.lab-manager-orders-filter-button.active {
    background-color: #3b82f6;
    color: white;
}

.lab-manager-orders-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* max-height: calc(100vh - 460px); */
    /* overflow-y: auto; */
}

/* Order Card */

.lab-manager-order-card {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.2s;
    display: flex;
    align-items: center;
    gap: 16px;
}

.lab-manager-order-card:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.lab-manager-order-img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.lab-manager-order-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 16px;
}

.lab-manager-order-content {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.lab-manager-order-title {
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 8px 0;
}

.lab-manager-order-description {
    color: #666;
    font-size: 14px;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.lab-manager-order-status-badge {
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 12px;
    font-weight: 500;
    color: white;
}

.status-badge-new {
    background-color: #3b82f6;
}

.status-badge-pending {
    background-color: #f59e0b;
}

.status-badge-completed {
    background-color: #22c55e;
}

.status-badge-rejected {
    background-color: #ef4444;
}

.lab-manager-order-details {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
}

@media (min-width: 768px) {
    .order-details {
        grid-template-columns: repeat(4, 1fr);
    }
}

.lab-manager-order-detail-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.lab-manager-order-detail-label {
    font-size: 12px;
    color: #666;
}

.lab-manager-order-detail-value {
    font-size: 14px;
    font-weight: 500;
}

.lab-manager-order-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.lab-manager-order-modal-metadata > h2 {
    font-size: 14px;
    font-weight: 500;
    color: #666;
}

.lab-manager-no-orders {
    text-align: center;
    padding: 40px;
    color: #666;
    background: white;
    border-radius: 8px;
}

/** Order Modal */

.status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.label {
    font-size: 0.875rem;
    color: #666;
    font-weight: 500;
}

.status-badge {
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
}

.status-badge.status-approved {
    background-color: #22c55e;
}

.status-badge.status-rejected {
    background-color: #ef4444;
}

.status-badge.status-pending {
    background-color: #eab308;
}

.details-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
}

.detail-item {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.value {
    font-size: 1.125rem;
    font-weight: 600;
    margin: 0;
}

.section-title {
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 1rem;
}

.approval-section,
.rejection-section,
.approved-section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.approval-card,
.rejection-card {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 0.75rem;
    background-color: #f4f4f5;
    border-radius: 0.5rem;
}

.status-icon {
    width: 1.25rem;
    height: 1.25rem;
    flex-shrink: 0;
}

.status-icon.approved {
    color: #22c55e;
}

.status-icon.rejected {
    color: #ef4444;
}

.status-icon.pending {
    color: #eab308;
}

.approval-info,
.rejection-info {
    flex: 1;
}

.manager-name {
    font-weight: 500;
    margin: 0;
}

.approval-status,
.rejection-reason {
    font-size: 0.875rem;
    color: #666;
    margin: 0.25rem 0 0 0;
}

.success-card {
    display: flex;
    align-items: flex-start;
    gap: 0.75rem;
    padding: 0.75rem;
    background-color: #f0fdf4;
    border-radius: 0.5rem;
}

.success-message {
    font-size: 0.875rem;
    color: #166534;
    margin: 0;
}

.checkout-button {
    width: 100%;
    background-color: #3b82f6;
    color: white;
    padding: 0.75rem;
    border-radius: 0.375rem;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.checkout-button:hover {
    background-color: #2563eb;
}

.checkout-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

@media (max-width: 640px) {
    .modal-content {
        padding: 1rem;
    }

    .details-grid {
        grid-template-columns: 1fr;
    }

    .approval-card,
    .rejection-card,
    .success-card {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
    }
}
