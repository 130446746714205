.lab-profile-publications-editor {
    padding: 20px;
}

.lab-profile-publications-list {
    display: flex;
    flex-direction: column;
}

.lab-profile-publications-list > *:not(:first-child) {
    margin-top: 20px;
    border-top: 1px solid #d0d0d0;
}

.lab-profile-publication-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
}

.lab-profile-publication-item > input,
.lab-profile-publication-item > textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    font-size: 14px;
}

.lab-profile-publication-item > textarea {
    height: 100px;
}

.lab-profile-publication-item > h2 {
    letter-spacing: 0lab-profile-5px;
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 0;
    color: #535353; /* Changed from #333333 to an even softer shade */
}

.lab-profile-publication-item > h4 {
    letter-spacing: 0lab-profile-5px;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    margin-bottom: 5px;
}

.lab-profile-publication-item > h4:not(:first-child) {
    margin: 5px 0;
}

.lab-profile-publication-description {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 5px;
    color: #454545;
}

.lab-profile-publication-meta {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #666666;
}

.lab-profile-publication-date-journal {
    font-size: 12px;
    color: #666;
    display: flex;
    gap: 5px;
    align-items: center;
}

.lab-profile-publication-journal {
    font-style: italic;
    text-decoration: underline;
    /* Remove font-size and color as they'll inherit from parent */
}

/* Add this new class */
.lab-profile-publication-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    background: white;
    padding: 10px 0;
}

.lab-profile-publication-doi {
    margin-top: 5px;
    font-size: 12px;
    text-decoration: none;
    color: var(--primary-color);
}

.lab-profile-publications-editor > h3 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0lab-profile-5px;
    color: #454545;
    display: flex;
    flex: 1;
    border-bottom: 1px solid #d0d0d0;
}

.lab-profile-publications-editor-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lab-profile-publications-editor-left-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
}

.lab-profile-publications-editor-left-buttons > a {
    margin-top: 0; /* Remove top margin */
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    background-color: #007bff;
    color: white;
}

/* Add new styles for the image container */
.lab-profile-publication-image {
    width: 100%;
    overflow: visible;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: auto;
    min-height: fit-content;
    margin: 15px 0;
}

.lab-profile-publication-image img {
    width: 100%;
    height: auto;
    max-height: 400px;
    max-width: 100%;
    object-fit: contain;
}

.lab-profile-publication-authors {
    color: #707070; /* Add this style for softer black */
    font-size: 16px;
}

/* Add responsive styles */
@media screen and (max-width: 768px) {
    .lab-profile-publication-image {
        margin: 10px 0;
    }

    .lab-profile-publication-image img {
        max-height: 300px;
    }
}

@media screen and (max-width: 480px) {
    .lab-profile-publication-image img {
        max-height: 250px;
    }
}

/* Add/modify these styles */
.publication-card {
    display: flex;
    gap: 20px;
    padding: 20px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    margin-bottom: 20px;
}

.profile-publication-image {
    width: 200px;
    height: 200px;
    object-fit: cover;
    border-radius: 5px;
    flex-shrink: 0; /* Prevent image from shrinking */
}

.publication-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    min-width: 0; /* Prevent content from overflowing */
}

/* Add responsive styles for the cards */
@media screen and (max-width: 768px) {
    .publication-card {
        flex-direction: column;
        gap: 15px;
    }

    .profile-publication-image {
        width: 100%;
        height: 250px;
    }
}
