.nf-newsfeed-container {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 20px;
    font-size: 15px;
}

.nf-newsfeed-main {
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 24px;
}

.nf-newsfeed-grid {
    display: grid;
    grid-template-columns: 1.8fr 1fr;
    gap: 9px;
    max-width: 100%;
}

.nf-section-title {
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #454545;
    margin-bottom: 20px;
    border-bottom: 1px solid #d0d0d0;
    line-height: 40px;
    margin-top: 0;
}

/* Feed Cards */
.nf-feed-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 18px;
    overflow: hidden;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.nf-card-header {
    padding: 14px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-bottom: 1px solid #eee;
}

.nf-header-content {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    flex: 1;
}

.nf-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
    flex-shrink: 0;
}

.nf-avatar:hover {
    transform: scale(1.05);
}

.nf-avatar-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nf-header-text {
    min-width: 0;
    flex: 1;
}

.nf-header-text h3 {
    font-weight: 600;
    margin: 0;
    word-wrap: break-word;
    line-height: 1.4;
    margin-bottom: 6px;
    font-size: 16px;
}

.nf-subtitle {
    color: var(--primary-color);
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    transition: opacity 0.2s ease-in-out;
}

.nf-subtitle:hover {
    opacity: 0.8;
}

.nf-badge {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 3px 7px;
    border-radius: 4px;
    background: #e0e0e0;
    font-size: 13px;
    margin-left: 12px;
    flex-shrink: 0;
}

.nf-card-content {
    padding: 14px;
    font-size: 14px;
}

.nf-content-image {
    display: block;
    width: auto;
    max-width: 100%;
    max-height: 350px;
    object-fit: contain;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}

.nf-card-footer {
    padding: 10px 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #eee;
    font-size: 14px;
}

.nf-interaction-buttons {
    display: flex;
    gap: 16px;
}

.nf-interaction-buttons button {
    display: flex;
    align-items: center;
    gap: 4px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
}

/* Sidebar Cards */
.nf-sidebar-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 5px 14px;
    padding-bottom: 14px;
    margin-bottom: 29px;
    max-width: 95%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -9px;
}

.nf-sidebar-card h3 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
}

.nf-marketplace-image {
    width: 72px;
    height: 72px;
    border-radius: 8px;
    object-fit: cover;
}

.nf-marketplace-items-container {
    position: relative;
    height: 330px;
    overflow: hidden;
}

.nf-marketplace-items-wrapper {
    position: absolute;
    width: 100%;
    transition: transform 0.8s ease-in-out;
    padding-bottom: 10px;
}

.nf-marketplace-item {
    min-height: 100px;
    height: auto;
    display: flex;
    gap: 12px;
    padding: 10px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    border-radius: 8px;
    background: white;
    border-bottom: none;
    margin-bottom: 10px;
    align-items: stretch;
}

.nf-marketplace-item:hover {
    background-color: #f8f8f8;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nf-marketplace-item:active {
    transform: translateY(0);
    box-shadow: none;
}

.nf-marketplace-item:last-child {
    border-bottom: none;
}

.nf-marketplace-item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 0;
    justify-content: space-between;
}

.nf-marketplace-item-content h4 {
    margin: 0 0 4px 0;
    font-size: 15px;
    font-weight: 600;
    color: #333;
    line-height: 1.3;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nf-marketplace-item-content .nf-lab-name {
    color: #666;
    font-size: 13px;
    margin: 0 0 auto 0;
}

.nf-price {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 14px;
    margin: auto 0 0 0;
    align-self: flex-start;
}

.nf-listing-price {
    font-size: 14px;
    margin: auto 0 0 0;
    align-self: flex-start;
}

.nf-listing-price span {
    color: var(--primary-color);
}

.nf-listing-price span.nf-listing-unit {
    color: #666;
    font-weight: normal;
}

.nf-suggestion-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.nf-suggestion-item {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
}

.nf-suggestion-item:last-child {
    margin-bottom: 0;
}

.nf-suggestion-content .name {
    font-weight: 500;
    margin: 0 0 4px 0;
}

.nf-suggestion-content .details {
    color: #666;
    font-size: 14px;
    margin: 0;
}

.nf-add-button {
    position: fixed;
    bottom: 28px;
    right: 28px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 8px rgba(124, 58, 237, 0.3);
    transition: all 0.2s ease-in-out;
}

.nf-add-button:hover {
    background: #6d28d9;
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(124, 58, 237, 0.4);
}

.nf-marketplace-title {
    cursor: pointer;
    transition: color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid #d0d0d0;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
}

.nf-marketplace-title:hover {
    color: var(--primary-color);
}

.nf-lab-avatar {
    width: 50px;
    height: 50px;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
}

.nf-lab-avatar img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nf-suggestion-item {
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.nf-suggestion-item:hover {
    background-color: #f8f8f8;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nf-suggestion-item:active {
    transform: translateY(0);
    box-shadow: none;
}

.nf-lab-title {
    font-weight: 600;
    font-size: 14px;
    margin: 0 0 4px 0;
    color: #333;
}

.nf-lab-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.nf-lab-details span {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #666;
    font-size: 13px;
}

.nf-connection-avatar {
    width: 44px;
    height: 44px;
    overflow: hidden;
    position: relative;
    background-color: #d0d0d0;
    border-radius: 50%;
    flex-shrink: 0;
}

.nf-connection-avatar::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M12 11.5c2.07 0 3.75-1.68 3.75-3.75S14.07 4 12 4 8.25 5.68 8.25 7.75 9.93 11.5 12 11.5zm0 2c-3.17 0-6.75 1.53-6.75 4.5v2h13.5v-2c0-2.97-3.58-4.5-6.75-4.5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.6;
    pointer-events: none;
    z-index: 1;
}

.nf-connection-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 2;
}

.nf-connection-avatar img[src=""],
.nf-connection-avatar img[src="undefined"],
.nf-connection-avatar img:not([src]) {
    display: none;
}

.nf-connection-avatar-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
}

.nf-connection-avatar-placeholder svg {
    width: 24px;
    height: 24px;
    color: #666;
}

.nf-connection-name {
    font-weight: 600;
    font-size: 15px;
    margin: 0 0 4px 0;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nf-connection-role {
    color: var(--primary-color);
    font-size: 12px;
    margin: 0 0 2px 0;
}

.nf-connection-field {
    color: #666;
    font-size: 12px;
    margin: 0;
}

.nf-suggestion-item {
    padding: 12px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
}

.nf-suggestion-item:hover {
    background-color: #f5f5f5;
    transform: translateY(-1px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.nf-connection-lab {
    color: var(--primary-color);
    font-size: 13px;
    margin: 0 0 2px 0;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nf-connection-institution {
    color: #666;
    font-size: 13px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.nf-author-info {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 4px;
}

.nf-lab-name {
    color: #666;
    font-style: italic;
    font-size: 14px;
    transition: color 0.2s ease-in-out;
    margin-bottom: 0;
}

.nf-lab-name:hover {
    color: var(--primary-color);
}

.nf-card-content p {
    line-height: 1.6;
    margin: 0;
}

.nf-publication-links {
    display: flex;
    gap: 16px;
}

.nf-publication-link {
    color: var(--primary-color);
    text-decoration: none;
    font-size: 14px;
    transition: opacity 0.2s ease-in-out;
}

.nf-publication-link:hover {
    opacity: 0.8;
}

.nf-publication-meta {
    color: #666;
    font-size: 14px;
    margin-top: 8px !important;
    line-height: 1.4;
}

.nf-publication-journal {
    color: var(--primary-color);
    font-weight: 500;
    font-style: italic;
}

.nf-suggestion-content {
    max-width: 300px;
    min-width: 0;
    overflow: hidden;
}

/* Media query for tablet and smaller screens */
@media screen and (max-width: 1024px) {
    .nf-newsfeed-main {
        padding: 0 32px;
    }

    .nf-newsfeed-grid {
        gap: 16px;
    }
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
    .nf-newsfeed-container {
        padding: 12px;
    }

    .nf-newsfeed-main {
        padding: 0 12px;
    }

    .nf-newsfeed-grid {
        grid-template-columns: 1fr; /* Single column for mobile */
    }

    /* Hide sidebar cards on mobile */
    .nf-sidebar-column {
        display: none;
    }

    /* Adjust feed card padding for mobile */
    .nf-feed-card {
        margin-bottom: 16px;
    }

    .nf-card-header {
        padding: 12px;
    }

    .nf-card-content {
        padding: 12px;
    }

    .nf-card-footer {
        padding: 12px;
    }
}

/* Adjust max-width breakpoints for larger screens */
@media screen and (min-width: 1400px) {
    .nf-newsfeed-main {
        max-width: 1300px;
    }
}

.nf-content-link {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 500;
}

.nf-carousel-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nf-carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    transition: background 0.3s ease;
    padding: 0;
}

.nf-carousel-arrow:hover {
    background: rgba(0, 0, 0, 0.7);
}

.nf-carousel-arrow.nf-left {
    left: 10px;
}

.nf-carousel-arrow.nf-right {
    right: 10px;
}

.nf-carousel-arrow svg {
    font-size: 20px;
}

/* Media query for mobile screens */
@media screen and (max-width: 768px) {
    .nf-carousel-arrow {
        width: 32px;
        height: 32px;
    }

    .nf-carousel-arrow svg {
        font-size: 18px;
    }
}

.nf-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    padding: 20px;
    color: #666;
    margin-bottom: 20px;
    min-height: 40px;
}

.nf-loading > * {
    opacity: 0;
    transition: opacity 0.2s ease;
}

.nf-loading.visible > * {
    opacity: 1;
}

.nf-no-more {
    text-align: center;
    padding: 20px;
    color: #666;
}
