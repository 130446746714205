/* Header */
.create-lab-page {
    margin: 0;
}

.create-lab-header {
    font-size: 40px !important;
    font-weight: 600;
    text-align: center;
}

.create-lab-subheader {
    font-size: 24px;
    font-weight: 600;
}

.lab-registration-content {
    position: relative;
    background-color: #eeeeee;
    border-radius: 10px;
    padding: 10px;
    flex: 1;
}

.lab-registration-delete {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.lab-registration-delete > button {
    background-color: transparent;
    border: none;
    width: 100%;
    height: 100%;
}

.create-lab-container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.create-lab-content {
    flex: 4;
    padding-left: 20px;
}

.create-lab-content > div:not(:first-child) {
    margin-top: 20px;
}

.create-lab-section {
    padding: 20px;
    background-color: #eeeeee;
    border-radius: 10px;
}

.create-lab-section > *:last-child {
    margin-bottom: 10px;
}

/* Fields */
.create-lab-field {
    font-family: var(--font-family);
    margin-bottom: 30px;
    gap: 5px;
}

.create-lab-field > * {
    margin: 0;
}

.create-lab-field > h3 {
    font-weight: 500;
}

.create-lab-field > h4 {
    color: #666;
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 10px;
}

.input {
    padding: 0.5rem;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 0.5rem;
    width: 100%;
    font-family: var(--font-family) !important;
}

.create-lab-select {
    position: relative;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-family: var(--font-family) !important;
}

.create-lab-footer {
    margin: 10px auto 30px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.create-lab-button {
    background-color: var(--primary-color);
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.create-lab-keyword-options {
    display: inline-flex;
    width: 100%;
    gap: 10px;
}

.create-lab-keyword-options button {
    flex: none;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: white;
    background-color: var(--primary-color);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    font-family: var(--font-family);
    white-space: nowrap;
}

.create-lab-keyword-options input {
    flex: 1;
}

.create-lab-keywords {
    margin-top: 20px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.lab-registration-lab {
    padding: 20px;
    flex: 1;
}

.lab-registration-content {
    align-items: flex-start;
    display: flex;
    gap: 40px;
    max-width: 100%;
}

.lab-registration-logo {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.lab-registration-logo > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.lab-registration-header-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 5px;
    overflow: scroll;
}

.lab-registration-header-text > * {
    margin: 0;
    padding: 0;
}

.lab-registration-header-text > h2 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0px;
}

.lab-registration-header-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.lab-registration-header-info > * {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: grey;
}

.lab-registration-description {
    display: flex;
    flex-direction: column;
}

.lab-registration-description-section:not(:last-child) {
    margin: 0 0 10px;
}

.lab-registration-description-keywords {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 5px;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    scrollbar-width: none;
}

.lab-registration-description-keywords::-webkit-scrollbar {
    display: none;
}

.lab-registration-description-keywords > div {
    font-size: 10px;
    font-weight: 500;
    padding: 5px 10px;
    color: white;
    background-color: #18181b;
    opacity: 0.9;
    border-radius: 10px;
}

.lab-registration-description-lab-overview {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
    height: 50px;
    letter-spacing: -0.3px;
}
