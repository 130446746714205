.vertical-tab-container {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.vertical-tab-menu {
    position: fixed;
    top: 64px;
    left: 0;
    height: calc(100% - 64px);
    width: 250px;
    background-color: white;
    transition: width 0.3s;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.vertical-tab-menu.collapsed {
    width: 60px;
}

.vertical-tab-content {
    margin-left: 250px;
    flex-grow: 1;
    overflow-y: auto;
    transition: margin-left 0.3s;
    min-height: calc(100vh - 65px);
}

.vertical-tab-menu.collapsed + .vertical-tab-content {
    margin-left: 60px;
}

/* Additional styles for other elements */

.vertical-tab-menu-header h1,
.vertical-tab-menu-header h2 {
    margin: 0;
    padding: 10px;
}

.vertical-tab-menu-items {
    overflow-y: auto;
    max-height: calc(100% - 69px - 123px);
}

.vertical-tab-menu-items > div:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
}

.vertical-tab-menu-item.active {
    background-color: #ddd;
}

.vertical-tab-user-section {
    position: absolute;
    display: flex;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #ffffff;
    height: 60px;
    border-top: 1px solid #e8e8e8;
    justify-content: center;
    align-items: center;
}

.vertical-tab-user-info {
    cursor: pointer;
    flex: 1;
    margin-right: 10px;
}

.vertical-tab-user-dropdown {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 1100;
    background-color: #ffffff; /* Ensure dropdown is opaque */
}

.vertical-tab-user-dropdown div {
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    cursor: pointer; /* Add cursor pointer to indicate clickable */
}

.vertical-tab-user-dropdown > div:hover {
    background-color: white;
}

.vertical-tab-collapse {
    cursor: pointer;
}

.vertical-tab-menu-header h1 {
    font-size: 24px;
    margin-bottom: 0;
    text-align: center;
}

.vertical-tab-menu-header h2 {
    font-size: 14px;
    text-align: center;
}

.vertical-tab-menu-header h2:hover {
    cursor: pointer;
}

.vertical-tab-menu-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e8e8e8;
    height: 132px;
}

.vertical-tab-menu-item {
    padding: 18px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    white-space: nowrap;
    gap: 10px;
}

.vertical-tab-menu-item > span {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.vertical-tab-menu-item:hover {
    background-color: #f7f7f7;
}

.vertical-tab-menu-item.active {
    background-color: var(--primary-color);
    color: #fff;
}

.user-and-arrow {
    display: flex;
    align-items: center;
}

.arrow-icon {
    margin-left: 5px;
}

.vertical-tab-user-info {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    font-weight: bold;
    background-color: #ffffff;
}

.vertical-tab-user-dropdown {
    position: absolute;
    bottom: 50px;
    left: 0;
    width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 1100;
}

.vertical-tab-user-dropdown div {
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
}

.vertical-tab-user-dropdown div:last-child {
    border-bottom: none;
}

.vertical-tab-user-dropdown div:hover {
    background-color: #f5f5f5;
}

.vertical-tab-collapse {
    padding: 10px;
}

.collapsed .vertical-tab-menu-item {
    justify-content: center;
    padding: 12px;
    border-bottom: none;
}

.collapsed .vertical-tab-menu-item span {
    display: none;
}

.collapsed .vertical-tab-menu-item .MuiSvgIcon-root {
    visibility: visible;
}

.collapsed .vertical-tab-user-info {
    display: none;
}

.collapsed .vertical-tab-menu-header h1 {
    display: none;
}

.vertical-tab-user-info.active + .vertical-tab-user-dropdown {
    display: flex;
    flex-direction: column;
}

.change-button-area {
    text-align: right !important;
    width: max-content;
}

.change-button {
    font-family: var(--font-family) !important;
    border: 2px solid var(--primary-color) !important;
    background-color: white !important;
    color: var(--primary-color) !important;
    text-transform: none !important;
}

.vertical-tab-content h1 {
    font-family: var(--font-family);
    font-size: 28px;
    font-weight: 600;
    text-align: left;
    margin-top: 10px;
}

.vertical-tab-content-item {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

/* Add more styles as needed */

/*ListingsManager*/

.listings-manager {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    background-color: #f5f5f5;
}

.listings-header {
    margin-bottom: 10px;
}

.listings-actions {
    display: flex;
    gap: 10px;
    margin-bottom: 20px;
}

.listings-search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.btn-add-listing {
    background-color: var(--primary-color);
    color: white;
}

.listings-grid {
    width: 100%;
}

.listing-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px;
}

.listing-details {
    text-align: center;
}

.listing-info {
    font-size: 1rem;
    color: #666;
}

.modal-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: white;
    padding: 20px;
    box-shadow: 24px;
    border-radius: 4px;
}

/*Orders and develivery style*/

.orders-and-delivery {
    display: flex;
    flex-direction: column;
    padding: 20px;
    font-family: Arial, sans-serif;
    width: 100%;
    margin: auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h1 {
    font-size: 24px;
    margin: 0;
}

.search-and-settings {
    display: flex;
    align-items: center;
}

.search-and-settings input {
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.settings-button {
    padding: 8px 12px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}

.main-content {
    display: flex;
}

.top-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
}

.top-tab,
.vertical-tab {
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 16px;
    color: #333;
    text-align: left;
    transition: background-color 0.3s;
}

.top-tab:hover,
.vertical-tab:hover {
    background-color: #ddd;
}

.top-tab.active,
.vertical-tab.active {
    font-weight: bold;
    background-color: #ccc;
}

.vertical-tabs {
    display: flex;
    flex-direction: column;
    border-right: 1px solid #ccc;
    padding-right: 20px;
}

.content,
.tab-content {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    flex-grow: 1;
}

.order {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
}

.order-details {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex-grow: 1;
    border: none !important;
}

.order-details h3 {
    font-size: 18px;
    margin: 0;
    color: #333;
}

.order-details h4 {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    margin: 0;
    color: #666;
}

.order-details p {
    font-size: 14px;
    margin: 0;
    color: #333;
}

.order-actions {
    display: flex;
    justify-content: flex-end !important;
    gap: 10px;
}

.order-actions button {
    background-color: #5a1a1a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.order-actions button:hover {
    background-color: #7a2a2a;
}

.rejection-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.rejection-section textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: none;
}

.rejection-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.rejection-buttons button {
    background-color: #5a1a1a;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
}

.rejection-buttons button:hover {
    background-color: #7a2a2a;
}

.order-filters {
    flex: 1;
    padding: 20px;
    border-left: 1px solid #ccc;
    margin-left: 20px;
}

.filter-section {
    margin-bottom: 20px;
}

.filter-section h3 {
    margin-bottom: 10px;
}

.filter-section label {
    display: block;
    margin-bottom: 5px;
}

.reset-filters {
    display: block;
    margin-top: 20px;
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
}

.filter-section h3,
.filter-section label {
    font-family: var(--font-family) !important;
    font-size: 14px;
}

.header h1,
.header .search-and-settings input,
.header .search-and-settings button {
    font-family: var(--font-family) !important;
}

.MuiFormControl-root {
    width: 100%;
}

.MuiInputLabel-outlined {
    font-family: var(--font-family) !important;
    font-size: 14px !important;
}

.MuiSelect-outlined {
    font-family: var(--font-family) !important;
    font-size: 14px !important;
}

.MuiMenuItem-root {
    font-family: var(--font-family) !important;
    font-size: 14px !important;
}

.MuiFormControlLabel-label {
    font-family: var(--font-family) !important;
    font-size: 14px !important;
}
.MuiButton-contained {
    font-family: var(--font-family) !important;
    text-transform: none !important;
    background-color: var(--primary-color) !important;
    color: white !important;
}

.MuiRadio-root,
.MuiCheckbox-root {
    color: var(--primary-color) !important;
}

.MuiRadio-root.Mui-checked,
.MuiCheckbox-root.Mui-checked {
    color: var(--primary-color) !important;
}

/*Inventory*/

.inventory-container {
    padding: 20px;
    background-color: #f5f5f5;
}

.inventory-header {
    display: flex;
    align-items: stretch;
    gap: 10px;
    background-color: white;
    padding: 10px;
    border-color: #e8e8e8;
    border-style: solid;
    border-width: 1px 1px 0;
}

.inventory-header > * {
    min-height: 40px;
}

.inventory-header-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.inventory-header-buttons > * {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-radius: 5px;
    height: 100%;
}

.btn-add,
.btn-edit,
.btn-save,
.btn-remove,
.btn-search,
.btn-sds-search {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.btn-edit:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.inventory-reports {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    letter-spacing: 0.5px;
    font-size: 14px;
    line-height: 1;
}

.inventory-reports option {
    letter-spacing: 0.5px;
}

.inventory-search {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
}

.inventory-search > input {
    font-size: 14px;
    border: 1px solid #ccc;
    flex-grow: 1;
    line-height: 1.5;
    background-color: #f7f7f7;
    padding: 8px 8px 8px 36px;
    width: 100%;
    border-radius: 5px;
    flex: 1;
    height: 100%;
}

.inventory-search-icon {
    color: var(--primary-color);
    padding: 0 12px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inventory-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.inventory-table th:not(:first-child) {
    letter-spacing: 0.5px;
    white-space: nowrap;
    font-weight: 600;
    min-width: 100px;
}

.inventory-table th,
.inventory-table td {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #e8e8e8;
    text-align: left;
}

.inventory-table tbody tr:hover {
    background-color: #f5f5f5;
}

.inventory-table button {
    font-size: 12px;
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.inventory-add-item-container {
    margin: 0 0 15px;
}

.inventory-add-item-container > * {
    margin: 0;
}

.inventory-add-item-container > h2 {
    font-size: 18px;
    font-weight: 600;
}

.inventory-add-item-container > h3 {
    font-size: 14px;
    font-weight: 500;
    color: #666;
    margin: 0 0 5px;
}

.inventory-add-item-container > input,
.inventory-add-item-container > select {
    flex: 1;
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 0.5rem;
    width: 100%;
    font-family: var(--font-family) !important;
}

.inventory-select {
    flex: 1;
    width: 100%;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 6px;
    width: 100%;
    font-family: var(--font-family) !important;
}

/* Options Button with Dropdown Indicator */
.btn-options {
    position: relative;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* Dropdown Menu */
.dropdown-content {
    display: none;
    position: absolute;
    top: 214px;
    right: 30px;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content button {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    border: none;
    background: none;
    text-align: left;
}

.dropdown-content button:hover {
    background-color: #f1f1f1;
}

/* Show the dropdown menu on parent hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Adjustments for mobile or smaller screens */
@media (max-width: 768px) {
    .btn-options {
        padding: 8px 10px;
        font-size: 0.8rem;
    }
}

/* General Button Styling */
.button {
    padding: 12px 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    text-align: center;
    display: inline-block;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: var(--hover-color);
}

/* Specific styling for 'Add to listings' button */
.inventory-table .add-to-listings-button {
    margin: 5px;
}

/* Ensure consistent look for all buttons */
.btn-add,
.btn-edit,
.btn-remove,
.btn-search,
.btn-options,
.add-to-listings-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    text-align: center;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    letter-spacing: 0.5px;
    transition: background-color 0.3s ease;
}

.tabs {
    display: flex;
    justify-content: space-around;
    background-color: #f5f5f5;
    padding: 10px 0;
}

.tabs button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    position: relative;
    padding: 10px 20px;
    font-size: 16px;
    color: #333;
    transition: color 0.3s;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.tabs button:hover {
    color: #000;
}

.tabs button.active {
    color: #000;
    font-weight: 600;
}

.tabs button.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--primary-color);
    transition: background-color 0.3s;
}

/*Statistics*/

/* Add to existing Manager.css file */

.time-range-dropdown {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 10;
}

.time-range-dropdown select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    cursor: pointer;
}

.statistics-container {
    position: relative; /* Needed for absolute positioning of the dropdown */
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
    font-size: 16px;
    color: #333;
    transition: color 0.3s;
}

.tab:hover {
    color: #000;
}

.tab.active {
    font-weight: 700 !important;
    color: #000;
    border-bottom: 3px solid var(--primary-color);
}

.tab.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 3px;
    background-color: var(--primary-color);
    transition: background-color 0.3s;
}

.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%; /* Ensure buttons are aligned with the calendar width */
    padding: 10px 0; /* Add some padding at the top and bottom */
}

.modal-buttons button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.modal-buttons button:hover {
    background-color: var(--unavailable-color) !important;
}

.date-input-container {
    margin-top: 20px; /* Adjust spacing as needed */
    width: 100%; /* Ensure it spans the full width of the modal */
}

/* Change selected range background color */
.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    background-color: var(--primary-color) !important;
}

/* Listings Stats Table Styles */
.listings-stats {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.listings-stats h2 {
    margin-bottom: 10px;
}

.listings-table {
    width: 100%; /* Set table width to 100% of its container */
    table-layout: fixed; /* Helps in maintaining uniform column widths */
    border-collapse: collapse; /* Ensures borders between cells are merged */
}

.listings-table th,
.listings-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #e8e8e8;
}

/* Specific styling for the listing column */
.listings-table th:first-child,
.listings-table td:first-child {
    width: 45%; /* Optionally adjust the width to give more space */
}

/* Reduce padding for other columns to bring them closer together */
.listings-table th:not(:first-child),
.listings-table td:not(:first-child) {
    padding-left: 4px;
    padding-right: 4px;
}

.listings-table th {
    background-color: #f5f5f5;
}

.listings-table tr:last-child td {
    border-bottom: none;
}

.listing-cell {
    display: flex;
    align-items: center; /* Ensures the image and text are aligned horizontally */
}

.listing-image {
    width: 50px; /* Adjust size as needed */
    height: 50px; /* Adjust size as needed */
    object-fit: cover; /* Ensures the image covers the area without distorting aspect ratio */
    margin-right: 10px; /* Space between image and text */
    vertical-align: middle; /* Aligns image with the text */
}
.arrow {
    display: inline-block;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid black; /* Triangle pointing down */
}

/* Optional: Style for active sorting arrow */
.arrow.active {
    border-top: none;
    border-bottom: 5px solid black; /* Triangle pointing up when active */
}
.sorted {
    font-weight: bold;
    color: var(--primary-color); /* Adjust color based on your theme */
}

th {
    cursor: pointer;
}

.sub-tab-list {
    margin: 12px 0 24px 24px;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
}

.sub-tabs-collapsed .sub-tab-list {
    margin: 12px;
    display: inline-flex;
    flex-direction: column;
    gap: 12px;
}

.sub-tab-list > li {
    cursor: pointer; /* Change cursor to pointer to indicate clickable */
    list-style: inside; /* Remove default list style */
}

.sub-tabs-collapsed {
    display: block;
    position: absolute;
    left: 100%; /* Adjust this if the tabs are not aligning correctly */
    top: 228px;
    min-width: 200px; /* Or set to a fixed width if preferred */
    background-color: white;
    color: black;
    z-index: 1000;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
    visibility: visible;
}

.sub-tab-arrow {
    transition: transform 4s ease;
}

.collapsed .vertical-tab-menu-item span {
    display: inline; /* Change to inline or block as needed */
}

.finances-container {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.details-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.bank-details,
.deposit-schedule {
    flex: 1;
    margin-right: 20px; /* Adjust spacing as needed */
}

.bank-details {
    max-width: 300px; /* Adjust width as needed */
}

.deposit-schedule {
    max-width: 300px; /* Adjust width as needed */
}

.bank-details p {
    margin-bottom: 4px; /* Reduces space between paragraphs */
}

.account-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.account-details p {
    margin: 0; /* Removes margin from paragraph to tighten spacing */
}

/* Finances Modal Styles */
.finances-modal-container {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.finances-modal-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
}

.finances-form-group {
    margin-bottom: 15px;
}

.finances-input,
.finances-select {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.finances-button {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.billing-card {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc; /* subtle line between cards */
    margin-bottom: 10px; /* space between cards */
}

.billing-card span {
    margin-right: 20px; /* space between elements in a card */
    min-width: 100px; /* ensure uniform width */
}

.billing-card button {
    margin-left: auto; /* push button to the far right */
    padding: 5px 10px; /* padding inside the button */
}

.add-card-btn {
    background-color: #007bff; /* example blue color */
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px; /* rounded corners */
}

.add-card-btn:hover {
    background-color: #0056b3; /* darker blue on hover */
}

.billing-card .MuiRadio-root.Mui-disabled {
    color: var(
        --primary-color
    ); /* Set to your primary color or the original color */
    opacity: 1; /* Keep full opacity to mimic an active state */
    pointer-events: none; /* Prevents clicking */
}

/* New Card Modal Styles */
.new-card-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 500px; /* Increased width */
}

.new-card-form .form-group {
    margin-bottom: 20px;
}

.new-card-form .form-group label {
    display: block;
    margin-bottom: 5px;
}

.new-card-form .form-group input,
.new-card-form .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.new-card-form .form-row {
    display: flex;
    justify-content: space-between;
}

.new-card-form .third-width {
    width: 31%; /* Adjusted for three items in one row */
}

.new-card-form .error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: 5px;
}

/* Button layout adjustments */
.new-card-form .button-container {
    display: flex;
    justify-content: space-between; /* Aligns buttons to the far edges */
    margin-top: 20px;
}

.new-card-form button {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
}

.new-card-form button:hover {
    background-color: var(--hover-color);
}

/* Specific styles for the continue button to make it stand out */
.new-card-form button[type="submit"] {
    background-color: var(--primary-color);
}

/* Specific styles for the cancel button to make it less prominent */
.new-card-form button[type="button"] {
    background-color: #ccc;
    color: #333;
}

.new-card-form button[type="button"]:hover {
    background-color: #bbb;
}

.modal-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px 0 !important; /* Adjusted to only have vertical margin */
    flex: 1; /* Makes buttons take equal space */
    min-width: 120px !important; /* Ensures buttons have a minimum width */
}

.cancel-button {
    background-color: #6c757d !important; /* Grey for cancel/back */
    color: white;
}

.cancel-button:hover {
    background-color: #5a6268 !important; /* Darker grey on hover */
}

.continue-button {
    background-color: var(
        --primary-color
    ); /* Use your primary color variable */
    color: white;
}

.continue-button:hover,
.save-button:hover {
    /* Assuming .save-button is your class for the save button */
    background-color: grey; /* Replace #darker-shade with the actual color code you want */
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 200px; /* Add space between buttons */
}

/* Centering the Remove Card button in the Edit Address Modal */
.button-container.remove-card-container {
    display: flex;
    justify-content: center; /* Centers the button horizontally */
    margin: 20px 0; /* Adds vertical spacing around the button */
}

.remove-card-button {
    background-color: red; /* Red background for emphasis */
    color: white; /* White text color */
    padding: 10px 20px; /* Padding for better touch area */
    border-radius: 5px; /* Rounded corners */
    border: none; /* No border */
    cursor: pointer; /* Pointer cursor on hover */
    font-weight: bold; /* Bold font for better visibility */
}

.remove-card-button:hover {
    background-color: darkred; /* Darker red on hover for feedback */
}

.edit-button-shipping-billing,
.edit-button-miscellaneous {
    padding: 10px 20px; /* Consistent padding */
    background-color: var(
        --primary-color
    ) !important; /* Primary color for the button */
    color: white !important; /* Text color */
    border: none; /* No border */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    font-size: 0.9rem; /* Font size */
    text-align: center; /* Center text */
    display: inline-block; /* Inline block display */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.edit-button-shipping-billing:hover,
.edit-button-miscellaneous:hover {
    background-color: darkred !important; /* Hover color change */
}

.header-with-button {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.rotate-90 {
    transform: rotate(90deg);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .profile-picture-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .profile-picture {
        width: 100%;
        height: auto;
        max-width: 240px;
    }
}

/* Overview Manager specific styles */
.overview-manager {
    width: 100%;
}

.overview-manager-editor {
    width: 100%;
}

.overview-manager-editor-header {
    position: relative;
    background-color: var(--primary-color);
    margin-bottom: 20px;
    overflow: hidden;
    min-height: 250px;
}

.overview-manager-editor-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.4;
    filter: grayscale(100%);
}

.overview-manager-editor-content {
    position: relative;
    z-index: 2;
    padding: 20px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.overview-manager-editor-logo {
    width: 150px;
    height: 150px;
    background: white;
    border-radius: 4px;
    overflow: visible;
    flex-shrink: 0;
    position: relative;
}

.overview-manager-editor-logo-button {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: var(--primary-gray);
    color: var(--primary-color);
    border: none;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.overview-manager-editor-logo-button:hover {
    background-color: var(--hover-color);
}

/* Ensure the icon inside the button is properly sized */
.overview-manager-editor-logo-button .MuiSvgIcon-root {
    font-size: 16px;
}

.overview-manager-editor-banner-info {
    color: white;
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex-grow: 1;
    min-height: 150px;
    position: relative;
    padding-bottom: 80px;
}

.overview-manager-editor-banner-info h1,
.overview-manager-editor-banner-info h2 {
    margin: 0;
    word-wrap: break-word;
}

.overview-manager-editor-banner-buttons {
    position: absolute;
    bottom: 0;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    padding: 20px 0;
}

.overview-manager-editor-edit-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

/* Update mobile styles */
@media (max-width: 768px) {
    .overview-manager-editor-header {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .overview-manager-editor-logo {
        margin: 0 auto 20px;
    }

    .overview-manager-editor-banner-info {
        width: 100%;
        padding-left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .overview-manager-editor-banner-info .lab-profile-banner-info-badges {
        justify-content: center;
    }

    .overview-manager-editor-banner-info h1,
    .overview-manager-editor-banner-info h2 {
        text-align: center;
        width: 100%;
    }
}

/* Even smaller screens */
@media (max-width: 480px) {
    .overview-manager-editor-header {
        min-height: 520px;
        padding: 20px 10px;
    }

    .overview-manager-editor-logo {
        width: 150px;
        height: 150px;
    }
}

/* Ensure no conflicts with LabProfile styles */
.overview-manager .lab-profile-banner-info-badges > div {
    background-color: rgba(255, 255, 255, 0.1);
}

.overview-manager-editor-banner-top-buttons {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    gap: 10px;
    z-index: 3;
}

.overview-manager-editor-save-button {
    background-color: #621f1f;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

/* Update mobile styles */
@media (max-width: 768px) {
    .overview-manager-editor-banner-top-buttons {
        top: 10px;
        right: 10px;
    }
}

/* Overview Manager Link Button Styles */
.textarea-container {
    position: relative;
    width: 100%;
}

.textarea-container textarea {
    width: 100%;
    min-height: 120px;
    padding: 8px;
    padding-top: 40px; /* Add padding at the top to make room for the button */
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.add-link-button {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: var(--primary-color);
    width: auto; /* Allow button to size to content */
}

.add-link-button:hover {
    background-color: #f5f5f5;
}

/* Link Modal Styles */
.link-modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
}

.link-modal-content input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.link-modal-content input:focus {
    outline: none;
    border-color: var(--primary-color);
}

/* Remove the right padding override since we're no longer overlapping */
.overview-manager-editor-input-wrapper textarea {
    /* padding-right: 100px; <- Remove this */
}

/* Rich Text Editor Styles */
.rich-editor-wrapper {
    width: 100%;
}

.rich-editor {
    padding: 10px;
    min-height: 200px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.rich-editor-toolbar {
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
}

/* Style for toolbar options */
.rdw-option-wrapper {
    border: 1px solid #f1f1f1;
    padding: 5px;
    min-width: 25px;
    height: 25px;
    border-radius: 2px;
    margin: 0 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: white;
    text-transform: capitalize;
}

/* Style for selected toolbar options - using primary color */
.rdw-option-active {
    border: 1px solid var(--primary-color) !important;
    background-color: #f1f1f1;
}

/* Hover effect for toolbar options */
.rdw-option-wrapper:hover {
    box-shadow: 1px 1px 0px var(--primary-color);
}

/* Dropdown styles */
.rdw-dropdown-wrapper {
    border: 1px solid #f1f1f1;
    border-radius: 2px;
    margin: 0 4px;
    background: white;
}

.rdw-dropdown-optionwrapper {
    border: 1px solid var(--primary-color);
    background: white;
}

/* Markdown Display Styles */
.markdown-content {
    line-height: 1.6;
    white-space: pre-line;
}

.markdown-content br {
    display: block;
    content: "";
    margin: 0.5em 0;
}

.markdown-content p,
.markdown-content li {
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    color: #454545;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

/* List Styles */
.markdown-content ul,
.markdown-content ol {
    margin: 0.5em 0;
    padding-left: 16px;
    white-space: normal;
}

.markdown-content li {
    margin: 0.25em 0;
    white-space: normal;
    display: list-item;
}

.markdown-content ul li {
    list-style-type: disc;
}

.markdown-content ol li {
    list-style-type: decimal;
}

.markdown-content li p {
    margin: 0;
    display: inline;
}

.markdown-content strong {
    font-weight: 700;
}

.markdown-content em {
    font-style: italic;
}

/* Rich Text Editor Link Styles */
.markdown-content a {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 500;
    transition: opacity 0.2s ease-in-out;
}

.markdown-content a:hover {
    opacity: 0.8;
    text-decoration: underline;
}

/* DraftJS Link Control Styles */
.rdw-link-modal {
    height: auto !important;
    padding: 20px !important;
    width: 300px !important;
}

.rdw-link-modal-input {
    width: 100% !important;
    height: 35px !important;
    margin: 10px 0 !important;
    border: 1px solid #ccc !important;
    border-radius: 4px !important;
    padding: 0 8px !important;
    font-size: 14px !important;
}

.rdw-link-modal-buttonsection {
    margin: 0 !important;
    display: flex !important;
    gap: 10px !important;
    justify-content: flex-end !important;
}

.rdw-link-modal-btn {
    background: var(--primary-color) !important;
    color: white !important;
    border: none !important;
    padding: 8px 16px !important;
    border-radius: 4px !important;
    cursor: pointer !important;
    font-size: 14px !important;
    height: auto !important;
    min-width: 80px !important;
    text-transform: none !important;
}

.rdw-link-modal-btn:hover {
    opacity: 0.9 !important;
}

/* Style for the link decorator in the editor */
.rdw-link-decorator-wrapper {
    color: var(--primary-color);
}

.rdw-link-decorator-icon {
    fill: var(--primary-color);
}

.markdown-content,
.markdown-content ul,
.markdown-content ol,
.markdown-content li,
.rich-editor,
.rich-editor ul,
.rich-editor ol,
.rich-editor li {
    font-family: var(--font-family);
    font-size: 16px;
}

.image-upload-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: #666;
    font-size: 14px;
    text-align: center;
    padding: 10px;
}

.image-upload-instructions span {
    margin-top: 25px;
}

.paste-image-button {
    margin-top: auto;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 12px;
    color: var(--primary-color);
    background-color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
}
