.notifications-list-container {
    position: fixed;
    top: 65px;
    right: 10px;
    width: 400px;
    background-color: white;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;
    transition: opacity 0.5s ease-out;
    opacity: 1;
}

.notifications-list-container.closing {
    opacity: 0;
}

.notifications-list-container > h1 {
    font-size: 20px;
    font-weight: 600;
    padding: 0;
    text-align: center;
    letter-spacing: 0.5px;
}

.notifications-list {
    padding: 0;
    margin: 0;
    max-height: 300px;
    overflow-y: scroll;
    cursor: pointer;
}

.notifications-list > li {
    color: var(--primary-color);
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-top: 1px solid lightgrey;
}

.notifications-list > li:hover {
    background-color: lightgrey;
}

.notifications-list > li > * {
    margin: 0;
    padding: 0;
}

.notifications-list > li > h2 {
    font-size: 16px;
    font-weight: 500;
}

.notifications-list > li > h3 {
    font-size: 12px;
    font-weight: 500;
}

.notifications-list > li > h4 {
    font-size: 10px;
    font-weight: 500;
}
