:root {
    --font-family: "poppins", Helvetica, Arial, sans-serif;
    --primary-color: rgb(63, 05, 05);
    --complementary-color: #053f3f;
}

.mainNav {
    justify-content: space-between;
}

.logo {
    font-family: var(--font-family) !important;
    font-weight: bold !important;
    color: rgb(51, 50, 50) !important;
    margin-right: 5%;
    width: 125px;
    height: 64px;
}

.logo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.logo:hover {
    cursor: pointer;
}

.appBar {
    background-color: #fcfcfc !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.nav-button {
    font-family: var(--font-family);
    font-size: 12px;
    font-weight: 500;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 90px;
    border: none;
    background-color: transparent;
}

.nav-icon {
    font-size: 24px !important;
    margin: 0 0 2px;
    transition: color 0.3s;
}

.nav-icon:hover {
    color: var(
        --primary-color
    ); /* Assuming PRIMARY_COLOR is defined as a CSS variable */
}

.nav-icon-lab {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2px;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #d3d3d3;
}

.nav-icon-lab > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 1px;
}

.search {
    position: relative;
    border-radius: var(--theme-shape-border-radius);
    background-color: rgba(var(--theme-palette-common-white), 0.15);
    margin-right: var(--theme-spacing-2);
    margin-left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.search:hover {
    background-color: rgba(var(--theme-palette-common-white), 0.25);
}

.closeIconWrapper {
    display: none;
}

.closeIconWrapper.visible {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.closeIconWrapper:hover {
    cursor: pointer;
}

.searchBarWrapper {
    position: relative;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    flex: 1;
    max-width: 500px;
}

.search-results-container {
    display: none;
}

.search-results-container.visible {
    display: block;
    position: absolute;
    top: 33px;
    left: 0;
    width: 100%;
    height: fit-content;
    background-color: #fcfcfc;
    z-index: 1000;
    border-radius: 5px;
    overflow: hidden;
}

.search-results-header {
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #000;
    margin: 0;
    padding: 10px 12px;
    background-color: #f7f7f7;
}

.search-results-header:hover {
    cursor: pointer;
    text-decoration: underline;
}

.search-results-list {
    display: flex;
    flex-direction: column;
}

.search-results-list > div {
    display: inline-flex;
    align-items: center;
    gap: 10px;
    letter-spacing: 0.5px;
    font-size: 12px;
    padding: 10px 12px;
}

.search-all-results {
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.5px;
    font-size: 14px;
    font-weight: 500;
    color: var(--primary-color);
}

.search-results-list > div:hover {
    background-color: #f0f0f0;
    cursor: pointer;
}

.searchIconWrapper {
    color: var(--primary-color);
    padding: 0 12px;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.styled-input-base {
    color: var(--primary-color);
}

.styled-input-base-input {
    background-color: #f7f7f7;
    padding: 8px 8px 8px 36px;
    transition: width 0.3s;
    width: 100%;
    font-size: 14px;
    border-radius: 5px;
    border: none;
}

.nav-item-list {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoSearchContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    margin-right: 96px;
}

.css-1hvkep-MuiToolbar-root {
    padding: 0px 20px !important;
}

@media (max-width: 1200px) {
    .desktop-only {
        display: none !important;
    }

    .mobile-only {
        display: block !important;
    }

    .navLinksContainer {
        top: 0;
        bottom: 0;
        right: -100%;
        width: 100%;
        position: fixed;
        background: white;
        z-index: 30;
        padding-top: 55px;
        transition: 0.75s ease-in-out;
        justify-content: center;
    }
    .navLinksContainer.active {
        right: 0;
        width: 100%;
    }
    .nav-item-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        gap: 15px;
        padding: 20px;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;
    }
    .nav-item-list li {
        text-align: center;
        width: 100%;
        padding: 8px;
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        background-color: #ffffff;
    }
    .nav-button {
        width: 100%;
        min-width: unset;
        padding: 8px;
    }
    .nav-icon {
        margin-bottom: 4px;
    }
    .hamburgerMenu {
        display: block !important;
        margin-left: 20px;
    }
    .logoSearchContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-right: 0;
    }
    .mainNav {
        flex-direction: column;
    }
    .searchBarWrapper {
        max-width: 70%;
        margin: 0 20px;
    }
    .logo {
        margin-right: 0;
        height: 32px;
    }
}

.lab-logo {
    content: url("../assets//labgiantlogo.png");
}

/* ... existing styles ... */

@media (max-width: 600px) {
    .logo {
        width: 40px;
        height: 40px;
    }
    .searchBarWrapper {
        margin: 0 10px;
    }
    .css-1hvkep-MuiToolbar-root {
        padding: 0px 15px !important;
    }
    .styled-input-base-input {
        font-size: 11px;
    }
    .lab-logo {
        content: url("../assets/labgiantlogosmall.png");
    }
    .search-results-header {
        font-size: 11px;
    }
}

/* hamburger menu */

.hamburgerMenu {
    cursor: pointer;
    display: none;
    flex-shrink: 0;
    height: 25px;
    position: relative;
    width: 30px;
    z-index: 60;
}

.top,
.mid,
.bottom {
    background-color: var(--primary-color);
    border-radius: 5px;
    display: block;
    height: 4px;
    position: absolute;
    width: 100%;
}

.top {
    top: 0;
    transform-origin: left top;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out, transform 0.5s ease-in-out,
        background-color 0.5s ease-in-out;
}

.mid {
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.5s ease-in-out;
}

.bottom {
    bottom: 0;
    transform-origin: left bottom;
    transform: rotate(0deg);
    transition: transform 0.5s ease-in-out, transform 0.5s ease-in-out,
        background-color 0.5s ease-in-out;
}

.topClose {
    transform: rotate(45deg);
    width: 105%;
    background-color: var(--primary-color);
}

.midClose {
    opacity: 0;
}

.bottomClose {
    transform: rotate(-45deg);
    width: 105%;
    background-color: var(--primary-color);
}

.nav-dropdown {
    position: absolute;
    top: 56px;
    right: 0px;
    color: rgb(51, 50, 50) !important;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #fcfcfc;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    overflow: hidden;
}

.nav-dropdown > div {
    display: inline-flex;
    flex: 1;
    margin: 0;
    font-size: 12px;
    width: 150px;
    padding: 8px;
}

.nav-dropdown > div:hover {
    background-color: #f0f0f0;
    color: #000;
    cursor: pointer;
}

.nav-dropdown > div:not(:last-child) {
    border-bottom: 1px solid #e0e0e0;
}

.nav-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.nav-item-label {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Add these new styles */
.desktop-only {
    display: block;
}

.mobile-only {
    display: none;
}

/* Add these styles for the notifications dropdown */
.notifications-button {
    position: relative;
}

.notifications-dropdown {
    position: absolute;
    top: 56px;
    right: 0;
    background-color: #fcfcfc;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    z-index: 1000;
}

/* Add these styles for the "Get Started" button */
.nav-button.get-started {
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    padding: 4px 8px;
    transition: background-color 0.3s ease;
    font-weight: 600;
}

.nav-button.get-started:hover {
    background-color: rgba(63, 5, 5, 0.9);
}

.nav-button.get-started .nav-icon {
    color: white;
}
