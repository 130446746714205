/* Add at the beginning of the file */
.MuiAvatar-root[alt="User"] {
    display: none !important;
}

/* If there's text next to the avatar that says "User", this will hide it */
.MuiAvatar-root[alt="User"] + span {
    display: none !important;
}

.user-profile {
    display: flex;
    font-family: var(--font-family) !important;
    max-width: 1000px;
    height: auto;
    margin: 0 auto;
}

.user-card {
    position: sticky;
    top: 100px;
    left: 0;
    bottom: 40px;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    color: white;
    padding: 20px;
    width: 300px;
    box-sizing: border-box;
    border-right: 1px solid #ddd;
    overflow: auto;
    align-self: flex-start; /* This will ensure the .user-card sticks to the top as you scroll */
    border-radius: 20px;
}

.user-pic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    background-size: cover; /* Ensure the image covers the entire circle */
    background-position: center;
}

.user-name {
    font-size: 24px;
    margin: 0;
}

.user-role {
    color: #ddd;
}

.user-info {
    margin-left: 2rem !important;
    margin-top: 20px;
    padding: 20px;
    overflow-y: auto;
    height: 100vh;
    scrollbar-width: none; /* Add this property to hide the scrollbar */
    -ms-overflow-style: none; /* Add this property for Internet Explorer */
    width: calc(
        100% - 300px
    ); /* Calculate the width based on remaining space */
    box-sizing: border-box; /* Include padding in the width calculation */
}

@media screen and (max-width: 1000px) {
    .user-info {
        margin-left: 0;
        width: 100%; /* Reset width to take full available space */
    }
}

.user-info::-webkit-scrollbar {
    display: none; /* Add this property to hide the scrollbar in WebKit browsers */
}

.user-info h3 {
    margin: 0;
    margin-bottom: 10px;
}

.user-info p,
.user-info ul {
    margin: 0;
    margin-bottom: 20px;
    text-align: justify; /* Adjust text alignment as needed */
}

.user-info ul {
    list-style-type: none;
    padding: 0;
}

.user-info li {
    padding: 5px 0;
}

@media screen and (min-width: 1001px) {
    .user-name {
        font-size: 36px;
    }

    .user-pic {
        width: 150px;
        height: 150px;
    }

    .user-card {
        width: 350px;
    }

    .user-info {
        margin-left: 350px;
    }
}

@media screen and (max-width: 1000px) {
    .user-card {
        position: relative;
        width: 100%;
        height: auto;
    }

    .user-info {
        margin-left: 0;
        padding-top: 0;
    }
}

/** Edit Profile Modal Content*/
.edit-profile-pic {
    display: flex;
    justify-content: center;
    align-items: center;
}

.profile-save-button {
    background-color: var(--primary-color) !important;
    margin: auto;
    font-family: var(--font-family) !important;
    text-transform: none !important;
}

/** New UI CSS */
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.profile {
    width: 100%; /* Changed from 70% to 100% */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.profile-header {
    display: flex;
    width: 50%;
    min-width: 1100px;
    height: 296px;
    position: absolute;
    left: 50%;
    margin-top: -160px;
    transform: translateX(-50%);
    padding: 22px;
    align-items: flex-start;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08),
        0px 8px 16px -4px rgba(0, 0, 0, 0.1);
    justify-content: space-between; /* Add this to separate left and right content */
    z-index: 3;
}

.profile-pic {
    width: 250px;
    height: 250px;
    background-color: #d0d0d0;
    border-radius: 15px;
    z-index: 2;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.profile-pic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    z-index: 2;
}

.profile-pic img[src=""],
.profile-pic img[src="undefined"],
.profile-pic img:not([src]) {
    display: none;
}

.profile-pic::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 85%;
    height: 85%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M12 11.5c2.07 0 3.75-1.68 3.75-3.75S14.07 4 12 4 8.25 5.68 8.25 7.75 9.93 11.5 12 11.5zm0 2c-3.17 0-6.75 1.53-6.75 4.5v2h13.5v-2c0-2.97-3.58-4.5-6.75-4.5z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    opacity: 0.6;
    pointer-events: none;
    z-index: -1;
}

.profile-pic img:not([src$="labgiantlady.png"]) + .profile-pic::before {
    display: none;
}

.profile-info {
    flex: 1; /* Take up available space */
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
}

.profile-info-top {
    display: flex;
    flex-direction: column;
    gap: 15px; /* Add space between name/role and titles */
}

.profile-name-role {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.profile-name {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
}

.profile-role {
    font-size: 14px;
    font-weight: 500;
    padding: 5px 10px;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    color: #454545;
    background-color: #f8f8f8;
}

.profile-roles {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.profile-titles {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.profile-title-line {
    font-size: 14px;
    color: #454545;
    line-height: 1.4;
}

.profile-info-bottom {
    margin-top: auto; /* Push the bottom section to the bottom of the container */
}

.profile-field-container {
    display: flex;
    align-items: flex-end; /* Align items to the bottom */
    justify-content: flex-start; /* Align items to the left */
    flex-wrap: wrap; /* Allows items to wrap onto the next line on smaller screens */
    gap: 20px; /* Space between each field */
}

.profile-field {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between icon and text */
}

.profile-field-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background-color: #d0d0d0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.profile-field-icon img {
    background-color: white;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 50%;
}

.profile-field-text {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #454545;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.profile-field-text span {
    color: #787878;
    font-size: 14px;
    font-weight: 400;
}

.profile-content {
    width: 50%;
    min-width: 1100px;
    margin-top: 170px; /* Base margin for default header size */
    box-sizing: border-box;
}

.profile-content h1 {
    margin: 0 0 20px; /* Increased bottom margin from 10px to 20px */
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #454545;
    display: flex;
    flex: 1;
    border-bottom: 1px solid #d0d0d0;
    line-height: 45px;
}

.profile-content-section {
    margin: 0 0 30px; /* Increased from 30px to 50px */
    line-height: 1.8;
}

/* New styles for the right-side cards */
.profile-stats {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-left: 20px;
}

.profile-stat-card {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 15px;
    width: 150px;
    text-align: left;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.profile-stat-number {
    font-size: 24px;
    font-weight: 600;
    color: #333;
}

.profile-stat-label {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
}

.profile-project {
    margin-bottom: 30px;
}

.profile-project h2 {
    font-size: 22px; /* Decreased from 24px (default h2 size) */
    font-weight: 420; /* Decreased from 600 (default h2 weight) */
    margin-bottom: 10px;
    color: #454545;
    font-style: italic;
}

.profile-project-images {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;
}

.profile-project-images:has(> *:nth-child(3)) {
    justify-content: space-between;
}

.profile-project-images:has(> *:nth-child(2)):not(:has(> *:nth-child(3))) {
    justify-content: center;
    gap: 100px; /* Increase gap for two images */
}

.profile-project-image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: calc(
        33.33% - 14px
    ); /* One-third of the width minus some space for gaps */
    aspect-ratio: 1 / 1; /* Maintain a square aspect ratio */
}

.profile-project-image {
    width: 100%;
    height: 100%;
    object-fit: contain; /* This will ensure the image covers the container while maintaining its aspect ratio */
}

.publications-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    min-height: 300px;
    margin-bottom: 80px;
}

.publication-card {
    display: flex;
    background-color: #f8f8f8;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    height: auto;
    transition: transform 0.2s ease-in-out;
}

.publication-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.profile-publication-image {
    width: 300px; /* Reduced from 250px */
    height: 230px;
    object-fit: cover;
}

.publication-details {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: calc(100% - 300px);
    overflow: hidden;
}

.publication-details h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.4;
    color: #333;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Style for authors */
.publication-details p:first-of-type {
    margin: 5px 0;
    font-size: 14px;
    font-style: italic;
    color: #666;
    line-height: 1.4;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    direction: ltr;
    text-align: left;
}

/* Style for description */
.publication-details p:nth-of-type(2) {
    margin: 3px 0;
    font-size: 15px;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* Increased from 2 to 3 lines */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.2em; /* Increased from 2.8em to accommodate 3 lines */
    flex-shrink: 0;
}

.publication-bottom {
    margin-top: 10px;
    padding-top: 5px;
    border-top: 1px solid #eee;
    flex-shrink: 0; /* Prevent bottom section from shrinking */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.publication-actions {
    display: flex;
    gap: 10px;
    align-items: center;
}

/* Style for date */
.publication-details p:nth-of-type(3) {
    font-size: 14px; /* Increased from 12px */
    color: #666; /* Changed from #888 for better readability */
    margin-top: 5px;
    margin-bottom: 0;
}

/* Add new style for journal name */
.publication-journal {
    font-style: italic;
    text-decoration: underline;
    margin-left: 5px;
    display: inline;
}

.doi {
    font-size: 13px;
    color: #666;
    margin: 0;
    text-decoration: none;
    transition: color 0.2s ease;
}

.doi:hover {
    color: #007bff;
    text-decoration: underline;
}

.html-button {
    padding: 4px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    background-color: #007bff;
    color: white;
    transition: background-color 0.2s ease;
    text-decoration: none !important;
    display: inline-block;
}

.html-button:hover {
    background-color: #0056b3;
    text-decoration: none !important;
}

.download-button,
.html-button {
    padding: 4px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 13px;
    white-space: nowrap;
}

.download-button {
    background-color: #ffd700;
}

.html-button {
    background-color: #007bff;
    color: white;
}

.view-all-button,
.view-less-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: transparent;
    border: none;
    color: #454545; /* Changed to match the text color used elsewhere */
    font-size: 16px;
    cursor: pointer;
    text-decoration: underline;
}

.view-all-button:hover,
.view-less-button:hover {
    color: #666; /* Darker shade for hover state */
}

.associated-tech-resources {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 20px; /* Increased from 20px to 50px */
    margin-top: 30px; /* Added margin-top */
}

.tech-resources-slider {
    display: flex;
    transition: transform 0.3s ease-in-out;
}

.tech-resource-card {
    flex: 0 0 calc(33.33% - 14px);
    margin-right: 20px;
}

.slider-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 24px;
    color: #454545;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
}

.slider-arrow-left {
    left: 10px;
}

.slider-arrow-right {
    right: 10px;
}

.slider-arrow:hover {
    background-color: rgba(255, 255, 255, 1);
}

/* New styles for the icons */
.profile-contact-icons {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 20px; /* Add space between name and icons */
}

.profile-icon {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f0f0f0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: #454545;
}

.profile-icon:hover {
    background-color: rgba(0, 0, 0, 0.05); /* Slight background on hover */
}

/* New styles for the QR code modal */
.profile-qr-code-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.profile-qr-code-image {
    width: 200px;
    height: 200px;
    border: 2px solid black;
    border-radius: 10px;
}

.profile-qr-code-input-container {
    width: 100%;
}

.profile-qr-code-input-container label {
    display: block;
    margin-bottom: 5px;
}

.profile-qr-code-input-container input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

/* Mobile Responsive Styles */
@media screen and (max-width: 768px) {
    .profile-header {
        min-width: unset;
        width: 90%;
        height: auto;
        flex-direction: column;
        margin-top: -360px;
        padding: 15px;
        position: relative;
        left: auto;
        transform: none;
    }

    .profile-pic {
        width: 150px;
        height: 150px;
        margin: 0 auto;
    }

    .profile-info {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        align-items: center;
        text-align: center;
    }

    .profile-name {
        flex-direction: column;
        font-size: 24px;
        text-align: center;
        justify-content: center;
        width: 100%;
    }

    .profile-contact-icons {
        margin: 10px 0;
        justify-content: center;
        width: 100%;
        margin-left: 0;
    }

    .profile-name-role {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .profile-roles {
        justify-content: center;
    }

    .profile-field-container {
        justify-content: flex-start;
        margin-top: 15px;
        width: 100%;
        padding: 0 15px;
        box-sizing: border-box;
    }

    .profile-field {
        width: 100%;
        justify-content: flex-start;
    }

    .profile-field-text {
        align-items: flex-start;
        text-align: left;
        width: calc(100% - 46px);
    }

    .profile-field-text span {
        width: 100%;
        text-align: left;
    }

    .profile-stats {
        margin: 20px 0 0 0 !important;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        padding: 0 10px;
    }

    .profile-stat-card {
        width: 32% !important;
        padding: 10px;
    }

    .profile-stat-number {
        font-size: 20px;
        text-align: center;
    }

    .profile-stat-label {
        font-size: 12px;
        text-align: center;
    }

    .profile-content {
        min-width: unset;
        width: 90%;
        margin-top: 40px !important;
    }

    /* Publications section */
    .publication-card {
        flex-direction: column;
        height: auto;
        margin-bottom: 15px;
    }

    .profile-publication-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .publication-details {
        width: 100%;
        padding: 15px;
    }

    .publication-details h3 {
        font-size: 16px;
        -webkit-line-clamp: 2;
    }

    .publication-details p:first-of-type {
        /* Authors */
        font-size: 13px;
        margin: 8px 0;
    }

    .publication-details p:nth-of-type(2) {
        /* Description */
        font-size: 14px;
        -webkit-line-clamp: 2;
        max-height: 3em;
    }

    .publication-details p:nth-of-type(3) {
        /* Date */
        font-size: 12px;
    }

    .publication-bottom {
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .publication-actions {
        width: 100%;
        justify-content: flex-start;
    }

    .html-button {
        width: 100%;
        text-align: center;
        padding: 8px;
    }

    .doi {
        display: block;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

/* Additional breakpoint for very small screens */
@media screen and (max-width: 480px) {
    .profile-field-container {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }

    .profile-stats {
        flex-direction: column;
        align-items: center;
    }

    .profile-stat-card {
        width: 98% !important;
        padding: 10px;
    }

    .publication-details h3 {
        font-size: 15px;
    }

    .publication-details p:first-of-type {
        font-size: 12px;
    }

    .profile-publication-image {
        height: 180px;
    }
}

/* Medium screen breakpoint (900px to 768px) */
@media screen and (max-width: 900px) {
    .profile-header {
        min-width: unset;
        width: 90%;
        height: auto;
        margin-top: -390px;
        padding: 20px;
        position: relative;
        left: auto;
        transform: none;
    }

    .profile-pic {
        width: 200px;
        height: 200px;
    }

    .profile-content {
        min-width: unset;
        width: 90%;
        margin-top: 40px; /* Reduced margin for mobile view */
    }

    .profile-stats {
        margin: 0;
        padding: 0 10px;
    }

    .profile-stat-card {
        width: 120px;
        padding: 12px;
    }

    /* Adjust project images for medium screens */
    .profile-project-image-container {
        width: calc(50% - 10px); /* Two images per row */
    }

    /* Adjust tech resources slider for medium screens */
    .tech-resource-card {
        flex: 0 0 calc(50% - 20px); /* Two cards per view */
    }

    /* Adjust publication cards for medium screens */
    .publication-card {
        height: auto;
        min-height: 150px;
    }
}

.tech-resource-content p {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4.5em; /* Approximately 3 lines of text */
    line-height: 1.5em;
}

.tech-resource-image img {
    width: 100%;
    height: 250px;
    object-fit: contain;
}

/* Add this new class for publication-specific modal content */
.publication-modal-content {
    padding: 20px;
    max-width: 100%;
    overflow-y: auto;
}

.lab-profile-publication-title {
    font-size: 24px;
    margin-bottom: 15px;
    line-height: 1.4;
    color: #333;
}

.lab-profile-publication-authors {
    font-style: italic;
    color: #666;
    margin-bottom: 20px;
}

.lab-profile-publication-image {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    margin: 20px 0;
    border-radius: 8px;
}

.lab-profile-publication-description {
    line-height: 1.6;
}

.lab-profile-publication-meta {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

/* Mobile styles for the publication modal */
@media screen and (max-width: 768px) {
    .lab-profile-publication-title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .lab-profile-publication-authors {
        font-size: 14px;
        margin-bottom: 15px;
    }

    .lab-profile-publication-image {
        max-height: 300px;
        margin: 15px 0;
    }

    .lab-profile-publication-description {
        font-size: 15px;
    }

    .lab-profile-publication-meta {
        margin-top: 15px;
        padding-top: 15px;
    }

    .lab-profile-publication-date-journal {
        font-size: 14px;
    }

    .lab-profile-publication-links {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
    }

    .lab-profile-publication-doi {
        font-size: 14px;
        word-break: break-all;
    }

    .lab-profile-publications-editor-left-buttons {
        width: 100%;
    }

    .lab-profile-publications-editor-left-buttons a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 10px;
        background-color: #007bff;
        color: white;
        text-decoration: none;
        border-radius: 4px;
        margin-top: 10px;
    }
}

/* Add this new rule */
img[src$="labgiantlady.png"],  /* Target the specific placeholder image */
img[src=""],                   /* Empty src attribute */
img:not([src]) {
    /* Missing src attribute */
    display: none;
}

/* Adjust profile header position for narrow screens */
@media screen and (max-width: 516px) {
    .profile-header {
        margin-top: -480px; /* Move up further for narrow screens */
    }
}

@media screen and (max-width: 480px) {
    .profile-header {
        margin-top: -550px; /* Move up even further for very narrow screens */
    }

    /* Existing 480px styles remain unchanged ... */
}

/* Hide arrows when there are 3 or fewer items */
.associated-tech-resources:has(.tech-resource-card:nth-child(-n + 3):last-child)
    .slider-arrow {
    display: none;
}

/* Ensure cards are properly spaced when no scrolling is needed */
.associated-tech-resources:has(.tech-resource-card:nth-child(-n + 3):last-child)
    .tech-resources-slider {
    justify-content: space-between;
    transform: none !important; /* Prevent any transform when no scrolling needed */
}

.associated-tech-resources:has(.tech-resource-card:nth-child(-n + 3):last-child)
    .tech-resource-card {
    margin-right: 0; /* Remove right margin when no scrolling needed */
}

/* Update media query for screens between 900px and 1100px */
@media screen and (max-width: 1100px) and (min-width: 901px) {
    .profile-header {
        min-width: unset;
        width: 90%;
        height: auto;
        min-height: 296px;
        padding: 20px;
    }

    .profile-content {
        min-width: unset;
        width: 90%;
        margin-top: 220px; /* Increased from calc(296px - 100px) to provide more space */
    }

    .profile-pic {
        width: 200px;
        height: 200px;
    }

    .profile-info {
        margin-left: 20px;
    }

    .profile-stats {
        margin-left: 10px;
    }

    .profile-stat-card {
        width: 130px;
    }
}

/* Add this new class to handle dynamic content positioning */
.profile-content-wrapper {
    position: relative;
    width: 100%;
    padding-top: 20px; /* Add some spacing */
}
