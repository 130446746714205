/** General CSS **/

.lab-profile {
    align-items: center;
    width: 100%;
    font-family: var(--font-family) !important;
}

.lab-profile-centered-content {
    display: block;
    width: 75%;
    height: 350px;
    /* background-color: #0980f8; */
    position: absolute;
    left: 50%;
    top: 60px;
    transform: translateX(-50%);
    z-index: 100;
}

.lab-profile-qr-code-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.lab-profile-qr-code-input-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.lab-profile-qr-code-input-container > label {
    font-size: 12px;
    color: #787878;
}

.lab-profile-banner {
    position: relative;
    width: 100%;
    height: 250px;
    background-color: var(--primary-color);
    overflow: hidden;
}

.lab-profile-image-banner {
    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
    width: 100%;
    height: 250px;
    position: relative;
    background-position: center center;
}

.lab-profile-image-banner::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        to bottom,
        rgba(63, 5, 5, 0.5) 0%,
        rgba(63, 5, 5, 0.52) 5%,
        rgba(63, 5, 5, 0.54) 10%,
        rgba(63, 5, 5, 0.56) 15%,
        rgba(63, 5, 5, 0.58) 20%,
        rgba(63, 5, 5, 0.6) 25%,
        rgba(63, 5, 5, 0.62) 30%,
        rgba(63, 5, 5, 0.64) 35%,
        rgba(63, 5, 5, 0.66) 40%,
        rgba(63, 5, 5, 0.68) 45%,
        rgba(63, 5, 5, 0.7) 50%,
        rgba(63, 5, 5, 0.72) 55%,
        rgba(63, 5, 5, 0.74) 60%,
        rgba(63, 5, 5, 0.76) 65%,
        rgba(63, 5, 5, 0.78) 70%,
        rgba(63, 5, 5, 0.8) 75%,
        rgba(63, 5, 5, 0.82) 80%,
        rgba(63, 5, 5, 0.84) 85%,
        rgba(63, 5, 5, 0.86) 90%,
        rgba(63, 5, 5, 0.88) 95%,
        rgba(63, 5, 5, 1) 100%
    );
}

.lab-profile-banner-info {
    color: black;
    position: absolute;
    top: 70px;
    left: 280px;
    width: fit-content;
    height: 300px;
}

.lab-profile-banner-info-badges {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.lab-profile-banner-info-badges > * {
    background-color: #621f1f;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.lab-profile-banner-info h1 {
    font-size: 26px;
    font-weight: 500;
    color: white;
    letter-spacing: 0.5px;
    line-height: 1.2;
    margin: 10px 0 0;
}

.lab-profile-banner-info h2 {
    color: #c28e8e;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    margin: 0;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 100%;
    word-wrap: break-word;
}

.lab-profile-banner-metrics {
    position: absolute;
    top: 190px;
    left: 280px;
    color: white;
    display: inline-flex;
    height: fit-content;
    width: fit-content;
    margin: 0;
    gap: 20px;
}

.lab-profile-banner-metric > * {
    margin: 0;
    padding: 0;
}

.lab-profile-banner-metric h2 {
    font-size: 20px;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.lab-profile-banner-metric h3 {
    font-size: 12px;
    font-weight: 400;
    color: #c28e8e;
}

.lab-profile-banner-ellipses {
    position: absolute;
    top: -200px;
    right: -120px;
    width: 453px;
    height: 552px;
    z-index: 1;
}

.lab-profile-banner-ellipse-1,
.lab-profile-banner-ellipse-2 {
    width: 400px;
    height: 400px;
    position: absolute;
    border-radius: 50%;
    background-color: #ff2f2f;
    opacity: 0.09;
    mix-blend-mode: screen;
}

.lab-profile-banner-ellipse-1 {
    top: 0px;
    left: 0px;
}

.lab-profile-banner-ellipse-2 {
    bottom: 0px;
    right: 0px;
}

.lab-profile-banner-buttons {
    position: absolute;
    top: 190px;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.lab-profile-banner-buttons button {
    background-color: #621f1f;
    color: white;
    padding: 12px 24px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.lab-profile-banner-buttons button:last-child:disabled {
    background-color: #e0e0e0; /* Light gray background */
    color: #a0a0a0; /* Darker gray text */
    cursor: not-allowed;
}

.lab-profile-banner-buttons button:last-child {
    color: #621f1f;
    background-color: white;
    min-width: 145px;
}

.lab-profile-logo {
    width: 250px;
    height: 250px;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
    position: absolute;
    top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.lab-profile-logo img {
    width: calc(100% - 5px); /* Reduce width by 4px (2px on each side) */
    height: calc(100% - 5px); /* Reduce height by 4px (2px on each side) */
    object-fit: contain;
}

.lab-profile-nav {
    position: absolute;
    top: 330px;
    width: 250px;
    background-color: #f5f5f5;
}

.lab-profile-nav li {
    padding: 16px 20px;
    font-size: 14px;
}

.lab-profile-nav li.active {
    border-left: 4px solid #999999;
    font-weight: 600;
    background-color: #e7e7e7;
}

.lab-profile-nav li:hover {
    cursor: pointer;
    background-color: #e7e7e7;
}

.lab-profile-content-container {
    height: 300px;
    /* background-color: #0056b3; */
    position: absolute;
    top: 280px;
    left: 280px;
    width: calc(100% - 280px);
}

/** Research Overview **/
.lab-profile-content {
    margin: 0;
    padding-bottom: 40px;
}

.lab-profile-content-publications-section-wrapper h3,
.lab-profile-content-members-section-wrapper h3,
.lab-profile-content-overview-section-wrapper h3,
.lab-profile-content-listings-section-wrapper h3,
.lab-profile-content-news-section-wrapper h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #454545;
    display: flex;
    flex: 1;
    border-bottom: 1px solid #d0d0d0;
    line-height: 45px;
    margin-bottom: 9px;
    padding-bottom: 3px;
}

.lab-profile-content-overview-section-wrapper p {
    font-size: 16px !important;
    font-weight: 400;
    line-height: 28px;
    color: #454545;
}

/** OLD UI **/

.listings-manager-listing-buttons > button {
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.overview-section-wrapper h3 {
    margin: 0;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #454545;
    display: flex;
    flex: 1;
    border-bottom: 1px solid #d0d0d0;
    line-height: 45px;
}

.overview-section-wrapper p {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #787878;
}

/* Add this CSS for the section images div */
.overview-section-images {
    display: inline-flex;
    flex-wrap: wrap; /* Allow items to wrap onto the next line if necessary */
    justify-content: center;
    align-items: center;
    gap: 100px; /* Space between images */
    width: 100%;
    margin: 20px 0 40px;
}

.overview-section-images-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Ensure images do not overflow the container */
    flex: 1;
}

.overview-section-images-container > img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; /* Ensure images cover the container */
}
.section-images img:nth-child(3) {
    order: 3 !important;
    width: 100%;
}

.button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.overview-content .edit-button {
    position: absolute;
    right: 0;
    top: 0;
}

.editor .section {
    margin-bottom: 20px;
}
.input-wrapper {
    margin-bottom: 10px;
}

.dropzone {
    border: 2px dashed #d9d9d9;
    padding: 30px 40px;
    cursor: pointer;
    background-color: #fff;
    text-align: center;
    position: relative;
}

.dropzone-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.dropzone-content img {
    height: 300px;
    width: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Ensure image doesn't overflow container */
}

.dropzone-box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.image-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Spacing between images */
    width: 100%;
}

.image-preview img {
    margin-right: 5px;
    width: 50px; /* control the width of preview image */
    height: 50px; /* control the height of preview image */
    object-fit: cover;
    border: 1px solid #d9d9d9;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.plus-button {
    font-size: 2em; /* make the plus sign bigger */
    color: #d9d9d9;
}

/* Publication Item Styles */
.publications-tab {
    display: flex;
    flex-direction: column;
}

.publication-item {
    width: 100%;
    background: #fff;
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: relative; /* For absolute positioning of edit button */
}

.publication-item input[type="text"],
.publication-item input[type="file"] {
    /* Styles to ensure the inputs take full width and have padding */
    width: 100%; /* Adjust width based on parent padding */
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.publication-item .button {
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    margin-right: 10px;
}

.publication-item .save-button {
    background-color: #4caf50; /* Green */
    color: white;
}

.publication-item .delete-button {
    background-color: #f44336; /* Red */
    color: white;
}

.publication-item label {
    display: block;
    margin-bottom: 5px;
    color: #333;
}

.publication-title {
    font-weight: bold;
    margin-bottom: 5px;
}

.publication-authors {
    color: #333;
    font-size: 0.9em;
    margin-bottom: 10px;
}

.publication-description {
    font-size: 0.9em;
    line-height: 1.5;
    margin-bottom: 10px;
}

.publication-images img,
.publication-images-edit img {
    display: block; /* Replace with flex if you prefer images side by side */
    max-height: 200px;
    object-fit: contain; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Space below image */
}
.publication-images,
.publication-images-edit {
    display: flex;
    align-items: center;
    justify-content: center; /* Center images */
    gap: 10px; /* Spacing between images */
}

.image-container {
    position: relative;
    display: inline-block; /* Adjust as needed to align images */
    margin-right: 10px; /* Space between images */
}

.delete-image-button {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #ff0000;
    color: white;
    border: none;
    cursor: pointer;
}
.publication-meta {
    font-size: 0.8em;
    color: #666;
    margin-bottom: 15px;
}

.publication-doi {
    display: block;
    margin-top: 5px;
}

.publication-actions,
.editing-actions {
    display: flex;
    gap: 3px; /* Adjust the spacing between buttons */
}

.publication-actions {
    justify-content: flex-start; /* Align publication actions to the left */
}

.editing-actions {
    justify-content: flex-end; /* Align editing actions to the right */
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.edit-button,
.save-button,
.delete-button {
    display: inline-block;
    padding: 10px 15px;
    margin: 0 5px;
    text-align: center;
    background-color: #007bff;
    color: white !important;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-button {
    background-color: var(--primary-color);
}

.button:hover,
.edit-save-button:hover,
.delete-button:hover {
    background-color: #0056b3;
}

.delete-button {
    background-color: #dc3545 !important;
}

.delete-button:hover {
    background-color: #bd2130;
}

.publication-item input[type="text"],
.publication-item textarea,
.publication-item select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

::placeholder {
    color: #aaa;
    opacity: 1;
}

.publication-item input[type="text"]:focus,
.publication-item textarea:focus,
.publication-item select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.bottom-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.content-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px; /* Adjust the space between your columns as needed */
}

.publications-container {
    flex: 1; /* This will allow the container to grow */
    max-width: 75%; /* This will ensure it doesn't grow beyond 75% */
}

/* Add this new container to wrap your publications list and the add button */
.publications-list {
    margin-top: 20px;
    flex-grow: 1;
}

/* Style for the layout container of publications and search card */
.publications-layout {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-right: 320px; /* Same as search card width, creates space for the card */
}

/* Ensure the Add Publication button does not stretch full width */
.add-button {
    width: 100%; /* Change this to auto or the same width as publication items if you want */
    align-self: flex-start; /* Align the button to the start of the flex container */
    margin-top: 20px; /* Optional margin-top */
    margin: 0 !important;
}

.publication-date {
    font-size: 1em;
    color: #666;
    margin-bottom: 5px; /* adjust as needed */
}

.publication-journal {
    font-size: 1em;
    color: #666;
    margin-bottom: 15px; /* adjust as needed for space below the journal name */
    font-style: italic;
    text-decoration: underline;
    margin-left: 5px;
    display: inline;
}

.button.download-pdf {
    background-color: #ffd700; /* Yellow color for the button */
    color: #000; /* Black text color */
    display: inline-block;
    padding: 10px 15px;
    margin-right: 5px; /* Adjusted to only have margin on the right */
    margin-left: 0; /* Set left margin to 0 to align with the content above */
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.button.article-html {
    background-color: #1e90ff; /* Adjust to match the blue color you want */
    color: #fff !important; /* Text color, assuming you want white */
    display: inline-block;
    padding: 10px 15px;
    margin: 0 5px;
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Hover states for buttons */
.button.download-pdf:hover {
    background-color: #e6c300; /* Darker yellow for hover state */
}

.button.article-html:hover {
    background-color: #1c86ee; /* Darker blue for hover state */
}

.search-card {
    width: 400px; /* Fixed width */
    position: sticky;
    top: 20px;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.search-card input[type="text"] {
    width: 100%; /* Adjusting for padding */
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: var(--font-family) !important;
}

.find-article {
    display: flex;
    justify-content: space-between; /* Adjust this to control spacing */
}

.date-container {
    flex-grow: 1; /* Allows the container to fill the space */
    display: flex;
    flex-direction: column;
    width: 10%; /* Adjust this to control the width of the date inputs */
}

.date-container label {
    font-size: 0.75em;
    color: #555;
    margin-bottom: 5px;
}

.search-card input[type="date"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%; /* This ensures the input fills its container */
    font-family: var(--font-family) !important;
    font-size: 0.85em;
}

.find-article button {
    padding: 10px 15px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
    flex-shrink: 0;
    margin-top: 5%;
    font-family: var(--font-family) !important;
}

.find-article button:hover {
    background-color: #555;
}

.upload-image-button {
    margin-bottom: 20px !important; /* Adjust the space as needed */
}

/* Ensure that all inputs and textareas use the specified font family */
input,
textarea,
select,
button,
::placeholder {
    font-family: var(--font-family) !important;
}

/* Fix for MUI TextField placeholders */
.MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-input,
.MuiInputBase-root.MuiOutlinedInput-root .MuiAutocomplete-input::placeholder,
.MuiInputBase-input,
.MuiInputBase-input::placeholder {
    font-family: var(--font-family) !important;
}

/** Overview Manager Editor **/

.overview-manager-editor-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 100;
    padding: 20px;
}

.overview-manager-editor-banner-background-color {
    background-color: var(--primary-color);
    width: 100%;
    height: 190px;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.95;
}

.overview-manager-editor-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 190px;
    background-size: cover;
    background-position: center;
    opacity: 0.4;
    z-index: 0;
    filter: grayscale(100%);
}

.overview-manager-editor-logo {
    width: 150px;
    height: 150px;
    border: 1px solid #ccc;
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.overview-manager-editor-logo img {
    width: 100%; /* Reduce width by 4px (2px on each side) */
    height: 100%; /* Reduce height by 4px (2px on each side) */
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
}

.overview-manager-editor-logo-button {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 5px;
    right: 5px;
    border: none;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--primary-color);
    z-index: 100;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.overview-manager-editor-banner-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.overview-manager-editor-banner-button {
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    font-weight: 500;
    padding: 8px 16px;
    gap: 5px;
    background-color: #621f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 4 !important;
    margin: 0;
}

.overview-manager-editor-banner-button:hover {
    background-color: var(--primary-color);
    color: white;
}

.overview-manager-editor-logo-button:hover {
    background-color: var(--primary-color);
    color: white;
}

.overview-manager-editor-banner-info {
    flex: 1;
    padding-left: 20px;
    z-index: 2;
}

.overview-manager-editor-banner-info h1,
.overview-manager-editor-banner-info h2 {
    letter-spacing: 0.5px;
}

.overview-manager-editor-banner-info h1 {
    font-size: 16px;
    margin-bottom: 10px;
    color: white;
}

.overview-manager-editor-banner-info h2 {
    margin-bottom: 20px;
    font-size: 14px;
    color: #c28e8e;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    width: 100%;
    word-wrap: break-word;
}

.overview-manager-editor-edit-button {
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    font-size: 16px;
    border: none;
    font-weight: 500;
    padding: 8px 16px;
    gap: 5px;
    background-color: #621f1f;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
}

.overview-manager-editor-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.overview-manager-editor-input-wrapper input,
.overview-manager-editor-input-wrapper textarea {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-family: var(--font-family) !important;
    letter-spacing: 0.5px;
}

.overview-manager-editor-sections {
    padding: 20px;
}

.overview-manager-editor-section-wrapper button {
    letter-spacing: 0.5px;
    display: inline-flex; /* Use inline-flex to ensure the button only takes the width of its content */
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Horizontally center the content */
    text-align: center; /* Centers the text inside the button */
    cursor: pointer; /* Adds a pointer cursor on hover */
    border-radius: 5px;
    font-size: 16px;
    border: none;
    font-weight: 500;
    padding: 8px 16px;
    gap: 5px;
    color: var(--primary-color);
}

.overview-manager-editor-section-images {
    display: flex;
    gap: 10px;
}

.overview-manager-editor-section-images > label {
    flex: 1;
}

.overview-manager-editor-section-images-image-upload-label {
    width: 200px;
    height: 200px;
    border: 2px dashed #ccc;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
}

.overview-manager-editor-section-images-image-upload-label:not(:last-child) {
    margin-right: 10px;
}

.overview-manager-editor-section-images-image-upload-square {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
}

.overview-manager-editor-section-images-delete-image {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 5px !important;
    right: 5px !important;
    padding: 5px 10px;
    font-size: 12px;
    color: var(--primary-color);
    background-color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    line-height: normal;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
}

.overview-manager-editor-section-images-delete-image:hover {
    background-color: var(--primary-color);
    color: white;
}

.overview-manager-editor-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.overview-manager-editor-buttons > * {
    letter-spacing: 0.5px;
    display: inline-flex; /* Use inline-flex to ensure the button only takes the width of its content */
    align-items: center; /* Vertically center the content */
    justify-content: center; /* Horizontally center the content */
    text-align: center; /* Centers the text inside the button */
    cursor: pointer; /* Adds a pointer cursor on hover */
    border-radius: 5px;
    font-size: 16px;
    border: none;
    font-weight: 500;
    padding: 8px 16px;
    gap: 5px;
}

.overview-manager-editor-add-section-button {
    color: var(--primary-color);
}

.overview-manager-editor-save-button {
    background-color: var(--primary-color);
    color: white;
}

/* Add responsive breakpoints */
@media screen and (max-width: 1300px) {
    .lab-profile-centered-content {
        width: 90%;
        padding: 0;
        height: auto;
    }

    /* Convert tab navigation to horizontal list and position below banner */
    .lab-profile-nav {
        position: relative;
        top: 360px;
        width: 100%;
        display: flex;
        overflow-x: visible;
        white-space: normal;
        background: none;
        padding: 0;
        margin: 0;
        border-bottom: 1px solid #e7e7e7;
        justify-content: center;
    }

    .lab-profile-nav li {
        flex: 1;
        min-width: 0;
        border-left: none;
        border-bottom: 2px solid transparent;
        padding: 16px 10px;
        font-size: 14px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .lab-profile-nav li.active {
        border-left: none;
        border-bottom: 2px solid #999999;
        background-color: transparent;
    }

    /* Adjust content container to match new tab position */
    .lab-profile-content-container {
        position: relative;
        top: 360px;
        left: 0;
        width: 100%;
        padding: 20px;
        margin-top: 10px;
    }

    .lab-profile-nav::-webkit-scrollbar {
        display: none;
    }

    .lab-profile-nav {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

@media screen and (max-width: 1125px) {
    .lab-profile-centered-content {
        width: 100%;
    }

    /* Adjust banner layout */
    .lab-profile-banner,
    .lab-profile-image-banner {
        height: 460px;
    }

    .lab-profile-logo {
        width: 180px;
        height: 180px;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
    }

    .lab-profile-banner-info {
        top: 250px;
        left: 0;
        width: 100%;
        text-align: center;
        height: auto;
    }

    /* Adjust metrics and buttons */
    .lab-profile-banner-metrics {
        top: 350px;
        left: 0;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
    }

    .lab-profile-banner-buttons {
        top: 420px;
        right: auto;
        left: 0;
        width: 100%;
        justify-content: center;
    }

    /* Update nav and content positions for larger banner height */
    .lab-profile-nav {
        top: 480px;
    }

    .lab-profile-content-container {
        top: 480px;
    }
}

@media screen and (max-width: 480px) {
    .lab-profile-banner,
    .lab-profile-image-banner {
        height: 620px !important;
    }

    /* Keep metrics in a row but adjust positioning */
    .lab-profile-banner-metrics {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        top: 370px;
        padding: 0 10px;
    }

    /* Stack buttons vertically with proper spacing */
    .lab-profile-banner-buttons {
        flex-direction: column;
        gap: 10px;
        padding: 0 20px;
        top: 440px;
    }

    .lab-profile-banner-buttons button {
        width: 100%; /* Make buttons take full width */
        justify-content: center; /* Center the text and icons */
    }

    /* Update nav and content positions to prevent overlap */
    .lab-profile-nav {
        top: 630px !important; /* Increased from 640px to 660px to prevent overlap */
    }

    .lab-profile-content-container {
        top: 620px !important; /* Increased from 640px to 660px to maintain alignment */
    }
}

@media screen and (max-width: 516px) {
    .lab-profile-banner,
    .lab-profile-image-banner {
        height: 580px;
    }

    .lab-profile-banner-info {
        padding: 0 20px;
    }

    .lab-profile-banner-info-badges {
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
    }

    .lab-profile-banner-info h1 {
        font-size: 22px;
        line-height: 1.3;
        margin: 15px 0 10px;
    }

    /* Adjust metrics spacing */
    .lab-profile-banner-metrics {
        top: 410px;
    }

    .lab-profile-banner-metric {
        text-align: center;
    }

    /* Move buttons down further */
    .lab-profile-banner-buttons {
        top: 480px;
    }

    /* Update nav and content positions */
    .lab-profile-nav {
        top: 600px;
    }

    .lab-profile-content-container {
        top: 600px;
        padding: 20px 15px;
    }
}

/* Add even smaller breakpoint for very narrow screens */
@media screen and (max-width: 405px) {
    .lab-profile-banner,
    .lab-profile-image-banner {
        height: 620px;
    }

    .lab-profile-banner-info h1 {
        font-size: 20px;
    }

    .lab-profile-banner-info h2 {
        font-size: 13px;
    }

    /* Keep metrics in a row but adjust positioning */
    .lab-profile-banner-metrics {
        flex-direction: row;
        justify-content: center;
        gap: 20px;
        top: 420px;
        padding: 0 10px;
    }

    /* Stack buttons vertically with proper spacing */
    .lab-profile-banner-buttons {
        flex-direction: column;
        gap: 10px;
        padding: 0 20px;
        top: 485px;
    }

    /* Update nav and content positions */
    .lab-profile-nav {
        top: 640px;
    }

    .lab-profile-content-container {
        top: 640px;
    }
}

/* Add specific adjustments for screens between 480px and 516px */
@media screen and (min-width: 481px) and (max-width: 516px) {
    .lab-profile-banner-metrics {
        top: 390px;
        gap: 25px;
    }

    .lab-profile-banner-buttons {
        top: 460px;
    }

    /* Remove negative margins and adjust spacing */
    .lab-profile-content {
        margin-top: 0;
    }

    .lab-profile-content-publications-section-wrapper,
    .lab-profile-content-members-section-wrapper,
    .lab-profile-content-overview-section-wrapper,
    .lab-profile-content-listings-section-wrapper {
        margin-top: 10px; /* Reduced from 20px */
    }

    /* Adjust container positions to remove extra space */
    .lab-profile-nav {
        top: 580px;
        margin-bottom: 0; /* Ensure no extra margin at bottom */
    }

    .lab-profile-content-container {
        top: 580px; /* Reduced from 620px to be closer to nav */
        padding-top: 10px; /* Add small padding instead of large gap */
    }

    .lab-profile-banner,
    .lab-profile-image-banner {
        height: 560px;
    }
}

/* Add additional adjustments for smaller screens */
@media screen and (max-width: 516px) {
    .lab-profile-nav li {
        padding: 12px 8px;
        font-size: 12px;
    }
}

@media screen and (max-width: 480px) {
    .lab-profile-banner-info h2 {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: normal;
        overflow: hidden;
    }
}

/* Research Overview Link Styles */
.lab-profile-content-overview-section-wrapper a {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 500;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
}

.lab-profile-content-overview-section-wrapper a:hover {
    opacity: 0.8;
}

/* Ensure links don't break the text flow */
.lab-profile-content-overview-section-wrapper p {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #454545;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

/* Rich Text Content Styles */
.lab-profile-content-overview-section-wrapper .markdown-content,
.lab-profile-content-overview-section-wrapper p {
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
    color: #454545;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

/* Lists styling */
.lab-profile-content-overview-section-wrapper ul,
.lab-profile-content-overview-section-wrapper ol {
    padding-left: 24px;
    margin: 16px 0 !important;
}

.lab-profile-content-overview-section-wrapper li {
    margin-bottom: 8px;
    line-height: 24px;
}

/* Text formatting */
.lab-profile-content-overview-section-wrapper strong,
.lab-profile-content-overview-section-wrapper b {
    font-weight: 600;
}

.lab-profile-content-overview-section-wrapper em,
.lab-profile-content-overview-section-wrapper i {
    font-style: italic;
}

/* Links styling */
.lab-profile-content-overview-section-wrapper a {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 500;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
}

.lab-profile-content-overview-section-wrapper a:hover {
    opacity: 0.8;
}

/* Line breaks */
.lab-profile-content-overview-section-wrapper br {
    display: block;
    content: "";
    margin: 8px 0;
}
