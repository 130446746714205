/* Loading container */
.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Center vertically in the viewport */
}

/* Loading spinner */
.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid var(--primary-color); /* Change the color to your liking */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite; /* Add a spinning animation */
}

/* Loading message */
.loading p {
    letter-spacing: 0.5px;
    margin-top: 10px;
    font-size: 18px;
    color: #333; /* Change the color to your liking */
}

/* Keyframes for the spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
