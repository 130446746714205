.filter-area {
    margin: auto;
    text-align: center;
}

ul {
    list-style-type: none;
}

.listing-card:hover {
    cursor: pointer;
}

.img-area {
    width: 400px;
    height: 400px;
    background-color: var(--primary-color);
    margin-right: 20px;
}

.text-area {
    width: calc(100% - 200px - 20px);
}

.listing-title {
    font-weight: 600;
    font-size: 25px;
}

.listing-content-area-compressed {
    margin-top: 20px;
    white-space: pre-line;
    max-height: 90px;
    overflow: hidden;
}

.listing-content-area {
    margin-top: 20px;
    white-space: pre-line;
}

label.Mui-focused {
    color: var(--primary-color) !important;
}

.MuiInput-underline:after {
    border-bottom-color: var(--primary-color) !important;
}

.primary-color {
    background-color: var(--primary-color) !important;
}

.paging-area {
    text-align: center;
}

.paging {
    margin: auto !important;
    width: 1139px !important;
}

.listings-container {
    display: flex;
}

.listing-info-area {
    height: 100% !important;
    width: calc(100% - 40px - 20px - 50%) !important;
    margin: 20px !important;
    position: sticky !important;
    top: 50px !important;
}

.listing-info-card {
    padding: 20px;
}

.browse-listings-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
}

.browse-listings-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative; /* Add this line */
}

.browse-listings-pagination {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    height: 40px;
}

.browse-listings-pagination-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    font-weight: 500;
    opacity: 0; /* Start with opacity 0 */
    animation: fadeIn 0.3s ease-in-out forwards; /* Apply fade-in animation */
}

@keyframes fadeIn {
    to {
        opacity: 1; /* End with opacity 1 */
    }
}

.browse-listings-pagination-loading-spinner {
    color: var(--primary-color) !important;
}
.pagination .Mui-selected {
    background-color: var(--primary-color) !important;
    color: white !important;
}

.lab-listings-pagination > button {
    background-color: transparent;
    border: none;
}

.browse-listings-container-content {
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-grow: 1;
}

.button-area {
    display: flex;
    width: 100%;
}

.action-button {
    width: 100px;
    margin-right: 10px !important;
    background-color: var(--primary-color) !important;
}

.price {
    margin: 0;
    margin-left: auto;
    white-space: nowrap;
    flex-shrink: 0;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.modal-button {
    color: white !important;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
}

.bold {
    font-weight: bold;
}

.listing-subtitles {
    font-weight: bold;
    margin: 10px 0;
}

/* View toggle styles */
.view-toggle {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
    justify-content: flex-end; /* Align buttons to the right */
    width: 100%; /* Ensure it takes full width */
    box-sizing: border-box; /* Include padding and border in element's total width */
    padding-right: 60px; /* Adjust padding to align with listings */
}

.view-toggle-button {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid #e0e0e0;
    background: white;
    cursor: pointer;
    transition: all 0.2s ease;
}

.view-toggle-button.active {
    background-color: var(--primary-color);
    color: white;
    border-color: var(--primary-color);
}

.view-toggle-button:first-child {
    border-radius: 4px 0 0 4px;
}

.view-toggle-button:last-child {
    border-radius: 0 4px 4px 0;
}

/* List view styles */
.lab-listings-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.lab-listings-list-item {
    display: flex;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.2s ease;
    cursor: pointer;
    max-width: 100%; /* Ensure card doesn't overflow container */
}

.lab-listings-list-item:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
}

.lab-listings-list-item .lab-listings-listing-img {
    width: 240px;
    height: 180px;
    object-fit: cover;
}

.lab-listings-list-item .lab-listings-listing-info {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    min-width: 0; /* Allow flex item to shrink below content size */
    width: 100%; /* Take up remaining space */
}

.lab-listings-list-item .lab-listings-listing-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0; /* Remove bottom margin */
    flex-wrap: nowrap;
    width: 100%;
    min-width: 0; /* Allow flex item to shrink below content size */
}

.lab-listings-list-item .lab-listings-listing-name {
    font-size: 20px;
    font-weight: 600;
    margin-right: 20px;
    max-width: calc(100% - 260px); /* More space for price */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-shrink: 1; /* Allow title to shrink */
}

.lab-listings-list-item .lab-listings-listing-description {
    color: #666;
    margin: 10px 0;
    flex-grow: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 5; /* Limit to 5 lines */
    line-clamp: 5; /* Fallback for non-webkit browsers */
    max-height: calc(
        1.2em * 5
    ); /* Fallback for browsers that don't support line-clamp */
}

.lab-listings-list-item .lab-listings-listing-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    flex-wrap: nowrap; /* Ensure items stay on one line */
}

.lab-listings-list-item .lab-listings-listing-lister {
    display: flex;
    align-items: center;
    flex-grow: 1; /* Allow the lister to take up remaining space */
    overflow: hidden; /* Hide overflow text */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    max-width: calc(100% - 160px); /* Adjust max-width to prevent overlap */
    margin-right: 20px; /* Add right margin to match info above */
}

.lab-listings-list-item .lab-listings-listing-lister-name {
    margin-left: 4px; /* Add some space between icon and text */
    overflow: hidden; /* Hide overflow text */
    white-space: nowrap; /* Prevent text from wrapping */
    text-overflow: ellipsis; /* Add ellipsis for overflow text */
    max-width: 100%; /* Allow full width usage */
}

.lab-listings-list-item .lab-listings-listing-type {
    margin-left: 20px; /* Add left margin to match info above */
}

.lab-listings-list-item .lab-listings-listing-price {
    flex-shrink: 0; /* Prevent price from shrinking */
    min-width: 120px;
    text-align: right;
}

/* Update media queries */
@media screen and (max-width: 1200px) {
    .lab-listings-list-item .lab-listings-listing-name {
        max-width: calc(100% - 280px);
    }
}

@media screen and (max-width: 900px) {
    .lab-listings-list-item .lab-listings-listing-name {
        max-width: calc(100% - 260px);
    }

    .lab-listings-list-item .lab-listings-listing-description {
        -webkit-line-clamp: 3; /* Show 3 lines */
        line-clamp: 3;
        max-height: calc(1.2em * 3); /* Fallback */
        margin: 8px 0; /* Slightly reduced margins */
    }
}

@media screen and (max-width: 800px) {
    .lab-listings-list-item .lab-listings-listing-name {
        max-width: calc(100% - 240px);
        font-size: 18px;
    }

    .lab-listings-list-item .lab-listings-listing-description {
        -webkit-line-clamp: 2; /* Show 2 lines */
        line-clamp: 2;
        max-height: calc(1.2em * 2); /* Fallback */
        margin: 5px 0; /* Reduce margins */
    }
}

@media screen and (max-width: 765px) {
    .lab-listings-list-item .lab-listings-listing-name {
        max-width: calc(100% - 220px);
        font-size: 16px;
    }

    /* Force grid view */
    .lab-listings-list {
        display: none !important;
    }

    .lab-listings-grid {
        display: grid !important;
    }

    /* Hide the view toggle buttons */
    .view-toggle {
        display: none;
    }
}

/* Add/update grid view styles for smaller screens */
@media screen and (max-width: 888px) {
    .lab-listings-grid .lab-listings-listing-footer {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
    }

    .lab-listings-grid .lab-listings-listing-lister {
        flex: 1;
        min-width: 0; /* Allow flex item to shrink below content size */
        margin-left: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .lab-listings-grid .lab-listings-listing-lister-name {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 100%;
    }

    .lab-listings-grid .lab-listings-listing-type {
        flex-shrink: 0; /* Prevent type badge from shrinking */
        min-width: 80px;
    }

    .lab-listings-grid .lab-listings-listing-name {
        max-width: calc(100% - 120px); /* Adjust to show more of the name */
    }
}

@media screen and (max-width: 600px) {
    .lab-listings-grid .lab-listings-listing-lister {
        max-width: calc(100% - 100px); /* Adjust max-width to prevent overlap */
    }
}

/* Update grid view lab name display */
.lab-listings-grid .lab-listings-listing-footer {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.lab-listings-grid .lab-listings-listing-lister {
    flex: 1;
    min-width: 0;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 110px); /* Increased space for type tag + gap */
}

.lab-listings-grid .lab-listings-listing-lister-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 100%;
}

.lab-listings-grid .lab-listings-listing-type {
    flex-shrink: 0;
    min-width: 80px;
    /* Removed margin-left: auto */
}
