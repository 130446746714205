/* START OF NORMALIZE SNIPPET */

html {
    line-height: 1.15;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
body {
    margin: 0;
}
article,
aside,
footer,
header,
nav,
section {
    display: block;
}
h1 {
    font-size: 2em;
    margin: 0.67em 0;
}
figcaption,
figure,
main {
    display: block;
}
figure {
    margin: 1em 40px;
}
hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}
pre {
    font-family: monospace, monospace;
    font-size: 1em;
}
a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}
abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}
b,
strong {
    font-weight: inherit;
}
b,
strong {
    font-weight: bolder;
}
code,
kbd,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}
dfn {
    font-style: italic;
}
mark {
    background-color: #ff0;
    color: #000;
}
small {
    font-size: 80%;
}
sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}
sub {
    bottom: -0.25em;
}
sup {
    top: -0.5em;
}
audio,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
img {
    border-style: none;
}
svg:not(:root) {
    overflow: hidden;
}
button,
input,
optgroup,
select,
textarea {
    font-family: sans-serif;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}
button,
input {
    overflow: visible;
}
button,
select {
    text-transform: none;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
    appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}
fieldset {
    padding: 0.35em 0.75em 0.625em;
}
legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0;
    white-space: normal;
}
progress {
    display: inline-block;
    vertical-align: baseline;
}
textarea {
    overflow: auto;
}
[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}
[type="search"] {
    -webkit-appearance: textfield;
    appearance: textfield;
    outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
}
::-webkit-file-upload-button {
    -webkit-appearance: button;
    appearance: button;
    font: inherit;
}
details,
menu {
    display: block;
}
summary {
    display: list-item;
}
canvas {
    display: inline-block;
}
template {
    display: none;
}
[hidden] {
    display: none;
}

* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

/* END OF NORMALIZE SNIPPET */

/* To place the footer */
.pageWrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pageContent {
    flex: 1;
    margin-top: 64px;
}

.pageContent.dimmed::before {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.navigation {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000; /* High z-index to keep it above other content */
    background: #fff; /* Assuming a white background, adjust as needed */
    box-shadow: none;
    border-bottom: 1px solid #e0e0e0;
}

.standard-button {
    background-color: rgb(63, 5, 5);
    color: white;
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 500;
    /* text-transform: uppercase; */
    letter-spacing: 0.5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s, box-shadow 0.3s;
    /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
    user-select: none;
}

.standard-button:hover {
    background-color: rgb(83, 25, 25);
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); */
}

.standard-button:active {
    background-color: rgb(43, 0, 0);
    /* box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); */
}

.standard-button:disabled {
    background-color: rgba(63, 5, 5, 0.5);
    cursor: not-allowed;
    box-shadow: none;
}

.error {
    color: red;
}
