.news-manager {
    padding: 20px;
    min-height: 100vh;
}

.news-manager-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.news-manager-title {
    margin: 0;
    padding-bottom: 10px;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0.5px;
    color: #454545;
    display: flex;
    flex: 1;
    border-bottom: 1px solid #d0d0d0;
}

.news-manager-add-button {
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-flex;
    color: var(--primary-color);
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    outline: none;
    height: 40px;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.news-manager-list {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.news-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
    margin-bottom: 15px;
    height: auto;
    min-height: fit-content;
    border-bottom: 1px solid #d0d0d0;
    padding: 20px 0;
}

.news-item:last-child {
    border-bottom: none;
}

.news-item-editor {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.news-item-editor input,
.news-item-editor textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    font-size: 14px;
}

.news-item-editor textarea {
    min-height: 100px;
    resize: vertical;
    padding-top: 39px !important;
}

.news-item-image-upload {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    margin: 10px 0;
    padding: 10px;
    min-height: 0;
    position: relative;
}

.news-item-image-preview {
    width: 100%;
    aspect-ratio: 3/2;
    background-size: cover;
    background-position: center;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.image-upload-placeholder {
    border: 2px dashed #d0d0d0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666;
    transition: all 0.3s ease;
}

.image-upload-placeholder:hover {
    border-color: var(--primary-color);
    color: var(--primary-color);
}

.carousel-container {
    position: relative;
    width: 100%;
    margin: 15px 0;
    display: flex;
    justify-content: center;
}

.carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    transition: background 0.3s ease;
}

.carousel-arrow:hover {
    background: rgba(0, 0, 0, 0.7);
}

.carousel-arrow.left {
    left: 10px;
}

.carousel-arrow.right {
    right: 10px;
}

.carousel-image {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 420px;
    object-fit: contain;
}

.news-item-actions {
    display: flex;
    gap: 10px;
    margin-top: 15px;
    justify-content: space-between;
    align-items: center;
}

.news-item-actions .action-buttons {
    display: flex;
    gap: 10px;
}

.news-item-date {
    color: #666;
    font-size: 14px;
    font-style: italic;
}

.news-item-actions button {
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.news-item-actions button:first-child {
    background-color: var(--primary-color);
    color: white;
}

.news-item-actions button:last-child {
    background-color: #dc3545;
    color: white;
}

.news-item-display {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.news-item-display h3 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin: 0;
}

.news-item-display img {
    max-width: 100%;
    max-height: 300px;
    height: auto;
    border-radius: 5px;
    object-fit: contain;
}

.news-item-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 15px 0;
}

.news-item-image-container img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 420px;
    object-fit: contain;
}

.delete-image-button {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0;
    z-index: 1;
}

.delete-image-button:hover {
    background-color: #c82333;
}

.textarea-container {
    position: relative;
    width: 100%;
}

.add-link-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    z-index: 1;
}

.add-link-button:hover {
    background: #f0f0f0;
    color: #333;
}

.link-modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.link-modal-content input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.delete-confirmation-content {
    padding: 10px 0;
}

.delete-confirmation-content p {
    margin: 0 0 15px 0;
    font-size: 15px;
    color: #333;
}

.delete-title {
    font-weight: 500;
    color: #666 !important;
    font-style: italic;
}
.delete-warning {
    color: #dc3545 !important;
    font-size: 14px !important;
}

/* Add these new mobile styles */
@media screen and (max-width: 768px) {
    .news-editor-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
    }

    .news-editor-overlay.active {
        display: block;
        opacity: 1;
        pointer-events: auto;
    }

    .news-editor-slideup {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 80vh;
        background-color: white;
        z-index: 1000;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        border-radius: 20px 20px 0 0;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }

    .news-editor-slideup.active {
        transform: translateY(0);
    }

    .news-editor-card {
        position: relative;
        height: 100%;
        padding: 20px;
        overflow-y: auto;
        padding-bottom: 80px; /* Space for footer */
    }

    .news-editor-close-button {
        position: absolute;
        top: 15px;
        right: 20px;
        width: 30px;
        height: 30px;
        background-color: #f0f0f0;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1002;
        color: #454545;
        font-size: 30px;
        border-radius: 50%;
    }

    .news-editor-close-button::before {
        content: "";
        position: absolute;
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
        background: white;
        border-radius: 50%;
        z-index: -1;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .news-editor-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 15px;
        background: white;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1001;
        display: flex;
        justify-content: flex-end;
    }

    .news-editor-footer button {
        background-color: var(--primary-color);
        color: white;
        border: none;
        padding: 12px 24px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .news-editor-card h2 {
        margin: 0 0 20px 0;
        font-size: 24px;
        padding-right: 40px;
    }

    .carousel-image {
        max-height: 360px;
    }

    .news-item-image-container img {
        max-height: 360px;
    }

    .carousel-arrow {
        width: 32px;
        height: 32px;
    }

    .carousel-arrow svg {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    .carousel-image {
        max-height: 250px;
    }

    .news-item-image-container img {
        max-height: 250px;
    }
}
