body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --primary-color: rgb(63, 05, 05);
    --complementary-color: #053f3f;
    --unavailable-color: #6e6e6e;
    --primary-gray: #f0f0f0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    transition: background-color 0.3s ease-in-out;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: var(--primary-color);
}
