.orders-container {
    display: flex;
    flex-direction: column; /* Stack children vertically */
    align-items: center; /* Center items horizontally */
    padding: 20px;
    background-color: #f4f4f4;
    font-family: var(--font-family) !important;
    font-weight: bold;
}

.orders-title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px; /* Add some space below the title */
}

.content-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
}

.order {
    border: 1px solid #e0e0e0; /* Border for the order block */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    background-color: #fff; /* White background for each order */
    margin-bottom: 20px; /* Space between order blocks */
    padding: 20px; /* Space inside each order block */
    cursor: pointer; /* Indicate that the order is clickable */
}

.orders-list {
    width: 60% !important; /* Adjust as per your requirements. Increased from 600px to 800px for wider blocks. */
    margin-right: 20px; /* Space between the order list and order details */
}

.reduced-width {
    width: 30% !important;
}

.order-header {
    display: flex;
    justify-content: space-between; /* Distribute date, price, and order number evenly */
    margin-bottom: 10px; /* Space between the header and items */
}

.order-items {
    display: flex;
    gap: 10px; /* Space between items */
}

.item-thumbnail {
    width: 60px; /* Adjust as per your requirements */
    height: 60px;
    object-fit: cover;
    border-radius: 4px; /* Slight rounding on the item images */
}

/* You can also add styles for the order-details to make it look similar to the orders list */
.order-details {
    position: relative; /* Added this */
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    padding: 20px;
    min-width: 50rem;
}

.details-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.top-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.details-number,
.details-date {
    flex: 0 0 auto;
}

.details-items {
    display: flex;
    gap: 10px;
}

.details-number {
    margin-bottom: 10px;
}

.item-full {
    width: 100px; /* Adjust as per your requirements */
    height: 100px;
    object-fit: cover;
    border-radius: 4;
}

.order-actions {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 10px;
    left: 10px;
    right: 10px; /* this ensures the container spans the entire width minus some padding */
}

.order-actions button {
    color: white;
    background-color: var(--primary-color) !important;
    font-family: var(--font-family) !important;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

/** Listing Requests **/
.requests-list {
    width: 60% !important;
    margin-right: 20px;
}

.request {
    position: relative;
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin-bottom: 20px;
    padding: 20px;
}

.request > div {
    margin-bottom: 5px;
    font-weight: 600;
}

.request-badges {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    gap: 10px;
    right: 0;
    top: 0;
    margin: 20px;
}

.request-badge-type,
.request-badge-status,
.request-badge-view > button {
    display: flex;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    gap: 5px;
    font-weight: 500;
}

.request-badge-view > button {
    background-color: #d4d4d4;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
}

.request-badge-type {
    background-color: #60d0fe;
}

.request-listing {
    font-size: 20px;
    font-weight: 700 !important;
}

.request-slots {
    font-size: 16px;
    font-weight: normal;
    margin-top: 10px;
}

.request-date {
    font-size: 16px;
    font-weight: normal;
}

.request-action-button {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 20px !important;
    display: flex;
    align-items: center;
    gap: 10px;
}

.request-action-button > button {
    padding: 10px 15px;
    font-size: 18px;
    border-radius: 5px;
    border: 2px solid var(--primary-color);
    background-color: white;
    color: var(--primary-color);
    font-weight: 700;
}

.request-action-button > button:not(:last-child) {
    background-color: white;
    color: var(--primary-color);
}

.request-action-button > button:last-child:not(:first-child) {
    background-color: var(--primary-color);
    color: white;
}
