.lab-manager-finances-container {
    margin: 24px auto;
    padding: 0 20px;
}

.lab-manager-finances-tabs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1px;
    width: 100%;
    background-color: #f1f1f1;
    border-radius: 8px;
    padding: 4px;
    margin-bottom: 15px;
}

.lab-manager-finances-tab {
    padding: 8px 16px;
    border: none;
    background: none;
    cursor: pointer;
    font-size: 14px;
    border-radius: 6px;
    transition: all 0.2s ease;
}

.lab-manager-finances-tab.active {
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.lab-manager-finances-card {
    background: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.lab-manager-finances-card-header {
    padding: 24px 24px 0;
}

.lab-manager-finances-card-header h2 {
    margin: 0 0 8px;
    font-size: 24px;
    font-weight: 600;
}

.lab-manager-finances-description {
    color: #666;
    font-size: 14px;
    margin: 0;
    line-height: 1.5;
}

.lab-manager-finances-card-content {
    padding: 24px;
}

.lab-manager-finances-radio-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
}

.lab-manager-finances-card-item {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
}

.lab-manager-finances-card-item:hover {
    background-color: #f8f8f8;
}

.lab-manager-finances-card-item.selected {
    background-color: #f8f8f8;
    border-color: #ddd;
}

.lab-manager-finances-card-item input[type="radio"] {
    margin-right: 16px;
}

.lab-manager-finances-card-details {
    flex: 1;
}

.lab-manager-finances-card-main {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 4px;
}

.lab-manager-finances-card-icon {
    color: #666;
}

.lab-manager-finances-card-number {
    font-weight: 500;
}

.lab-manager-finances-card-info {
    color: #666;
    font-size: 14px;
}

.lab-manager-finances-default-badge {
    background-color: #e8f5e9;
    color: #2e7d32;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    font-weight: 500;
}

.lab-manager-finances-edit-button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 8px;
    border-radius: 4px;
    transition: all 0.2s ease;
}

.lab-manager-finances-edit-button:hover {
    background-color: #f1f1f1;
}

.lab-manager-finances-add-card-button {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    border: 1px solid #e0e0e0;
    background: none;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease;
}

.lab-manager-finances-add-card-button:hover {
    background-color: #f8f8f8;
}

.lab-manager-finances-no-data {
    color: #666;
    font-size: 14px;
    margin: 0;
}

@media (max-width: 640px) {
    .lab-manager-finances-tabs {
        width: 100%;
    }

    .lab-manager-finances-card-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
    }

    .lab-manager-finances-edit-button {
        align-self: flex-end;
    }
}

/**
 * Form styles
 */
.lab-manager-finances-form-header {
    font-size: 18px;
    font-weight: 600;
    padding: 0 0 12px;
    border-bottom: 1px solid #ddd;
    margin: 10px 0 0;
}

.lab-manager-finances-form-content {
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.lab-manager-finances-form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
}

.lab-manager-finances-form-row {
    display: flex;
    gap: 20px;
}

.lab-manager-finances-form-group input[type="checkbox"] {
    background-color: var(--primary-color);
}

.lab-manager-finances-form-group > label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    display: flex;
    align-items: center;
    gap: 5px;
}

.lab-manager-finances-form-group .required {
    color: #e53935;
}

.lab-manager-finances-form-group > *:not(label) {
    padding: 10px 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 14px;
    transition: all 0.2s ease;
}

.lab-manager-finances-form-group > *:not(label):focus {
    outline: none;
    border-color: #2196f3;
    box-shadow: 0 0 0 2px rgba(33, 150, 243, 0.1);
}

.lab-manager-finances-expiry-inputs {
    display: flex;
    gap: 12px;
}

.lab-manager-finances-expiry-inputs select {
    flex: 1;
}

.lab-manager-finances-button-primary,
.lab-manager-finances-button-secondary {
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
}

.lab-manager-finances-button-primary {
    background-color: #2196f3;
    color: white;
    border: none;
}

.lab-manager-finances-button-primary:hover {
    background-color: #1976d2;
}

.lab-manager-finances-button-secondary {
    background-color: white;
    color: #666;
    border: 1px solid #ddd;
}

.lab-manager-finances-button-secondary:hover {
    background-color: #f5f5f5;
}

.lab-manager-finances-form-group-note {
    font-size: 12px;
    color: #666;
}

@media (max-width: 640px) {
    .lab-manager-finances-form-row {
        flex-direction: column;
        gap: 20px;
    }
}
