.warning-banner {
    position: fixed;
    z-index: 500;
    width: 100%;
    top: 64px;
    padding: 10px;
    background-color: rgb(253 122 122);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    border-bottom: 0.5px solid white;
}

.warning-banner-clickable {
    cursor: pointer;
    color: #0f40b0;
}
