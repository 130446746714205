.email-verification-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    margin-top: 100px;
}
.email-verification-card {
    background-color: rgb(238, 238, 238);
    border-radius: 8px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 32px;
    max-width: 450px;
    width: 100%;
}
.card-title {
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    color: #111827;
    margin-bottom: 24px;
}
.card-content {
    text-align: center;
}
.lock-icon {
    width: 72px;
    height: 72px;
    color: #9ca3af;
    margin: 0 auto 16px;
}
.verification-heading {
    font-size: 20px;
    font-weight: 500;
    color: #111827;
    margin-bottom: 8px;
}
.verification-message {
    font-size: 14px;
    color: #6b7280;
    margin-bottom: 32px;
}
.illustration-container {
    display: flex;
    justify-content: center;
}
.verification-illustration {
    max-width: 100%;
    height: auto;
}
