.publications-manager-editor {
    padding: 20px;
    /* Add to ensure full height */
    min-height: 100vh;
}

.publications-manager-list {
    display: flex;
    flex-direction: column;
    /* Add to ensure full height */
    height: 100%;
}

.publication-manager-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative; /* For absolute positioning of edit button */
    gap: 10px; /* Add spacing between elements */
    margin-bottom: 15px;
    /* Add to ensure content can expand */
    height: auto;
    min-height: fit-content;
    border-bottom: 1px solid #d0d0d0; /* Add this line to create separation */
}

.publication-manager-item:last-child {
    border-bottom: none;
}

.publication-manager-item > h2 {
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 0;
}

.publication-manager-item > h4 {
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    margin-bottom: 5px;
}

.publication-manager-item > h4:not(:first-child) {
    margin: 5px 0;
}

.publication-manager-item-editor-input {
    margin-bottom: 10px;
}

/* Add these new styles for image preview */
.publication-manager-item-editor-input .image-preview {
    margin-top: 10px;
    max-width: 200px;
    max-height: 200px;
    overflow: hidden;
    border-radius: 5px;
    /* Add these properties to center the preview */
    margin-left: auto;
    margin-right: auto;
}

.publication-manager-item-editor-input .image-preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.publications-manager-header-buttons {
    display: flex;
    gap: 10px;
}

.publication-manager-item-editor > div > input,
.publication-manager-item-editor > div > textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    font-size: 14px;
}

.publication-manager-item-editor > div > textarea {
    height: 100px; /* Changed from 200px to 100px */
}

.publication-manager-item-editor > div > h2 {
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 20px;
}

.publication-manager-item-editor > div > h4 {
    letter-spacing: 0.5px;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    margin-bottom: 5px;
}

.publication-manager-item-editor > div > h4:not(:first-child) {
    margin: 5px 0;
}

/* Add styles for the image container */
.publication-image {
    width: 100%;
    overflow: hidden; /* Changed back to hidden to prevent overflow */
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: auto;
    min-height: fit-content;
}

.publication-image img {
    width: 100%; /* Changed from width: auto */
    height: auto;
    max-height: 360px;
    object-fit: contain; /* Added to maintain aspect ratio */
}

/* Update the publication description styles */
.publication-description {
    font-size: 14px;
    line-height: 1.6;
    color: #333;
    margin: 10px 0;
    padding: 0;
    white-space: pre-wrap; /* Preserves line breaks and spaces */
    overflow-wrap: break-word; /* Ensures long words don't overflow */
    max-width: 100%; /* Ensures text doesn't overflow container */
    display: block; /* Ensures the description takes full width */
}

.publication-meta {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 0;
}

/* Add new styles for the links row */
.publication-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex-wrap: wrap; /* Add this to allow wrapping on small screens */
    gap: 10px; /* Add spacing between wrapped items */
}

/* Style both links consistently */
.publication-links a {
    text-decoration: none;
    color: var(--primary-color);
    font-size: 14px;
}

.publicationmanager-journal {
    font-size: 1em;
    color: #666;
    margin-bottom: 15px; /* adjust as needed for space below the journal name */
    font-style: italic;
    text-decoration: underline;
    display: inline;
}

.publications-manager-editor > div > h3 {
    margin: 0;
    padding-bottom: 10px;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0.5px;
    color: #454545;
    display: flex;
    flex: 1;
    border-bottom: 1px solid #d0d0d0;
}

.publications-manager-editor-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.publications-manager-editor-left-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-wrap: wrap; /* Add this to allow wrapping */
}

.publications-manager-editor-left-buttons > a {
    padding: 8px 12px; /* Slightly reduce padding */
    text-align: center;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    white-space: nowrap; /* Prevent text from wrapping inside button */
}

.publications-manager-editor-left-buttons > a:first-child {
    background-color: #ffd700;
    color: #555;
}

.publications-manager-editor-left-buttons > a:last-child {
    background-color: #007bff;
    color: white;
}

.publications-manager-editor-right-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
}

.publications-manager-editor-right-buttons > button {
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    border: none;
    padding: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
}

.publications-manager-editor-right-buttons > select {
    border-radius: 5px;
    border: 1px solid #d0d0d0;
    height: 30px;
}

.publications-manager-editor-save-button {
    display: flex;
    gap: 10px;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    width: 100%;
}

.publications-manager-editor-save-button > button {
    margin-top: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-flex;
    color: var(--primary-color);
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    outline: none;
    height: 40px;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.my-publications-item-journal {
    margin-top: 0;
    font-size: 1em;
    color: #666;
    margin-bottom: 15px; /* adjust as needed for space below the journal name */
    font-style: italic;
    text-decoration: underline;
    display: inline;
}

.publications-manager-orcid-connect-field {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 10px;
}

.publications-manager-orcid-connect-field > h4 {
    margin: 5px 0;
}

.publication-creator {
    font-size: 14px;
    color: #666;
    margin-top: 5px;
    font-style: italic;
}

/* Add mobile slide-up styles */
@media screen and (max-width: 768px) {
    .publication-editor-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
    }

    .publication-editor-overlay.active {
        display: block;
        opacity: 1;
        pointer-events: auto;
    }

    .publication-editor-slideup {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: 85vh;
        background-color: white;
        z-index: 1000;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        border-radius: 20px 20px 0 0;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }

    .publication-editor-slideup.active {
        transform: translateY(0);
    }

    .publication-editor-card {
        position: relative;
        height: 100%;
        padding: 20px;
        overflow-y: auto;
        padding-bottom: 80px; /* Space for footer */
    }

    .publication-editor-close-button {
        position: absolute;
        top: 15px;
        right: 20px;
        width: 30px;
        height: 30px;
        background-color: #f0f0f0;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        z-index: 1002;
        color: #454545;
        font-size: 30px;
        border-radius: 50%;
    }

    .publication-editor-close-button::before {
        content: "";
        position: absolute;
        top: -8px;
        right: -8px;
        bottom: -8px;
        left: -8px;
        background: white;
        border-radius: 50%;
        z-index: -1;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .publication-editor-footer {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 15px;
        background: white;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1001;
        display: flex;
        justify-content: flex-end;
    }

    .publication-editor-footer button {
        background-color: var(--primary-color);
        color: white;
        border: none;
        padding: 12px 24px;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }

    .publication-editor-card h2 {
        margin: 0 0 20px 0;
        font-size: 24px;
        padding-right: 40px;
    }

    .publication-links {
        flex-direction: column; /* Stack items vertically on mobile */
        align-items: flex-start;
    }

    .publication-doi {
        word-break: break-all; /* Allow DOI to break across lines */
    }

    .publications-manager-editor-left-buttons {
        width: 100%; /* Take full width */
    }

    .publications-manager-editor-left-buttons > a {
        width: 100%; /* Make button take full width */
        justify-content: center; /* Center the text */
    }
}

/* Add new styles for the publication image upload */
.publication-image-upload-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.publication-image-upload-container input[type="file"] {
    display: none;
}

.publication-image-preview-container {
    width: 100%;
    max-width: 300px;
    aspect-ratio: 16/9;
    border: 2px dashed #d0d0d0;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    transition: border-color 0.3s;
}

.publication-image-preview-container:hover {
    border-color: var(--primary-color);
}

.publication-image-preview {
    width: 100%;
    height: 100%;
    position: relative;
}

.publication-image-preview img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #f5f5f5;
}

.publication-image-delete {
    position: absolute;
    top: 8px;
    right: 8px;
    width: 30px;
    height: 30px;
    padding: 5px;
    background-color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s, color 0.3s;
}

.publication-image-delete:hover {
    background-color: var(--primary-color);
    color: white;
}

.publication-image-placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
}

.publication-image-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: #666;
    font-size: 14px;
}

.publication-image-instructions .paste-image-button {
    padding: 5px 10px;
    font-size: 12px;
    color: var(--primary-color);
    background-color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.publication-image-instructions .paste-image-button:hover {
    background-color: var(--primary-color);
    color: white;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .publication-image-preview-container {
        max-width: 100%;
    }

    .publication-image-instructions {
        font-size: 12px;
    }

    .publication-image-instructions .paste-image-button {
        font-size: 11px;
        padding: 4px 8px;
    }
}
