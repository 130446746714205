:root {
    --font-family: "poppins", Helvetica, Arial, sans-serif;
}

button {
    cursor: pointer;
}

.control-label {
    font-family: var(--font-family) !important;
}

.container-create-listing {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.container-create-listing > form {
    width: 100%;
}

.title {
    font-size: 2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
    font-family: var(--font-family) !important;
}

.label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.label-container {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.input {
    padding: 0.5rem;
    font-size: 18px;
    border: 2px solid #ccc;
    border-radius: 0.5rem;
    width: 100%;
    font-family: var(--font-family) !important;
}

.input-error {
    border: 2px solid red !important;
}

.duration-input {
    font-size: 1.2rem;
    width: 100%;
    font-family: var(--font-family) !important;
}

.button-container-listing {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.button-listing {
    background-color: var(--primary-color) !important;
    color: white !important;
    padding: 1rem;
    margin-bottom: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font-family) !important;
    text-transform: none !important;
    border: none !important;
}

.full-width-button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: var(--primary-color) !important;
    color: white !important;
    text-transform: none !important;
    font-family: var(--font-family) !important;
}

.delete-custom-spec-button {
    background-color: var(--primary-color) !important;
    color: white !important;
    padding: 1rem;
    margin: 1rem;
    margin-bottom: 1rem !important;
    border-radius: 0.5rem;
    cursor: pointer;
    font-family: var(--font-family) !important;
    text-transform: none !important;
}

.progress-bar {
    width: 100%;
    height: 0.5rem;
    background-color: #ddd;
    margin-top: 2rem;
}

.progress {
    height: 100%;
    background-color: var(--primary-color);
}

@media only screen and (max-width: 986px) {
    .form-section {
        max-width: none;
    }

    .button-container {
        flex-direction: column;
        align-items: center;
    }

    .button {
        margin-top: 1rem;
        margin-right: 0;
    }
}

.container-categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0;
}

.container-pricing {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.container-rental-rates {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 2rem;
}

.container-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.title-categories {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 2rem;
    color: black;
    font-family: var(--font-family) !important;
}

.categories {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.category-container {
    height: auto;
    aspect-ratio: 1;
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    transition: transform 0.3s ease-in-out;
    padding: 10px;
    border-radius: 5px;
}

.category-container:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
    cursor: pointer;
}

.category-container > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.category {
    color: var(--primary-color);
    padding: 1rem;
    font-weight: 700;
    cursor: pointer;
    font-family: var(--font-family);
    letter-spacing: 0.5px;
}

@media screen and (max-width: 1024px) {
    .categories {
        grid-template-columns: repeat(2, 1fr);
        max-width: 600px;
    }
}

@media screen and (max-width: 768px) {
    .categories {
        grid-template-columns: 1fr;
        max-width: 300px;
    }

    .category-container {
        max-width: 100%;
    }

    .category {
        font-size: 1rem;
    }
}

.content-area-categories {
    margin: auto !important;
    margin-top: 10% !important;
}

input[type="radio"] {
    opacity: 0;
    position: absolute;
}

.shipping-available {
    display: flex;
    align-items: center;
}

input[type="number"] {
    font-family: var(--font-family);
    padding: 8px !important;
}

.form-section h3 {
    font-weight: 500;
    margin: 0;
}

.form-section h4 {
    color: #666;
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 10px;
}
.add-unavailability-button-container {
    margin-bottom: 20px;
}

.add-unavailability-button-container button {
    margin: 0 auto;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
    text-align: left;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.add-unavailability-text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 16px !important;
    font-weight: 500;
}

.checkbox-container {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 1rem;
}

.checkbox-container label {
    margin-left: 8px;
    font-family: var(--font-family) !important;
}

.unit-section {
    display: flex;

    position: relative;
    z-index: 10;
    flex-direction: column;
}

.unit-input-container {
    display: flex;
}

.currency {
    font-size: 16px;
    margin-right: 5px;
}

.price-input-container input {
    min-width: 150px;
    max-width: 150px;
    text-align: right;
    font-size: 16px;
    padding-right: 10px;
    border: 1px solid lightgrey;
    border-radius: 3px;
}

.price-input-container {
    display: inline-flex;
    align-items: center;
}

.per-text {
    font-size: 1.2rem;
    margin: 0 0.5rem;
}

.unit-select {
    position: relative;
    z-index: 11;
    min-width: 100px;
    max-width: 200px;
}

.react-select__menu-list {
    max-height: 20rem;
    overflow-y: auto;
}

.react-select__option {
    font-size: 1.5rem;
}

.custom-unit-input {
    font-size: 16px;
    max-width: 200px;
    min-width: 100px;
    margin-left: 5px;
}

/*image */
.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.image-upload-label {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    cursor: pointer;
}

.image-upload-icon {
    width: 2.5rem;
    height: 2.5rem;
    fill: var(--primary-color);
}

.image-upload-input {
    display: none;
}

.image-preview {
    max-width: 400px;
    max-height: 400px;
    margin-bottom: 1rem;
}

.image-change-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    color: var(--primary-color) !important;
}

.image-change-icon {
    width: 2.5rem;
    height: 2.5rem;
    fill: var(--primary-color) !important;
}

.image-change-button:hover .image-change-icon {
    fill: var(--primary-color) !important;
}

/* Schedule*/
/* ScheduleTable.css */
.schedule-table-container {
    position: relative;
    width: 100%;
}

.repeat-weekly {
    position: relative;
    padding: 10px;
    text-align: right;
    margin-top: 5px;
}

.repeat-weekly input[type="checkbox"] {
    margin-right: 5px;
}

.rbc-event-label {
    font-size: 11px !important; /* Adjust the value to your desired font size */
    margin-bottom: 0.5rem;
}

.action-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

.clear-all {
    background-color: var(--primary-color) !important;
    color: white !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    margin: 1rem;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: transform 0.2s ease-in-out !important;
    font-family: var(--font-family) !important;
    text-transform: none !important;
}

.labmember-select {
    position: relative;
    z-index: 17 !important;
    font-size: 18px;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-family: var(--font-family) !important;
}

.field-error-message {
    color: red;
    margin: 5px 0 0;
    font-size: 12px;
}

.locations {
    padding: 12px;
}

.pricing-section {
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
}

.price-input {
    width: 150px;
    text-align: right;
    padding-right: 10px;
}

.pricing-section > .unit-section {
    flex: 3 !important;
}

.pricing-section > per-text {
    flex: 1 !important;
}

textarea {
    resize: none;
    height: 200px;
    font-size: 12px;
}

.booked-slot {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 5px;
    gap: 10px;
}

.booked-slot > div {
    gap: 5px;
}

.booked-slot-type {
    display: flex;
    align-items: center;
    margin-right: 5px;
}

.booked-slot-day-select {
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 0.5rem;
    font-family: var(--font-family) !important;
    padding: 0;
}

.booked-slot > div > input {
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    border: 1.5px solid var(--primary-color);
}

.booked-slot > button {
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    border: 2px solid var(--primary-color);
}

.add-availability-label {
    margin-bottom: 20px;
}

.add-availability-container {
    margin-top: 15px;
    padding: 20px;
}

.add-availability-container > div {
    margin-bottom: 30px;
}

.booked-slot > button:hover {
    background-color: var(--primary-color);
    color: white;
}

.add-timeslot-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    color: white;
    font-weight: 500;
    padding: 5px 20px;
    border: none;
    background-color: var(--primary-color);
}
.booked-slot > button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    font-weight: 500;
    height: 32.4px;
    width: 32.4px;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
}

.booked-slot > button:hover {
    background-color: var(--primary-color);
    color: white;
}

.add-availability-confirm-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
}

.add-availability-confirm {
    display: flex;
    justify-content: center;
    text-align: center;
    background-color: var(--primary-color);
    color: white;
    border-radius: 5px;
    padding: 10px 20px;
    font-weight: 500;
}

.add-time-button {
    margin-top: 20px;
    justify-content: center;
    text-align: center;
}

.center {
    justify-content: center;
}

.time-input {
    padding: 5px;
    border-radius: 5px;
}

/* Add/modify these responsive styles */
.create-listing-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.create-listing-content {
    width: 100%;
}

.create-listing-header {
    font-size: 2rem;
    text-align: center;
    margin: 2rem 0;
}

.create-listing-section {
    margin-bottom: 3rem;
}

.create-listing-field {
    margin-bottom: 2rem;
}

.image-input-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
}

.image-upload-label {
    width: 100%;
    aspect-ratio: 1;
    max-width: 400px;
    margin: 0 auto;
}

.image-upload-square {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    border: 2px dashed #ccc;
}

.price-quantity-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

.price-per-text {
    margin: 0 10px;
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
    .create-listing-header {
        font-size: 1.5rem;
    }

    .create-listing-section h2 {
        font-size: 1.2rem;
    }

    .create-listing-field h3 {
        font-size: 1rem;
    }

    .create-listing-field h4 {
        font-size: 0.9rem;
    }

    .input,
    textarea {
        font-size: 16px; /* Prevents zoom on mobile */
    }

    .price-quantity-container {
        flex-direction: column;
        align-items: stretch;
    }

    .price-per-text {
        text-align: center;
        margin: 5px 0;
    }

    /* Adjust category selection grid */
    .categories {
        grid-template-columns: 1fr;
    }

    .category-container {
        width: 100%;
        max-width: 300px;
        height: 300px;
        margin: 10px auto;
    }

    /* Adjust radio buttons and checkboxes for better touch targets */
    .delivery-option,
    .checkbox-label {
        margin-bottom: 15px !important;
    }

    input[type="radio"],
    input[type="checkbox"] {
        width: 24px;
        height: 24px;
    }

    /* Make buttons more touch-friendly */
    .button-listing {
        min-height: 44px;
        padding: 12px 20px;
        width: 100%;
        margin: 10px 0;
    }

    .button-container-listing {
        padding: 0 20px;
        flex-direction: column;
        gap: 10px;
    }

    .button-container-listing button {
        width: 100%;
    }
}

/* Tablet-specific styles */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .create-listing-container {
        padding: 0 40px;
    }

    .categories {
        grid-template-columns: repeat(2, 1fr);
    }

    .category-container {
        height: 350px;
    }
}

/* Adjust Select components for better mobile experience */
.labmember-select,
.licensing-select {
    font-size: 16px !important;
}

.react-select__control {
    min-height: 44px !important;
}

.react-select__option {
    padding: 12px !important;
}

/* Make form sections more compact on mobile */
@media screen and (max-width: 768px) {
    .create-listing-section {
        margin-bottom: 2rem;
    }

    .create-listing-field {
        margin-bottom: 1.5rem;
    }

    /* Adjust spacing for radio groups */
    .RadioGroup {
        flex-direction: column !important;
    }

    .RadioGroup .MuiFormControlLabel-root {
        margin-left: 0;
        margin-right: 0;
    }
}
