body {
    font-family: var(--font-family) !important;
}
.listing-detail {
    display: flex;
    margin: auto auto 40px;
    height: auto;
    padding: 50px 5% 0 !important;
    justify-content: center;
    gap: 30px;
    max-width: 1600px;
}

.listing-card {
    position: sticky;
    top: 100px;
    bottom: 40px;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background-color: white;
    color: var(--primary-color);
    width: 50%;
    min-width: 450px;
    max-width: 700px;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    overflow: auto;
    align-self: flex-start;
    border-radius: 10px;
    cursor: default;
}

.listing-card-qty {
    display: inline-flex;
    align-items: center;
    width: 95% !important;
    justify-content: space-between;
    border: 3px solid black;
    border-radius: 5px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.listing-card-qty div {
    text-align: center;
    font-weight: 700;
}

.listing-card-qty-add,
.listing-card-qty-subtract {
    color: var(--primary-color);
    font-size: 16px;
    border: none;
    cursor: pointer;
    padding: 15px;
    width: 63px;
}

.listing-card-qty-add {
    border-radius: 0 5px 5px 0;
    border-left: 3px solid black;
}

.listing-card-qty-subtract {
    border-radius: 5px 0 0 5px;
    border-right: 3px solid black;
}

.listing-card-qty-input {
    flex: 1 !important;
    font-size: 16px;
    font-weight: bold;
}

.listing-placeholder-image {
    width: 100%;
    margin-bottom: 10px;
    object-fit: contain; /* Changed from 'cover' to 'contain' */
    background-color: #f0f0f0; /* Added a light background color */
}

.listing-card-send-message:disabled {
    background-color: #ccc !important;
    color: white !important;
    cursor: not-allowed;
}

.listing-card-image-carousel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.listing-card-image-carousel-buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 !important;
    margin: 0;
}

.listing-card-image-carousel button {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.listing-title {
    font-size: 32px !important;
    margin: 10px 0 15px 0;
}

.listing-card-price {
    padding-left: 15px;
    color: black;
    font-weight: bold;
    font-size: 20px;
    flex: 1;
    width: 100%;
    text-align: left;
    display: inline-flex;
    align-items: center;
    gap: 5px;
    margin: 10px 0 20px 0;
}

.listing-card-unit {
    font-size: 16px;
    color: rgb(108, 105, 105);
}
.listing-card-available-qty {
    flex: 1;
    text-align: right;
    font-weight: 700;
    font-size: 18px;
}

.listing-info {
    padding: 0 20px;
    overflow-y: auto;
    height: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    box-sizing: border-box;
    min-width: 450px;
    max-width: 800px;
    flex: 1;
}

@media screen and (max-width: 1200px) {
    .listing-detail {
        padding: 50px 20px 0 !important;
    }

    .listing-info {
        min-width: 400px;
    }

    .listing-card {
        width: 45%;
    }
}

@media screen and (max-width: 1100px) {
    .listing-info {
        min-width: 350px;
    }

    .listing-title {
        font-size: 26px !important;
    }

    .listing-badges div {
        padding: 5px;
        margin-right: 10px;
        font-size: 13px;
    }
}

@media screen and (max-width: 1000px) {
    .listing-detail {
        flex-direction: column;
        align-items: center;
        padding: 20px !important;
    }

    .listing-card {
        position: static;
        width: 100%;
        min-width: unset;
        max-width: 100%;
        margin: -100px auto;
        order: 2;
        padding: 0;
        box-sizing: border-box;
        box-shadow: none;
        background-color: transparent;
        border-radius: 0;
    }

    .listing-card-description {
        padding: 15px 0;
        border-top: none;
    }

    .listing-card-image {
        margin-bottom: 20px;
    }

    .listing-card-price {
        border-bottom: 1px solid #eee;
        padding: 15px 0;
        margin: 0;
    }

    .listing-card-send-message,
    .listing-card-add-to-cart,
    .listing-action-button,
    .listing-card-qty {
        width: 95% !important;
        margin: 10px 0;
        box-sizing: border-box;
    }

    .listing-card-qty {
        margin: 10px auto;
    }

    .listing-info {
        width: 100%;
        min-width: unset;
        max-width: 100%;
        margin: 0;
        padding: 0;
    }

    .listing-info-mobile {
        order: 3;
    }

    .listing-title {
        font-size: 24px !important;
    }

    .listing-badges {
        margin: 10px 0 10px 0;
        flex-wrap: wrap;
        gap: 5px;
        justify-content: flex-start;
    }

    .listing-badges div {
        margin-right: 5px;
    }

    .listing-card-image img {
        height: 250px !important;
    }

    /* Reduce paragraph spacing in mobile view */
    .listing-card-description p {
        margin-bottom: -50px; /* Reduced from default */
    }

    .listing-card-description p :last-child {
        margin-bottom: 30px !important; /* Reduced from 10px to 5px */
    }

    .listing-card-description {
        display: block;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
        padding: 15px;
    }

    .listing-card-description .listing-metadata {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin: 0;
        padding-top: 30px !important;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .listing-card-description .listing-metadata-created,
    .listing-card-description .listing-metadata-location {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #666;
        margin: 0; /* Remove bottom margin since we're using gap */
    }

    .listing-card-description h3 {
        margin: 20px 0 10px 0;
        font-size: 18px;
    }

    .listing-card-description p {
        font-size: 16px;
        margin-bottom: 5px;
    }

    /* Add specific styling for lab member name */
    .listing-card-description h3 + p {
        /* Targets the paragraph immediately after h3 */
        font-size: 20px;
        font-weight: 500;
    }

    .listing-specifications-mobile {
        order: 3;
        width: 100%;
        margin-top: 20px;
        padding: 20px;
        border-radius: 10px;
    }

    .listing-card-description {
        display: block;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
        padding: 15px;
        border-top: 1px solid #eee;
    }

    /* Hide the original description in mobile view */
    .listing-info-mobile .listing-description {
        display: none;
    }

    .listing-card {
        margin-bottom: 20px; /* Add space between card and calendar */
    }

    .listing-specifications-title {
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: 600;
    }
}

.listing-info::-webkit-scrollbar {
    display: none;
}

.listing-info h3 {
    margin: 0;
    margin-bottom: 10px;
    font-size: 20px;
}

.listing-info p,
.listing-info a {
    margin: 0;
    margin-bottom: 10px;
    text-align: justify;
    font-size: 16px;
    color: #666666;
    text-decoration: none;
}

.listing-subpath-lab-link {
    color: #18181b;
}

@media screen and (min-width: 1001px) {
    .listing-title {
        font-size: 30px;
    }

    .listing-pic {
        width: 150px;
        height: 150px;
    }

    .listing-card {
        width: 450px;
    }

    .listing-info {
        margin: 0;
    }

    .listing-description {
        display: block;
    }
}

@media screen and (max-width: 1000px) {
    .listing-card {
        position: relative;
        width: 100%;
        height: auto;
    }

    .listing-info {
        margin-left: 0;
        padding-top: 0;
    }

    .listing-info-mobile {
        order: 3;
        width: 100%;
    }

    .listing-description {
        display: block;
    }
}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
}

.listing-card-confirm-booking,
.listing-card-add-to-cart,
.listing-action-button {
    background-color: var(--primary-color);
    color: white;
    font-family: var(--font-family) !important;
    border: none;
    border-radius: 5px;
    font-weight: 700;
    cursor: pointer;
    padding: 16px 0;
    font-size: 16px !important;
    flex: 1;
    width: 95% !important;
    font-weight: bold;
    justify-content: center;
    margin: 0 auto 10px auto;
}

.listing-card-send-message {
    background-color: #18181b;
}

.listing-card-booking-details {
    font-weight: 500;
    padding-inline-start: 0;
}

.listing-card-booking-details li:not(:last-child) {
    margin-bottom: 10px;
}

.listing-card-request-booking {
    background-color: white !important;
    color: var(--primary-color);
    border: 3px solid var(--primary-color);
}

.listing-card-confirm-booking:disabled {
    background-color: #ccc !important;
    color: white !important;
    cursor: not-allowed;
}

.checkbox-label .MuiFormControlLabel-label {
    font-family: var(--font-family) !important;
}

.checkbox-label .MuiCheckbox-root {
    color: var(--primary-color);
}

.checkbox-label .MuiCheckbox-root.Mui-checked {
    color: var(--primary-color);
}

.quantity-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.quantity-button {
    padding: 6px 10px;
    background-color: #ddd;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-right: 5px;
}

.quantity-field {
    display: flex;
    flex-direction: column;
}

.quantity-input {
    width: 60px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    text-align: center;
    margin-right: 5px;
}

.quantity-unit-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.confirm-buy-button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
}

.shipping-address {
    font-family: var(--font-family) !important;
}

#shipping-address-field {
    margin-top: 1em;
}

.seller-specification {
    font-family: var(--font-family) !important;
}

.custom-textfield .MuiOutlinedInput-notchedOutline {
    border: none !important;
}

.unit-price {
    flex-grow: 1;
    text-align: center;
}
.total-price {
    text-align: center;
}

.schedule-table-container {
    position: relative;
    width: 100%;
}

.calendar-container {
    margin-bottom: 20px;
}

.custom-textfield input {
    padding: 0.5rem;
    font-size: 18px;
    border: 2px solid #ccc;
    border-radius: 0.5rem;
    width: 100%;
    font-family: var(--font-family) !important;
    color: black;
}

.custom-chip {
    font-family: var(--font-family) !important;
    font-size: 16px !important;
}

.example-options-label {
    visibility: hidden !important;
}

.autocomplete-input .Mui-focused fieldset {
    border-color: var(--primary-color) !important;
}

/** New Listing CSS */

.listing-main-info {
    color: black;
}

.listing-subpath {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.listing-subpath a {
    margin: 0;
    text-decoration: none;
    color: #18181b;
}

.listing-badges {
    margin: 10px 0 30px 0;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
}

.listing-badges div {
    display: inline-flex;
    align-items: center;
    border-radius: 5px;
    padding: 7px;
    margin-right: 15px;
    gap: 5px;
    font-size: 14px;
}

.listing-badge-type {
    background-color: #60d0fe;
}

.listing-badge-lab {
    background-color: #ffd700;
}

.listing-badge-lab:hover {
    cursor: pointer;
}

.listing-badge-collaborative {
    background-color: #e2e2e2;
}

.listing-specifications {
    margin-top: 60px;
    border-top: 2px solid #ccc;
}

.listing-specifications-title {
    font-size: 24px;
    margin: 20px 0 20px;
    color: black;
    font-weight: 600;
}

.listing-specification-values {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

.listing-specification-value {
    border: 2px solid black;
    padding: 5px;
}

.listing-metadata {
    margin: 10px 0 30px 0;
    font-size: 14px;
}

.listing-metadata div {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    padding: 0 20px 0 0;
}

.listing-metadata-message-text:hover {
    cursor: pointer;
    text-decoration: underline;
}

.listing-card-image {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.listing-card-image button {
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.listing-card-image button:hover {
    background-color: var(--primary-color);
    color: white;
}

.listing-card-image button:first-child {
    position: absolute;
    left: 10px;
}

.listing-card-image button:last-child {
    position: absolute;
    right: 10px;
}

.listing-card-shipping-options {
    padding: 10px 15px 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    color: black;
    font-size: 14px;
    font-weight: bold;
    flex: 1;
    width: 100%;
    text-align: left;
}

.listing-card-shipping-options-title {
    margin-bottom: 10px;
}

.listing-card-add-to-cart:disabled {
    background-color: #ccc !important;
    color: white !important;
    cursor: not-allowed;
}

.listing-card-confirm-booking-qty {
    color: #ccc;
}

/** Custom Specifications */

.custom-specifications {
    margin: 10px 0;
}

.variations {
    padding: 20px;
}

.variations .variation-component {
    position: relative;
    background-color: #eeeeee;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
}

.variation-component > h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
}

.variation-option {
    display: flex;
}

.variation-option-input {
    display: inline-flex;
    width: 100%;
    gap: 10px;
}

.variation-name-input {
    margin-bottom: 20px;
}

.variation-option-input input {
    flex: 1;
}

.variation-option-input button {
    flex: none;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: white;
    background-color: var(--primary-color);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    font-family: var(--font-family);
    white-space: nowrap;
}
.variation-options {
    margin-top: 20px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.add-variation-button {
    margin: 0 auto;
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    text-align: left;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.confirm-variations-button {
    display: block;
    margin: 20px auto 0;
    padding: 10px 15px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 700;
}

.variation-variants {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    margin: 10px 0;
    font-weight: 700;
}

.delete-variation-button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    padding: 10px;
}

.combined-variants {
    background-color: whitesmoke;
    padding: 20px;
    border-radius: 5px;
    margin-top: 20px;
    padding: 20px;
}

.combined-variants > h3 {
    padding: 0;
    margin: 0;
    font-weight: 600;
}
.combined-variants > h4 {
    padding: 0;
    margin: 0;
    font-weight: 500;
}

.variations-table {
    width: 100%;
    table-layout: fixed;
    margin-top: 10px;
    padding: 20px;
    justify-content: center;
}

.variations-table > thead > tr,
.variations-table > tbody > tr {
    text-align: center;
}

.variations-table > thead > tr > th {
    flex: 1;
    font-weight: 600;
}

.add-variation-text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 16px;
    font-weight: 500;
}

.variation-variants-text {
    font-weight: 500;
}

.variations > h4 {
    font-weight: 500;
    font-size: 14px;
}

.listing-description {
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.listing-card-image img {
    transition: transform 0.5s ease; /* Smooth transition for sliding */
}

.slide-left-enter {
    transform: translateX(-100%);
}

.slide-left-enter-active {
    transform: translateX(0);
}

.slide-left-exit {
    transform: translateX(0);
}

.slide-left-exit-active {
    transform: translateX(100%);
}

.slide-right-enter {
    transform: translateX(100%);
}

.slide-right-enter-active {
    transform: translateX(0);
}

.slide-right-exit {
    transform: translateX(0);
}

.slide-right-exit-active {
    transform: translateX(-100%);
}

/* Add these new styles */
.listing-card-description {
    display: none; /* Hidden by default for desktop */
    padding: 15px;
    border-top: 1px solid #eee;
    margin-top: 10px;
}

@media screen and (max-width: 1000px) {
    /* ... existing mobile styles ... */

    /* Hide the original description in mobile view */
    .listing-info-mobile .listing-description {
        display: none;
    }

    /* Show the description inside the card in mobile view */
    .listing-card-description {
        display: block;
        white-space: pre-wrap;
        word-wrap: break-word;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 20px;
        padding: 15px;
    }

    .listing-card-description > .ReactMarkdown {
        margin-bottom: 30px; /* Increased space after description */
    }

    .listing-card-description .listing-metadata {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin: 0;
        padding-top: 30px;
        flex-wrap: wrap;
    }

    .listing-card-description .listing-metadata-created,
    .listing-card-description .listing-metadata-location {
        display: flex;
        align-items: center;
        gap: 5px;
        color: #666;
        margin: 0; /* Remove bottom margin since we're using gap */
    }
}

@media screen and (min-width: 1001px) {
    /* ... existing desktop styles ... */

    /* Ensure the original description is visible in desktop view */
    .listing-description {
        display: block;
    }

    /* Keep the card description hidden in desktop view */
    .listing-card-description {
        display: none;
    }
}

.listing-card-digital {
    width: 100%;
    padding: 20px;
    border-top: 1px solid #eee;
}

.listing-card-licensing,
.listing-card-retrieval {
    margin-bottom: 20px;
}

.listing-card-licensing h3,
.listing-card-retrieval h3 {
    font-size: 16px;
    margin-bottom: 8px;
    color: #333;
    font-weight: 600;
}

.listing-card-licensing p,
.listing-card-retrieval p {
    font-size: 14px;
    color: #666;
    line-height: 1.6;
    white-space: pre-wrap;
    word-wrap: break-word;
    margin: 0;
}

/* Style links within Access Method */
.listing-card-retrieval a {
    color: var(--primary-color);
    text-decoration: none;
}

.listing-card-retrieval a:hover {
    text-decoration: underline;
}

.listing-card-link {
    margin-bottom: 20px;
}

.listing-card-link h3 {
    font-size: 16px;
    margin-bottom: 8px;
}

.listing-card-link a {
    color: var(--primary-color);
    text-decoration: none;
}

.listing-card-link a:hover {
    text-decoration: underline;
}

.listing-content-link {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.2s ease-in-out;
}

.listing-content-link:hover {
    text-decoration: underline;
    color: var(--primary-color-dark);
}

.listing-card-retrieval p {
    white-space: pre-wrap;
    word-wrap: break-word;
    line-height: 1.6;
}

/* Update cursor only for clickable elements */
.listing-card button,
.listing-card a,
.listing-card-qty-add,
.listing-card-qty-subtract,
.listing-metadata-message-text,
.listing-card-add-to-cart:not(:disabled),
.listing-card-send-message:not(:disabled),
.listing-card-confirm-booking:not(:disabled),
.listing-card-request-booking:not(:disabled) {
    cursor: pointer;
}

/* Set not-allowed cursor for disabled buttons */
.listing-card button:disabled,
.listing-card-add-to-cart:disabled,
.listing-card-send-message:disabled,
.listing-card-confirm-booking:disabled {
    cursor: not-allowed;
}

/* Ensure non-clickable elements have default cursor */
.listing-card-price,
.listing-card-description,
.listing-card-shipping-options,
.listing-placeholder-image,
.listing-card-digital {
    cursor: default;
}
