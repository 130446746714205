:root {
    --center-width: 1200px;
    --window-threshold: 987px;
    --font-family: "poppins", Helvetica, Arial, sans-serif;
    --primary-color-dark: #333;
}

/* Add these rules at the root level */
html,
body {
    width: 100%;
    position: relative;
    margin: 0;
    padding: 0;
}

.home-container {
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scroll */
    position: relative;
    margin: 0;
    padding: 0;
}

.hero-image {
    position: fixed;
    top: 10px;
    left: 0;
    width: 100%;
    height: 70vh;
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5)),
        url("/src/assets/lab-giant-banner-adjusted.png");
    background-position: top 1% right;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
}

.hero-text {
    text-align: left;
    color: white;
    font-family: var(--font-family);
    width: 100%;
    max-width: var(--center-width);
    padding: 0 5%;
    margin-top: 9vh !important;
}

.hero-text h1 {
    font-size: 48px !important;
    margin-bottom: 4vh !important;
    word-wrap: break-word !important; /* Allow long words to break and wrap */
    overflow-wrap: break-word !important; /* Alternative property for better browser support */
}

.hero-text h2 {
    font-size: 22px !important;
}

.hero-image-button {
    background-color: var(--primary-color) !important;
    font-family: var(--font-family) !important;
    text-transform: none !important;
    font-size: medium !important;
}

h1 {
    font-weight: 700;
    letter-spacing: -1px;
}

h2 {
    font-weight: 400;
}

.landingPageSection {
    background-color: white;
    position: relative;
    z-index: 1;
    padding-top: 40px;
    margin: auto;
}

.divider {
    background: var(--primary-color);
    content: "";
    display: block;
    width: 300px;
    height: 6px;
    position: absolute; /* Change from relative to absolute */
    z-index: 1; /* Ensure it appears on top */
    bottom: 0; /* Adjust as needed */
    left: 50%; /* Adjust as needed */
    transform: translateX(-50%);
}

.landingPageTitleText {
    color: rgb(51, 50, 50);
    font-family: var(--font-family);
    font-weight: 600;
}

.landingPageTitleText h3 {
    margin: 0;
}

@media (min-width: 987px) {
    .hero-text {
        width: 100%;
        max-width: var(--center-width);
        margin: 0 auto;
    }
    h1 {
        font-size: 64px;
    }
    .landingPageTitleText {
        text-align: left;
        font-size: 36px;
    }
    .howItWorks {
        flex-direction: row;
    }
}

@media (max-width: 986px) {
    .hero-image {
        height: 100vh; /* Increase from 60vh to cover full viewport */
    }

    .hero-text {
        padding: 0 20px; /* More consistent padding */
        margin: 15vh 0 5vh; /* Increase top margin for better spacing */
    }

    .hero-text h1 {
        font-size: 36px; /* Slightly larger for better readability */
        line-height: 1.3;
        margin-bottom: 20px !important;
    }

    .hero-text h2 {
        font-size: 18px !important; /* Slightly smaller for better hierarchy */
        line-height: 1.5;
    }

    .signup-section {
        margin-top: 15vh; /* Adjust spacing */
        padding: 0 20px 40px;
        width: 100%;
        box-sizing: border-box;
    }

    .signup-hero-container {
        width: 100%; /* Take full width */
        margin: 0 auto 30px; /* Center and add bottom margin */
        top: 0;
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); /* Enhanced shadow */
    }

    .signup-cta {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        height: auto; /* Allow content to determine height */
        min-height: 200px;
    }

    .howItWorksSteps {
        padding: 20px;
        margin-bottom: 15px;
    }

    .howItWorksSteps_Title {
        font-size: 18px; /* Smaller for mobile */
    }

    .howItWorksSteps p {
        font-size: 14px;
        line-height: 1.5;
    }

    .landingPageSection {
        margin: 0 5vw 0 5vw;
    }
    .landingPageTitleText {
        font-size: 24px;
        line-height: 30px;
    }
    .howItWorks {
        flex-direction: column;
    }
    p {
        font-size: 13px;
    }
    .step-arrow {
        left: 50% !important;
        top: -23px; /* Adjusted from -25px to -20px */
        transform: translateX(-50%) rotate(90deg); /* Adjusted from -90deg to 180deg */
    }
}

/* Add new styles for better mobile spacing */
@media (max-width: 480px) {
    .hero-text h1 {
        font-size: 32px; /* Even smaller for very small screens */
    }

    .signup-cta-steps li {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .create-lab-link {
        font-size: 16px;
    }
}

.howItWorks {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.howItWorksSteps {
    width: calc(33.33% - 18px);
    background-color: white;
    border-radius: 8px;
    padding: 25px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-top: 20px;
}

.howItWorksSteps:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.howItWorksSteps_Icon {
    font-size: 43px;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.howItWorksSteps_Title {
    font-size: 22px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 15px;
}

.howItWorksSteps p {
    font-size: 15px;
    line-height: 1.6;
    color: #4a4a4a;
}

@media (max-width: 986px) {
    .howItWorksSteps {
        width: 100%;
        margin-bottom: 20px;
    }
}

.howItWorksContent {
    max-width: var(--center-width);
    margin: 0 auto;
    padding: 0 5%;
}

.signup-section {
    background-color: white;
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    align-items: flex-end; /* Change this from flex-start to flex-end */
    justify-content: center;
    margin-top: 17vh;
    height: auto; /* Change from fixed height to auto */
}

.signup-content {
    display: flex;
    width: 100%;
    max-width: var(--center-width);
    justify-content: flex-start; /* Change from space-between to flex-start */
    padding: 0 5%;
    position: relative;
    align-items: flex-end;
}

.signup-hero-container {
    background-color: white;
    padding: 10px;
    width: 60%;
    max-width: 400px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    top: -81px;
    margin-right: 5%;
    min-height: 405px;
    display: flex;
    flex-direction: column;
}

.signup-cta {
    width: calc(100% - 400px);
    color: var(--primary-color-dark);
    font-family: var(--font-family);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 5%;
    margin-bottom: 12%;
    transition: opacity 0.3s ease, transform 0.3s ease;
    height: 270px;
    position: relative;
}

/* Add specific styling for login state */
.signup-cta[key="login-cta"] {
    justify-content: center; /* Center the content vertically */
    padding-top: 50px; /* Add some top padding to push content down */
}

.signup-cta h4 {
    font-size: 29px;
    font-weight: 600;
    color: var(--primary-color-dark);
    margin-top: 0;
    white-space: nowrap;
}

.login-cta-text {
    font-size: 22px;
    font-weight: 400;
    color: var(--primary-color-dark);
    margin-top: 30px; /* Increase top margin */
    display: flex;
    align-items: center;
    margin-bottom: 100px; /* Reduce bottom margin if needed */
}

.login-arrow {
    margin-right: 8px;
}

.signup-cta-text {
    font-size: 22px;
    font-weight: 600;
    margin-top: 20px;
}

.create-lab-button {
    background-color: var(--primary-color);
    color: white;
    font-family: var(--font-family);
    font-size: 16px;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    align-self: flex-start;
}

.create-lab-button:hover {
    background-color: var(--primary-color-dark);
}

@media (max-width: 986px) {
    .hero-image {
        height: 60vh;
    }

    .signup-section {
        margin-top: 6vh;
        padding: 20px 0 40px;
    }

    .signup-content {
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 0 20px;
        box-sizing: border-box;
    }

    .signup-hero-container {
        position: relative;
        top: -10px;
        width: 100%;
        max-width: 400px;
        margin: 0 auto 20px;
        padding: 20px;
        box-sizing: border-box;
    }

    .signup-cta {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        padding: 0;
        align-items: center;
    }

    .create-lab-button {
        align-self: center;
    }

    .howItWorksSection {
        width: 100%;
        padding: 0;
    }

    .howItWorksContent {
        width: 100%;
        padding: 40px 5% 0 !important; /* Increased top padding from 0 to 40px */
    }

    .landingPageSection {
        margin: 0;
        width: 100%;
    }
}

.findLabsSection {
    height: auto;
    min-height: 250px;
    padding-bottom: 40px;
}

.hero-content {
    position: relative;
    margin-top: 5vh;
}

.howItWorksSection {
    padding: 30px 0 40px;
    background-color: white;
}

.howItWorksContent {
    max-width: var(--center-width);
    margin: 0 auto;
    padding: 0 5%;
}

.signup-cta-steps {
    margin-bottom: -15x;
}

.signup-cta-steps h4 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #333;
}

.signup-cta-steps ol {
    list-style-type: none;
    padding-left: 0;
}

.signup-cta-steps li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    font-size: 16px;
    color: #4a4a4a;
}

.step-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    background-color: #2c3e50;
    border-radius: 50%;
    margin-right: 15px;
    position: relative;
}

.step-number {
    color: white;
    font-weight: bold;
}

.step-arrow {
    position: absolute;
    left: -25px;
    color: #2c3e50;
    font-size: 30px !important;
    font-weight: bold !important;
}

@media (max-width: 986px) {
    .signup-cta-steps {
        text-align: left;
        width: 100%;
    }

    .signup-cta-steps h4 {
        text-align: left;
    }

    .signup-cta-steps ol {
        display: inline-block;
    }
}

.create-lab-link {
    display: inline-flex;
    align-items: center;
    color: var(--primary-color);
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
    transition: color 0.3s ease;
    font-size: 18px;
    justify-content: flex-start;
    gap: 5px;
    z-index: 10;
    pointer-events: auto;
}

.create-lab-link:hover {
    color: var(--primary-color-dark);
    cursor: pointer;
}

.create-lab-link .MuiSvgIcon-root {
    margin-left: 5px;
    font-size: 18px;
}

.signup-cta > div {
    position: absolute;
    width: 100%;
    height: 100%;
}

/* Update these media queries */
@media (max-width: 986px) {
    .signup-section {
        width: 100vw; /* Changed from 100% to 100vw */
        margin-top: 6vh;
        padding: 20px 0 40px;
        overflow-x: hidden; /* Prevent horizontal scroll */
    }

    .howItWorksSection {
        width: 100vw; /* Changed from 100% to 100vw */
        padding: 0;
        overflow-x: hidden; /* Prevent horizontal scroll */
    }

    .landingPageSection {
        width: 100vw; /* Changed from 100% to 100vw */
        margin: 0;
        overflow-x: hidden; /* Prevent horizontal scroll */
    }
}

/* Add or update these rules */
:root {
    --center-width: 1200px;
    --window-threshold: 987px;
    --font-family: "poppins", Helvetica, Arial, sans-serif;
    --primary-color-dark: #333;
}

/* Update media queries */
@media (max-width: 986px) {
    .signup-section,
    .howItWorksSection,
    .landingPageSection {
        width: 100%; /* Change back to 100% */
        margin: 0;
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
        box-sizing: border-box; /* Add this */
    }

    /* Add this to ensure content padding doesn't cause overflow */
    .signup-content,
    .howItWorksContent,
    .landingPageSection > * {
        box-sizing: border-box;
        width: 100%;
    }
}
