/* Loading container */
.loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Center vertically in the viewport */
    animation: fadeIn 0.5s ease-in;
}

/* Loading spinner */
.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.3);
    border-top: 4px solid var(--primary-color); /* Change the color to your liking */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    animation: spin 1s linear infinite; /* Add a spinning animation */
}

/* Keyframes for the entry animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Keyframes for the spinner animation */
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
