/* Account Settings Styles */
.account-settings-container {
    width: 100%;
    padding: 20px;
    font-family: var(--font-family);
    background-color: #f5f5f5;
}

.account-settings-tabs {
    display: flex;
    margin-bottom: 20px;
    background-color: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.account-settings-tab {
    background: none;
    border: none;
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: #333;
    letter-spacing: 0.5px;
    font-weight: 500;
}

.account-settings-tab.active {
    font-weight: bold;
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
}

.account-settings-section {
    background-color: #fff;
    padding: 20px; /* Optional: adds some inner spacing */
    margin-bottom: 20px;
}

.account-settings-publications-header {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
    height: 40px;
    margin-bottom: 20px;
}

.account-settings-public-profile-section {
    background-color: #fff;
    margin-bottom: 20px;
}

.account-settings-heading {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.account-settings-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.account-settings-button-container > button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .account-settings-heading {
        flex-wrap: nowrap; /* Prevent wrapping on mobile */
    }

    .account-settings-edit-button {
        padding: 6px 12px; /* Slightly smaller padding on mobile */
        font-size: 13px; /* Slightly smaller font on mobile */
    }
}

.account-settings-info p {
    margin: 10px 0;
    font-size: 14px;
}

.account-settings-description {
    color: #666;
    margin-bottom: 15px;
    font-size: 14px;
}

.account-settings-form {
    margin-bottom: 20px;
}

.account-settings-form-group {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.account-settings-form-group label {
    display: block;
    font-weight: 500;
}

.account-settings-form-group-row {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.account-settings-select,
.account-settings-input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.account-settings-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.add-tag-button {
    height: 36px; /* Match the height of the input */
    padding: 0 15px; /* Adjust horizontal padding as needed */
    font-size: 14px; /* Adjust font size if necessary */
    line-height: 1; /* Ensure text is vertically centered */
}

.account-settings-public-profile {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.account-settings-public-profile > h3 {
    font-size: 18px;
    font-weight: 500;
}

.account-settings-notifications-table {
    width: 100%;
}

.account-settings-notifications-table > thead > tr {
    border-bottom: 1px solid #e0e0e0;
}

.account-settings-notifications-table > thead > tr > th {
    padding-bottom: 10px;
}

.account-settings-notifications-table > thead > tr > th:first-child {
    text-align: left;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.account-settings-notifications-table > thead > tr > th:not(:first-child) {
    text-align: center;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.account-settings-notifications-table > tbody > tr > td:not(:first-child) {
    text-align: center;
}

.account-settings-notifications-setting-header {
    flex-direction: column;
    display: flex;
    gap: 3px;
    margin-bottom: 20px;
}

.account-settings-notifications-setting-header > h1 {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
}

.account-settings-notifications-setting-header > p {
    font-size: 14px;
    color: #666;
    margin: 0;
}

/* Responsive design */
@media (max-width: 768px) {
    .account-settings-container {
        padding: 10px;
    }

    .account-settings-tab {
        padding: 10px 15px;
        font-size: 14px;
    }

    .account-settings-section {
        padding: 15px;
    }

    .account-settings-heading {
        font-size: 18px;
    }

    .account-settings-button {
        width: 100%;
    }
}
/* ... (keep existing styles) ... */

/* Public Profile Styles */
.profile-picture-container {
    display: flex;
    align-items: center;
    gap: 20px;
}

.profile-picture {
    width: 240px;
    height: 240px;
    object-fit: cover;
    border-radius: 10px;
}

.profile-picture-upload {
    display: none;
}

.tags-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.tag {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.remove-tag {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 5px;
    font-size: 16px;
    color: #666;
}

.add-tag-container {
    display: flex;
    gap: 10px;
}

.account-settings-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    resize: vertical;
}

.account-settings-section-wrapper {
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    padding: 15px;
    border-radius: 4px;
}

.account-settings-input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.account-settings-section-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #f0f0f0;
    cursor: pointer;
    border: none;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: var(--primary-color);
}

.account-settings-section-images {
    display: flex;
    justify-content: center;
    gap: 20px; /* Reduced gap for mobile */
    margin-top: 20px;
    flex-wrap: wrap;
    width: 100%;
}

.account-settings-section-images-image-upload-label {
    position: relative;
    width: 200px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #ccc;
    border-radius: 8px;
    cursor: pointer;
    max-width: 100%;
    overflow: hidden;
    transition: border-color 0.3s;
}

.account-settings-section-images-image-upload-square {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-upload-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    color: #666;
    font-size: 14px;
    text-align: center;
    padding: 10px;
}

.image-upload-instructions span {
    margin-top: 25px;
}

.paste-image-button {
    margin-top: auto;
    margin-bottom: 10px;
    padding: 5px 10px;
    font-size: 12px;
    color: var(--primary-color);
    background-color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.paste-image-button:hover {
    background-color: var(--primary-color);
    color: white;
}

.account-settings-section-images-delete-image {
    position: absolute;
    width: 30px;
    height: 30px;
    bottom: 5px;
    right: 5px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    border-radius: 50%;
    border: none;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.account-settings-section-images-delete-image:hover {
    color: white;
    background-color: var(--primary-color);
}

.account-settings-section-images img {
    max-width: 100%; /* Ensure images don't overflow */
    height: auto;
    max-height: 300px; /* Limit height on all screens */
    object-fit: contain;
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
    .account-settings-section-images {
        gap: 10px; /* Further reduce gap on mobile */
        justify-content: center;
    }

    .account-settings-section-images:has(> *:nth-child(3)) {
        justify-content: center; /* Center images on mobile */
    }

    .account-settings-section-images:has(> *:nth-child(3)) img {
        width: 100%; /* Full width on mobile */
        max-width: 300px; /* But limit maximum width */
        margin: 0 auto; /* Center the image */
    }

    .account-settings-section-images-image-upload-label {
        width: 150px;
        height: 150px;
    }

    .image-upload-instructions {
        font-size: 12px;
    }

    .paste-image-button {
        font-size: 11px;
        padding: 4px 8px;
    }
}

.account-settings-my-publications {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.account-settings-publications-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.account-settings-publications-list > .publication-manager-item {
    background-color: #fff;
    border-radius: 4px;
    padding: 15px;
    border: 1px solid #e0e0e0;
}

.account-settings-publications-list > .publication-manager-item:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.account-settings-publications-list > .publication-manager-item:last-child {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #e0e0e0;
}
