.container {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("/src/assets/background-1440.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: calc(100vh - 64px);
    /* width: 100vw; */
    display: block;
}

.signup-area {
    margin: auto !important;
    margin-top: 10% !important;
}

.input-field {
    margin-bottom: 20px !important;
    width: 100%;
}

.button-area {
    margin-top: 30px;
}

.signup-button {
    background-color: var(--primary-color) !important;
    color: white !important;
    margin: auto !important;
    width: 100% !important;
}

.signup-button:hover {
    background-color: var(--primary-color-dark) !important;
}

.signup-container {
    height: 100%;
}

select.input.signup {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
    background-repeat: no-repeat;
    background-position: right 15px top 50%;
    background-size: 12px auto;
}

select.input.signup:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

select.input.signup:hover {
    border-color: #999;
}

/* Style for the placeholder option */
select.input.signup option[value=""] {
    color: #999;
}

/* Ensure other inputs have consistent styling */
input.input.signup {
    width: 100%;
    padding: 10px 15px;
    margin-bottom: 17.5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

input.input.signup:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.login-error {
    color: #dc3545;
    font-size: 12px;
    margin-bottom: 10px;
    margin-top: 0px !important;
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.login-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.welcome-back {
    font-size: 56px;
    margin-right: 20px;
}

/* Add spacing between select boxes */
.login-form > div {
    margin: 10px 0;
}

/* Increase width of the Get Started button */
.signup-button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 0; /* Add this line */
}

.signup-button-container > button:first-child {
    background-color: white;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.login-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: 15px;

    height: 50px;
}

.login-button-container button {
    flex: 1; /* Makes buttons grow to fill available space */
}

/* For the "Get Started" button */
.login-button-container button:only-child {
    flex: 0 0 100%; /* Full width when it's the only button */
}

.standard-button {
    width: 100%; /* Changed from 100% to 48% to allow space between buttons */
    padding: 10px;
    font-size: 16px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    height: 50px;
}

.standard-button:hover {
    background-color: var(--primary-color-dark);
}

.signup-form-container {
    position: relative;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Change this from center to flex-start */
    max-width: none;
    margin: 0;
    padding: 20px 30px 0; /* Remove bottom padding */
    width: 100%;
    height: 475px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    max-width: 400px;
    margin: 0 auto;
}

.signup-form-container h3 {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
    text-align: center;
}

@media (max-width: 986px) {
    .signup-form-container {
        padding: 0;
        width: 100%;
        max-width: 100%;
    }
    .signup-form-container {
        width: 100%;
        max-width: 100%;
    }

    .signup-form-container h3 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .input.signup,
    select.input.signup {
        font-size: 14px;
        padding: 8px 12px;
    }

    label {
        font-size: 14px;
        margin-bottom: 3px;
    }

    .login-button-container {
        margin-top: 20px;
        gap: 15px;
        margin-bottom: 0; /* Add this line */
    }

    .standard-button {
        height: 40px;
        font-size: 14px;
    }
}

.form-toggle {
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 0;
    opacity: 0;
    visibility: hidden;
    display: none;
    position: absolute;
    bottom: -8px;
    left: 0;
    right: 0;
    transition: opacity 0.3s ease;
    z-index: 1;
}

.form-toggle.login-toggle {
    margin-top: auto;
    padding-top: 20px;
}

.form-toggle.signup-toggle {
    margin-top: 0;
}

.form-toggle.visible {
    opacity: 1;
    visibility: visible;
    display: block;
}

.toggle-link {
    color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
}

.toggle-link:hover {
    text-decoration: underline;
}

/* Ensure the AnimatePresence container takes full width and height */
.signup-form-container > div {
    width: 100%;
    flex-grow: 1; /* Allow it to grow and push the toggle to the bottom */
    display: flex;
    flex-direction: column;
}

/* Add this new rule */
.login-form > *:last-child {
    margin-bottom: 10px !important;
    padding-bottom: 0;
}

/* New styles for Login form */
.login-form.login-specific {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 400px;
}

.login-form.login-specific label {
    margin-bottom: 8px; /* Increased space below labels */
}

.login-form.login-specific input {
    margin-bottom: 0; /* Remove default bottom margin */
}

.login-form.login-specific .login-button-container {
    margin: 21px 0; /* Increased space above buttons */
}

/* Adjust error message positioning for login form */
.login-form.login-specific .error.login {
    margin-bottom: 10px;
}

/* Add these new styles */
.name-row {
    display: flex;
    gap: 10px;
    margin: 10px 0 !important;
}

.name-row .name-input-wrapper {
    flex: 1;
}

.name-row .name-input-wrapper input {
    width: 100%;
    margin-bottom: 0;
}

/* Modify this existing rule */

@keyframes highlightPulse {
    0% {
        box-shadow: 0 0 0 0 rgba(63, 5, 5, 0.4);
    }
    70% {
        box-shadow: 0 0 0 20px rgba(63, 5, 5, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(63, 5, 5, 0);
    }
}

.signup-form-container {
    transition: all 0.3s ease-in-out;
}

.signup-form-container.highlight {
    animation: highlightPulse 2.5s ease-out;
}

.login-form {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
    padding-bottom: 55px;
}

.login-button-container {
    margin-top: auto;
    margin-bottom: 0;
}
