/* Header */

.create-listing-header {
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
    margin: 2rem 0;
}

.create-listing-subheader {
    font-size: 24px;
    font-weight: 600;
}

.create-listing-container {
    display: flex;
    margin: 0 auto;
    padding: 0 20px;
    max-width: 1200px;
}

.create-listing-nav {
    border-radius: 10px;
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px;
}

.create-listing-nav button {
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: var(--primary-color);
    background-color: #eeeeee;
    padding: 10px;
    cursor: pointer;
    text-align: left;
    transition: background-color 0.3s, color 0.3s; /* Add this line */
}

.create-listing-nav button:hover {
    background-color: var(--primary-color);
    color: white;
}

.create-listing-content {
    flex: 4;
    width: 100%;
}

.create-listing-content > div:not(:first-child) {
    margin-top: 20px;
}

.create-listing-section {
    padding: 20px;
    background-color: #eeeeee;
    border-radius: 10px;
    margin-bottom: 3rem;
}

.create-listing-section > *:last-child {
    margin-bottom: 10px;
}

/* Fields */
.create-listing-field {
    font-family: var(--font-family);
    margin-bottom: 2rem;
    gap: 5px;
}

.create-listing-field > * {
    margin: 0;
}

.create-listing-field > h3 {
    font-weight: 500;
}

.create-listing-field > h4 {
    color: #666;
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 10px;
}

.edit-listing-footer {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-bottom: 20px;
}

.edit-listing-footer > button {
    padding: 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--primary-color);
    color: white;
    font-weight: 500;
}

.input {
    padding: 8px;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 0.5rem;
    width: 100%;
    font-family: var(--font-family) !important;
}

.labmember-select {
    position: relative;
    z-index: 12 !important;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-family: var(--font-family) !important;
}

.licensing-select {
    position: relative;
    z-index: 11 !important;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-family: var(--font-family) !important;
}

.unit-select {
    position: relative;
    z-index: 11;
    min-width: 100px;
    max-width: 200px;
    border-radius: 5px;
}

.custom-unit-input {
    font-size: 16px;
    max-width: 200px;
    min-width: 100px;
    margin-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 3px;
    padding: 8px;
}

.delivery-option .MuiFormControlLabel-label {
    font-family: var(--font-family);
    font-size: 16px;
}

/* Price, Unit, Quantity */

.price-quantity-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    font-size: 16px;
}

.price-per-text {
    font-size: 16px;
    margin: 0 10px;
}

.price-unit-select {
    position: relative;
    z-index: 11;
    min-width: 100px;
    max-width: 200px;
}

.image-input-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 90%;
    margin: 0 auto;
}

.image-upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.image-upload-label {
    width: 30%;
    aspect-ratio: 1;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    position: relative;
    outline: none;
}

.image-upload-label:focus {
    outline: 2px solid var(--primary-color);
}

.image-label {
    position: absolute;
    top: 5px;
    left: 5px;
    border-radius: 5px;
    font-weight: 500;
    background-color: var(--primary-color);
    color: white;
    padding: 2px 8px;
    font-size: 12px;
}

.image-upload-square {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
}

.image-actions {
    position: absolute;
    bottom: 5px;
    right: 5px;
    display: flex;
    gap: 5px;
}

.paste-image-button {
    padding: 5px 10px;
    font-size: 12px;
    color: var(--primary-color);
    background-color: white;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    line-height: normal;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
}

.paste-image-button:hover {
    background-color: var(--primary-color);
    color: white;
}

.delete-image {
    width: 30px;
    height: 30px;
    padding: 5px;
    font-size: 12px;
    color: var(--primary-color);
    background-color: white;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    line-height: normal;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
}

.image-upload-instructions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #666;
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
}

.image-upload-instructions span {
    display: block;
}

/* Mobile-specific styles */
@media screen and (max-width: 768px) {
    .create-listing-header {
        font-size: 1.5rem;
    }

    .create-listing-section h2 {
        font-size: 1.2rem;
    }

    .create-listing-field h3 {
        font-size: 1rem;
    }

    .create-listing-field h4 {
        font-size: 0.9rem;
    }

    .input,
    textarea {
        font-size: 16px; /* Prevents zoom on mobile */
    }

    .price-quantity-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 15px;
    }

    .price-quantity-container > * {
        width: 100%;
        max-width: 300px;
    }

    .price-per-text {
        text-align: center;
        margin: 0;
    }

    /* Adjust radio buttons and checkboxes for better touch targets */
    .delivery-option,
    .checkbox-label {
        margin-bottom: 15px !important;
    }

    input[type="radio"],
    input[type="checkbox"] {
        width: 24px;
        height: 24px;
    }

    /* Make buttons more touch-friendly */
    .button-listing {
        min-height: 44px;
        padding: 12px 20px;
        width: 100%;
        margin: 10px 0;
    }

    .button-container-listing {
        padding: 0 20px;
        flex-direction: column;
        gap: 10px;
    }

    .button-container-listing button {
        width: 100%;
    }

    .price-quantity-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 15px;
    }

    .price-quantity-container > * {
        width: 100%;
        max-width: 300px;
    }

    .price-quantity-container input[type="number"] {
        width: 100%;
        max-width: 300px;
        box-sizing: border-box;
    }

    .unit-select,
    .price-unit-select {
        margin: 0 auto;
        width: 100%;
        max-width: 300px;
    }

    .custom-unit-input {
        margin: 10px auto;
        width: 100%;
        max-width: 300px;
    }

    .price-per-text {
        text-align: center;
        margin: 0;
    }

    .image-input-container {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .image-upload-label {
        width: 100%;
        max-width: 250px;
    }

    .image-upload-instructions {
        font-size: 12px;
    }

    .paste-image-button {
        font-size: 11px;
        padding: 4px 8px;
    }
}

/* Tablet-specific styles */
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .create-listing-container {
        padding: 0 40px;
    }
}

/* Adjust Select components for better mobile experience */
.labmember-select,
.licensing-select {
    font-size: 16px !important;
}

.react-select__control {
    min-height: 44px !important;
}

.react-select__option {
    padding: 12px !important;
}

/* Make form sections more compact on mobile */
@media screen and (max-width: 768px) {
    .create-listing-section {
        margin-bottom: 2rem;
    }

    .create-listing-field {
        margin-bottom: 1.5rem;
    }

    /* Adjust spacing for radio groups */
    .RadioGroup {
        flex-direction: column !important;
    }

    .RadioGroup .MuiFormControlLabel-root {
        margin-left: 0;
        margin-right: 0;
    }
}

.textarea-container {
    position: relative;
    width: 100%;
}

.add-link-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background: transparent;
    border: none;
    cursor: pointer;
    color: #666;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    z-index: 1;
}

.add-link-button:hover {
    background: #f0f0f0;
    color: #333;
}

.link-modal-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.retrieval-methods-textarea {
    padding-top: 39px !important;
}
