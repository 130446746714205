:root {
    --font-family: 'poppins', Helvetica, Arial, sans-serif;
    /* --primary-color: rgb(63, 05, 05); */
    --complementary-color: #053f3f;
    --primary-gray: #949494;
    --center-width: 1100px;
}

footer {
    background-color: var(--primary-color);
    color: white;
    font-family: var(--font-family);
    display: flex;
    justify-content: center;
    flex-direction: column;
}

footer ul {
    padding: 0;
}

footer h5 {
    color: var(--primary-gray);
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 0.9rem;
}

.footerButton {
    background-color: var(--primary-color) !important;
    font-family: var(--font-family) !important;
    text-transform: none !important;
    font-size: medium !important;
    color: var(--primary-color);
}

.footerWrapper {
    margin: 0 auto;
    max-width: var(--center-width);
    width: 90%;
}

.footerLogo p {
    color: white;
    font-weight: bold;
}

.footerLinks {
    display: flex;
    justify-content: space-between;
    align-items: top;
    border-bottom: 1px solid var(--primary-gray);
    padding: 40px;
}

.footerCopyright {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerCopyright p {
    color: white;
}

.footerPrompt {
    padding: 0 5% 0 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footerImage {
    /* background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/src/assets/footer-background.jpg'); */
    min-height: 350px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #797c7c;
}

.footer a {
    color: white;
    text-decoration: none;
    padding: 20px 0;
    font-size: 0.9rem;
    transition: 0.3s ease-in-out;
}

.footer a:hover {
    color: var(--primary-gray);
}

.footerSocialLinks {
    display: flex;
    gap: 10px;
    flex-direction: row !important;
}

.footerLinks ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
