.spinner-container {
    display: inline-block;
    position: relative;
    width: 12px;
    height: 12px;
}

.spinner {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid white; /* Spinner color */
    border-radius: 50%;
    animation: spinner-animation 0.8s linear infinite;
    border-color: white transparent transparent; /* Adjusted for a bigger arc */
}

@keyframes spinner-animation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
