.lab-profile-news {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px 0;
    width: 100%;
}

.lab-profile-news-item {
    background: white;
    border: 1px solid #d0d0d0;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.2s ease;
    width: 100%;
}

.lab-profile-news-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.lab-profile-news-content {
    padding: 20px;
}

.lab-profile-news-content h2 {
    margin: 0 0 15px;
    font-size: 23px;
    font-weight: 500;
    color: #535353;
    letter-spacing: 0.5px;
}

.lab-profile-news-content p {
    margin: 0 0 15px;
    font-size: 16px;
    line-height: 1.6;
    color: #454545;
}

.lab-profile-news-image {
    width: 100%;
    max-height: 420px;
    overflow: hidden;
    margin: 15px 0;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    position: relative;
}

.lab-profile-news-image img {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 420px;
    object-fit: contain;
}

.lab-profile-news-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #d0d0d0;
    font-size: 14px;
    color: #666;
}

.lab-profile-news-author {
    color: #707070;
    font-size: 16px;
}

.lab-profile-news-date {
    color: #707070;
    font-size: 14px;
}

.lab-profile-news-link {
    color: var(--primary-color);
    text-decoration: underline;
    font-weight: 500;
}

.lab-profile-news-image.carousel {
    background-color: white;
}

.lab-profile-carousel-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 2;
    transition: background 0.3s ease;
    padding: 0;
}

.lab-profile-carousel-arrow:hover {
    background: rgba(0, 0, 0, 0.7);
}

.lab-profile-carousel-arrow.left {
    left: 10px;
}

.lab-profile-carousel-arrow.right {
    right: 10px;
}

.lab-profile-carousel-arrow svg {
    font-size: 20px;
}

/* Add responsive styles */
@media screen and (max-width: 768px) {
    .lab-profile-news-image {
        max-height: 360px;
    }

    .lab-profile-news-image img {
        max-height: 360px;
    }

    .lab-profile-carousel-arrow {
        width: 32px;
        height: 32px;
    }

    .lab-profile-carousel-arrow svg {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    .lab-profile-news-image img {
        max-height: 250px;
    }

    .lab-profile-news-content h2 {
        font-size: 20px;
    }
}
