/* Header */
.create-lab-pi-page {
    margin: 0;
}

.create-lab-pi-header {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
}

.create-lab-pi-subheader {
    font-size: 24px;
    font-weight: 600;
}

.create-lab-pi-container {
    display: flex;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.create-lab-pi-content {
    flex: 4;
    padding-left: 20px;
}

.create-lab-pi-content > div:not(:first-child) {
    margin-top: 20px;
}

.create-lab-pi-section {
    padding: 20px;
    background-color: #eeeeee;
    border-radius: 10px;
}

.create-lab-pi-section > *:last-child {
    margin-bottom: 10px;
}

/* Fields */
.create-lab-pi-field {
    font-family: var(--font-family);
    margin-bottom: 30px;
    gap: 5px;
}

.create-lab-pi-field > * {
    margin: 0;
}

.create-lab-pi-field > h3 {
    font-weight: 500;
}

.create-lab-pi-field > h4 {
    color: #666;
    font-weight: 400;
    font-size: 14px;
    margin: 5px 0 10px;
}

.input {
    padding: 0.5rem;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 0.5rem;
    width: 100%;
    font-family: var(--font-family) !important;
}

.create-lab-pi-select {
    position: relative;
    font-size: 16px;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: 100%;
    font-family: var(--font-family) !important;
}

.create-lab-pi-footer {
    margin: 10px auto 30px;
    display: flex;
    width: 100%;
    justify-content: center;
}

.create-lab-pi-button {
    background-color: var(--primary-color);
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.create-lab-pi-keyword-options {
    display: inline-flex;
    width: 100%;
    gap: 10px;
}

.create-lab-pi-keyword-options button {
    flex: none;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: white;
    background-color: var(--primary-color);
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    font-family: var(--font-family);
    white-space: nowrap;
}

.create-lab-pi-keyword-options input {
    flex: 1;
}

.create-lab-pi-keywords {
    margin-top: 20px;
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
