ul {
    list-style-type: none;
    padding: 0;
    color: #454545;
}

/* Listings Page */
.browse-labs-page {
    width: 80%;
    margin: auto;
    min-height: 650px;
    position: relative;
    padding: 30px 0;
}

.browse-labs-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 20px;
}

.browse-labs-header > button {
    color: white;
    background-color: var(--primary-color);
    border: none;
    padding: 5px 10px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
    letter-spacing: 0.5px;
}

.browse-labs-container {
    display: flex;
    gap: 20px;
}

.browse-labs-list-area {
    flex: 1;
    min-width: 500px;
}

.browse-labs-list-area > ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.browse-labs-list-area > ul > li {
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
}

.selected-listing-area {
    flex: 1;
    min-width: 400px;
}

.listings-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.browse-labs-pagination {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
}

/* Listings Filter */
.listings-filter-bar {
    display: inline-flex;
    display: center;
    gap: 20px;
}

.listings-filter-bar > div {
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.listings-filter {
    font-size: 14px;
}

.listings-filter-button {
    color: white;
    background-color: var(--primary-color);
    border: none;
    padding: 5px 10px;
    font-weight: 500;
    border-radius: 5px;
}

/* Listings Info */
.browse-labs-lab {
    padding: 20px;
}

.listing > *:not(:last-child) {
    margin: 0 0 10px;
}

.browse-labs-content {
    align-items: flex-start;
    display: flex;
    gap: 40px;
    max-width: 100%;
}

.browse-labs-logo {
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
}

.browse-labs-logo > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.browse-labs-header > * {
    margin: 0 0 5px;
}

.browse-labs-header-text {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: flex-start;
    gap: 10px;
    margin-top: 5px;
    overflow: scroll;
}

.browse-labs-header-text > * {
    margin: 0;
    padding: 0;
}

.browse-labs-header-text > h2 {
    font-size: 22px;
    font-weight: 600;
    letter-spacing: 0px;
}

.browse-labs-header-info {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.browse-labs-header-info > * {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
    color: grey;
}

.listing-header-text-container::-webkit-scrollbar {
    display: none;
}

.listing-header-text-container {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1; /* Limit to 1 line */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis if text overflows */
    word-break: break-all; /* Break long words */
    max-width: 100%;
}

.listing-header > div > h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
}

.listing-header > div > div > h3 {
    font-size: 12px;
    font-weight: 400;
    margin: 0 0 5px;
}

.browse-labs-description-section:not(:last-child) {
    margin: 0 0 10px;
}

.browse-labs-description-keywords {
    display: inline-flex;
    align-items: center;
    flex-wrap: nowrap; /* Prevent wrapping */
    gap: 5px;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent text from wrapping */
    max-width: 100%; /* Ensure it respects the container's width */
    scrollbar-width: none; /* For Firefox */
}

.browse-labs-description-keywords::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
}

.browse-labs-description-keywords > div {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    color: white;
    background-color: #286cea;
    opacity: 0.9;
    border-radius: 10px;
}

.browse-labs-description-lab-overview {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin-top: 10px;
    font-weight: 400;
    height: 50px;
    letter-spacing: -0.3px;
}

.browse-labs-description-lab-overview > p {
    margin: 0;
}

.browse-labs-footer-buttons {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;
    gap: 10px;
}

.browse-labs-footer-buttons > button {
    border: none;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 12px;
    border-radius: 5px;
}

.browse-labs-footer-buttons > button:first-child {
    background-color: white;
    color: #454545;
    border: 1px solid #787878;
}

.browse-labs-footer-buttons > button:last-child {
    background-color: #18181b;
    color: white;
}

.browse-labs-footer-buttons > button:last-child:disabled {
    background-color: #ccc;
    color: white;
    cursor: not-allowed;
}

/* Selected Listing */

.selected-lab-card {
    position: sticky;
    right: 0;
    top: 85px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.1);
}

.selected-lab-card > *:not(:last-child) {
    margin: 0 0 10px;
}

.selected-lab-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 0 0 10px;
}

.selected-lab-header-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.selected-lab-header > img {
    width: 120px;
    height: 120px;
    background-color: white;
    object-fit: contain;
}

.selected-lab-header > img:hover {
    cursor: pointer;
}

.selected-lab-header > div > h2 {
    max-width: 400px;
    font-weight: 600;
    font-size: 28px;
    word-break: break-word;
    margin: 0;
}

.selected-lab-header-lab:hover {
    cursor: pointer;
}

.selected-lab-header > div > h3 {
    margin: 0;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    max-width: 400px;
    font-weight: 600;
    font-size: 12px;
    color: #787878;
    font-weight: 400;
    gap: 2px;
}

.selected-lab-header > div > h3 > span {
    display: flex;
    gap: 10px;
}

.selected-lab-section-title {
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0;
}

.selected-lab-section-title:first-child {
    margin: 0 0 20px;
}

.selected-lab-section-title {
    display: flex;
    align-items: center;
    gap: 7px;
}

.selected-lab-section-content {
    font-size: 14px;
    margin-bottom: 30px;
    display: -webkit-box; /* Enable flexbox layout */
    -webkit-box-orient: vertical; /* Set the box orientation to vertical */
    -webkit-line-clamp: 12; /* Limit to 12 lines */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis if text overflows */
}

.selected-lab-section-content.keywords {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
}

.selected-lab-section-content-keyword {
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    color: white;
    background-color: #18181b;
    opacity: 0.9;
    border-radius: 12px;
}

.selected-lab-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.selected-lab-footer > button {
    color: white;
    background-color: var(--primary-color);
    border: none;
    padding: 8px 15px;
    font-weight: 500;
    font-size: 14px;
    border-radius: 5px;
}

.selected-lab-footer > button:disabled {
    background-color: grey;
    cursor: not-allowed;
}

/* Add z-index to the ellipses */
.lab-profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.lab-profile-banner-ellipses {
    position: relative;
    z-index: 0; /* Ensure this is lower than the input field */
}

.lab-profile-search-title {
    color: white;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 1px;
    margin: 20px 0;
    width: 100%;
    text-align: left;
}

/* Ensure the input field is above the ellipses */
.lab-profile-search-inputs {
    position: relative;
    z-index: 1; /* Ensure this is higher than the ellipses */
    background-color: transparent; /* Ensure the background covers the ellipses */
    top: 20px;
    width: 70%;
    transform: translateX(-50%);
    left: 50%;
    height: 325px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.lab-profile-search-inputs-container {
    position: relative;
    display: inline-flex;
    width: 100%;
    gap: 20px;
}

.lab-profile-search-inputs-container > select {
    flex: 1;
}

.lab-profile-search-inputs-container > input {
    flex: 5;
}

.lab-profile-search-inputs-container > button {
    position: absolute;
    right: -55px;
    height: 100%;
    width: 40px;
    background-color: white;
    color: #621f1f;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.lab-profile-search-input {
    padding: 8px;
    font-size: 14px;
    border: 2px solid #ccc;
    border-radius: 4px;
    width: 100%;
    letter-spacing: 0.5px;
}

.lab-profile-search-buttons {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
}

.lab-profile-search-buttons > button {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    font-size: 14px;
    padding: 12px 16px;
    border: none;
    letter-spacing: 0.5px;
}

.lab-profile-search-buttons > button:first-child {
    background-color: white;
    color: #621f1f;
}

.lab-profile-search-buttons > button:first-child:hover {
    text-decoration: underline;
}

.lab-profile-search-buttons > button:last-child {
    background-color: #621f1f;
    color: white;
}

/* Add/modify these styles */
@media screen and (max-width: 768px) {
    .browse-labs-page {
        width: 95%;
        padding: 15px 0;
    }

    .browse-labs-container {
        flex-direction: column;
        width: 100%;
        gap: 10px;
    }

    .browse-labs-list-area {
        min-width: unset;
        width: 100%;
    }

    .browse-labs-content {
        gap: 15px;
        width: 100%;
    }

    .browse-labs-content > img {
        width: 80px;
        height: 80px;
    }

    /* Mobile sliding card styles */
    .selected-listing-area {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        min-width: unset;
        width: 100%;
        height: 80vh;
        z-index: 1000;
        background-color: white;
        transform: translateY(100%);
        transition: transform 0.3s ease-in-out;
        border-radius: 20px 20px 0 0;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    }

    .selected-listing-area.active {
        transform: translateY(0);
    }

    .selected-lab-card {
        position: relative;
        top: 0;
        height: 100%;
        overflow-y: auto;
        border: none;
        box-shadow: none;
        padding-top: 40px;
    }

    .selected-lab-header {
        flex-direction: column;
        text-align: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #eee;
        padding-top: 10px;
    }

    .selected-lab-header-text {
        align-items: center;
        text-align: center;
    }

    .selected-lab-header > div > h3 {
        align-items: center;
    }

    .browse-labs-footer-buttons {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background: white;
        padding: 15px;
        margin: 0;
        box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
        z-index: 1001;
        justify-content: space-between;
    }

    .selected-lab-footer-button {
        flex: 1;
        padding: 12px !important;
        font-size: 14px !important;
    }

    .selected-lab-footer-button:disabled {
        color: #ccc;
        background-color: #f0f0f0;
        cursor: not-allowed;
    }

    /* Adjust content to account for fixed footer */
    .selected-lab-content {
        padding-bottom: 100px;
    }

    /* Mobile header adjustments */
    .browse-labs-header {
        margin: 10px 0;
    }

    .browse-labs-header h2 {
        font-size: 18px;
    }

    /* Mobile lab list adjustments */
    .browse-labs-lab {
        padding: 15px;
        width: 100%;
    }

    .browse-labs-header-text > h2 {
        font-size: 18px;
    }

    .browse-labs-description-lab-overview {
        font-size: 13px;
        height: auto;
        -webkit-line-clamp: 2;
    }

    .browse-labs-description-lab-overview > p {
        margin: 0;
    }
}

/* Add these new styles */
.selected-lab-close-button {
    position: fixed;
    top: 15px;
    right: 20px;
    width: 30px;
    height: 30px;
    background-color: #f0f0f0;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1002;
    color: #454545;
    font-size: 30px;
    transition: background-color 0.2s ease;
}

.selected-lab-close-button::before {
    content: "";
    position: absolute;
    top: -8px;
    right: -8px;
    bottom: -8px;
    left: -8px;
    background: white;
    border-radius: 50%;
    z-index: -1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.selected-lab-close-button:hover {
    background-color: #e0e0e0;
}

/* Add these new styles */
.selected-listing-overlay {
    display: none;
}

@media screen and (max-width: 768px) {
    .selected-listing-overlay {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease-in-out;
        cursor: pointer;
    }

    .selected-listing-overlay.active {
        opacity: 1;
        pointer-events: auto;
    }
}
