#root {
    height: 100%;
}

.messages-container {
    display: inline-flex;
    height: 100%;
    width: 100%;
}

.chatNames {
    flex: 1;
    border-right: 1px solid #a7a7a7;
    font-family: var(--font-family);
}

.conversations {
    overflow-y: scroll;
}

.chatOnline {
    flex: 2;
    border-right: 1px solid #a7a7a7;
}

.chatDetails {
    flex: 1;
}

.conversation {
    display: flex;
    align-items: center;
    padding: 15px;
    font-size: 14px;
    letter-spacing: 0.5px;
    cursor: pointer;
}

.conversation:hover {
    background-color: rgb(231, 231, 231);
    transition: 0.7s;
    cursor: pointer;
}

.conversation-info {
    padding-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.conversation-name {
    font-weight: 600;
}

.conversation-name-time {
    display: flex;
    align-items: center;
    gap: 5px;
}

.conversation-time {
    font-size: 10px;
}
.conversation-message {
    font-size: 12px;
}

.messagesTitle,
.conversationTitle,
.detailsTitle {
    background-color: var(--primary-color);
    opacity: 0.9;
    color: white;
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 24px;
    font-family: var(--font-family);
    letter-spacing: 0.5px;
}

.detailsAvatar:hover {
    cursor: pointer;
}

.detailsContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 350px;
}

.detailsContentHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 36px !important; /* !important to override default margin */
}

.detailsContentHeader > div {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.detailsContentHeaderTab {
    cursor: pointer;
    font-weight: 700;
}

.detailsContentHeaderTab > h2 {
    margin: 16px 0;
    font-weight: 600;
}

.detailsContentHeaderTab.selected {
    border-bottom: 3px solid var(--primary-color);
    background-color: #f3f3f3;
}

.detailsContent > * {
    margin: 0;
    margin-bottom: 8px;
}

.detailsContent > h2 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.5px;
}

.detailsContent > h3 {
    color: #7d0907;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 20px;
}

.detailsListingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.detailsListingContainer > h3 {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.5px;
    max-width: 300px;
    text-align: center;
    color: #454545;
    margin: 0;
}

.detailsListingContainer > h3:hover {
    cursor: pointer;
}

.detailsListingContainer > p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    line-height: 1.5;
    max-width: 300px;
    color: #454545;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 10;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
}

.detailsListingImageContainer {
    position: relative;
    width: 300px;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
}

.detailsListingImageContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.detailsListingImageContainer > img:hover {
    cursor: pointer;
}

.detailsDescription {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 28px;
    width: 100%;
    padding: 0 24px;
}

.detailsDescription > div {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.detailsDescriptionIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    background-color: var(--primary-color);
    color: white;
    padding: 18px;
}

.detailsDescription > div:first-child:hover {
    cursor: pointer;
}

.detailsDescriptionMetrics {
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 0 !important;
}
.detailsDescriptionMetrics > div {
    width: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2px;
}

.detailsDescriptionMetrics > div > * {
    margin: 0;
}

.detailsDescriptionMetrics > div > h2 {
    font-size: 18px;
    font-weight: 600;
}
.detailsDescriptionMetrics > div > h3 {
    font-size: 10px;
    font-weight: 500;
    color: #787878;
}

.detailsDescriptionText {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.detailsDescriptionText > * {
    padding: 0;
    margin: 0;
    font-weight: 500;
}

.detailsDescriptionText > h4 {
    color: #787878;
    font-size: 12px;
}

.detailsDescriptionText > h5 {
    font-size: 14px;
    font-weight: 600;
    color: #454545;
    width: 250px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.detailsDescriptionText > h5:hover {
    cursor: pointer;
}

.messages {
    overflow-y: scroll;
    height: calc(100vh - 49.2px - 130px);
    font-family: var(--font-family);
    display: flex;
    flex-direction: column-reverse;
}

.message {
    display: flex;
    padding: 12px 12px 0;
    font-size: 14px;
    letter-spacing: 0.5px;
}

.messageContent {
    display: flex;
    flex-direction: column;
    padding-left: 12px;
    padding-top: 8px;
}

.messageNameTime {
    display: inline-flex;
    align-items: center;
    gap: 5px;
}

.messageName {
    font-weight: 600;
}

.messageTime {
    font-size: 10px;
}

.messageText {
    font-size: 12px;
    line-height: 24px;
}

.messageTextBox {
    display: flex;
    padding: 12px;
    gap: 10px;
    font-size: 14px;
}

.textBox {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    padding: 0 8px;
    border: 1px solid #a7a7a7;
    font-family: var(--font-family);
}

.message-send {
    padding: 5px 10px;
    background-color: var(--primary-color);
    border-radius: 5px;
    color: white;
    font-size: 14px;
    letter-spacing: 0.5px;
    cursor: pointer;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
}

.emptyChat {
    text-align: center;
    font-weight: 600;
    padding: 0 3rem;
}

.listingLink {
    text-decoration: none;
}
