.import-orcid-publications-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.import-orcid-publications-subtitle {
    font-size: 16px;
    font-weight: 500;
    color: #71717a;
}

.import-orcid-publications-select-all-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.import-orcid-publications-select-all-container > button {
    background-color: #18181b;
    color: white;
    border: none;
    padding: 10px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 5px;
}

.import-orcid-publications-selected-count {
    font-size: 14px;
    font-weight: 500;
    color: #71717a;
}

.import-orcid-publications-list {
    list-style: none;
    padding: 0;
    overflow-y: scroll;
    max-height: 300px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    gap: 20px;
    padding-bottom: 5px;
}

.import-orcid-publications-list > li {
    display: flex;
    gap: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    font-size: 12px;
    align-items: flex-start;
}

.import-orcid-publications-list > li:hover {
    cursor: pointer;
}

.import-orcid-publications-item-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #71717a;
}
